import { View } from "@react-pdf/renderer";

const PageSeparator = () => {
  return (
    <View style={{ flexDirection: "column", gap: 0.5 }}>
      <View style={{ border: "1px solid #000000" }}></View>
      <View style={{ border: "1px solid #000000" }}></View>
    </View>
  );
};
export default PageSeparator;
