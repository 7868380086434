import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  alpha,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllCartItems } from "views/farmer/shoppingCartSlice";

import { useIsAuthenticated } from "providers/authprovider";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    border: theme.palette.grey,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ThemedAppBar = styled(AppBar)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[5],
  border: 0,
  height: 90,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  [theme.breakpoints.down("md")]: {
    gap: 2,
  },
}));

const FarmerNavbar = () => {
  const history = useHistory();
  const { isAuthenticated } = useIsAuthenticated();
  const getItems = useSelector(getAllCartItems);

  const [myAccountAnchorEl, setAccountAnchorEl] = useState(null);
  const handleCloseAccountMenu = () => setAccountAnchorEl(null);
  const handleOpenAccountMenu = (e) => setAccountAnchorEl(e.currentTarget);
  const isMyAccountMenuOpen = Boolean(myAccountAnchorEl);

  const total = getItems.reduce((acc, item) => (acc += item.quantity), 0);

  const renderMyAccountMenu = (
    <Menu
      anchorEl={myAccountAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMyAccountMenuOpen}
      onClose={handleCloseAccountMenu}
    >
      <MenuItem
        hidden={isAuthenticated}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          handleCloseAccountMenu();
          history.push("/auth/login");
        }}
      >
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        Login
      </MenuItem>
      <Divider hidden={!isAuthenticated} />
      <MenuItem
        sx={{ cursor: "pointer" }}
        onClick={() => {
          handleCloseAccountMenu();
          history.push("/");
        }}
        hidden={!isAuthenticated}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        My Account
      </MenuItem>
      <MenuItem
        sx={{ cursor: "pointer" }}
        onClick={() => {
          handleCloseAccountMenu();
          history.push("/orders");
        }}
        hidden={!isAuthenticated}
      >
        <ListItemIcon>
          <ShoppingBasketIcon />
        </ListItemIcon>
        My Orders
      </MenuItem>
      <Divider hidden={!isAuthenticated} />
      <MenuItem
        sx={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/logout");
        }}
        hidden={!isAuthenticated}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }} fontFamily="Geologica, sans-serif">
      <ThemedAppBar position="static">
        <Toolbar>
          <IconButton size="large" edge="start" hidden sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Box
            maxHeight={70}
            sx={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
          >
            <img src="/lima.png" alt="LiMA" width="auto" height={50} />
          </Box>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
            <Button
              color="primary"
              variant="contained"
              sx={{
                fontFamily: "Geologica, sans-serif",
              }}
            >
              Search
            </Button>
          </Search>
          <Button
            onClick={handleOpenAccountMenu}
            sx={{
              fontFamily: "Geologica, sans-serif",
            }}
          >
            Account
            <ArrowDropDownIcon />
          </Button>
          <Box
            component="a"
            href="/shopping-cart"
            onClick={(e) => {
              e.preventDefault();
              history.push("/shopping-cart");
            }}
          >
            <IconButton>
              <Badge badgeContent={total} color="primary">
                <CartIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </ThemedAppBar>
      {renderMyAccountMenu}
    </Box>
  );
};
export default FarmerNavbar;
