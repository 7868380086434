import { Box, Dialog, Paper, Typography, colors, styled } from "@mui/material";
import Carousel from "components/Carousel/Carousel";
import { Button } from "reactstrap";
import { useConfirmOrderCharges } from "services/stockService";
import Swal from "sweetalert2";

const RestockOrderDetails = ({
  orderDetails,
  showPaymentFormModal,
  open,
  onClose,
}) => {
  const { mutateAsync, isLoading } = useConfirmOrderCharges();

  const handleConfirmFee = (status) => {
    mutateAsync({
      restockId: orderDetails.id,
      status: status,
    }).then(async (res) => {
      let response = null;
      if (status === "accept") {
        // display invoice details
        response = await Swal.fire({
          title: "Successfully processed!",
          text: `Your order has been approved. Order No. ${res.invoice_number}`,
          icon: "success",
        });
      } else {
        // display message of order rejection
        response = await Swal.fire({
          title: "Order Cancelled!",
          text: `Your order has been cancelled.`,
          icon: "info",
        });
      }
      // close the details dialog
      if (response.isConfirmed || response.isDismissed) {
        onClose();
      }
    });
  };

  const CustomBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: 1,
  });
  return (
    <Dialog open={open} onClose={onClose}>
      <Paper
        sx={{
          py: 2,
          px: 2,
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h5" fontWeight="bold">
            Order Details
          </Typography>
          <Box maxHeight="40%" mb={2}>
            <Carousel
              sx={{ marginLeft: "10px" }}
              items={orderDetails.images.map((image) => ({
                name: orderDetails.product_name,
                image: image.fileName,
              }))}
            />
          </Box>
          <CustomBox>
            <Typography color={colors.grey[600]}>Product</Typography>
            <Typography>{orderDetails.product_name}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography color={colors.grey[600]}>Manufacturer</Typography>
            <Typography>{orderDetails.manufacturer}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography color={colors.grey[600]}>Variation</Typography>
            <Typography>{orderDetails.product_variation}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography color={colors.grey[600]}>Quantity</Typography>
            <Typography>{orderDetails.quantity}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography color={colors.grey[600]}>Price</Typography>
            <Typography>{Number(orderDetails.price).toFixed(2)}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography color={colors.grey[600]}>Total Payable</Typography>
            <Typography>
              {Number(orderDetails.total_payable).toFixed(2)}
            </Typography>
          </CustomBox>
          <CustomBox>
            <Typography color={colors.grey[600]}>Total Paid</Typography>
            <Typography>
              {Number(orderDetails.total_paid).toFixed(2)}
            </Typography>
          </CustomBox>
          <CustomBox>
            <Typography color={colors.grey[600]}>Amount Due</Typography>
            <Typography>
              {Number(orderDetails.amount_due).toFixed(2)}
            </Typography>
          </CustomBox>
          {orderDetails.status === "to_pay" && (
            <Box>
              <Button
                color="primary"
                disabled={orderDetails.status !== "to_pay"}
                onClick={() => showPaymentFormModal(true)}
              >
                Pay
              </Button>
            </Box>
          )}

          {orderDetails.status === "accepted" && (
            <Box>
              <Button
                color="default"
                disabled={isLoading}
                onClick={() => handleConfirmFee("accept")}
              >
                Accept Fee
              </Button>
              <Button
                color="danger"
                disabled={isLoading}
                onClick={() => handleConfirmFee("reject")}
              >
                Cancel Order
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Dialog>
  );
};
export default RestockOrderDetails;
