import ShoppingCart from "components/Product/ShoppingCart/ShoppingCart";
import { Card, CardBody, Container } from "reactstrap";

// to change this to checkout
const ShoppingCheckout = () => {
  return (
    <>
      {/* <FarmerHeader /> */}
      <Container className="my-3">
        <Card>
          <CardBody>
            <ShoppingCart />
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default ShoppingCheckout;
