// import axios from "./axiosInstance";
import axios from "./axiosInstance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const paymentOptions = {
  now: "now",
  later: "later",
};

const orderService = {
  // get Orders
  getNewOrders: async () => {
    return await axios.get("/orders/initiated").then((res) => res.data);
  },
  getNewOrderDetails: async (id) => {
    return await axios
      .get("/orders/new-order-details/" + id)
      .then((res) => res.data);
  },
  claimOrder: async (data) => {
    return await axios.patch("/orders/claim", { id: data });
  },
  getNewOrderCompletionCode: async (id) =>
    await axios.get(`orders/complete/new-code/${id}`).then((res) => res.data),
  getResellerOrderDetails: async (id) =>
    await axios.get(`orders/seller/details/${id}`).then((res) => res.data),
  completeOrder: async (data) => {
    return await axios.patch("/orders/complete", data);
  },
  makeOrder: async (data) => {
    return await axios.post("/orders/add", data).then((res) => res.data);
  },
  makePayment: async (data) => {
    return await axios.post("/payments/stk", data).then((res) => res.data);
  },

  // reseller orders orders
  getResellerOrders: async () => {
    return await axios.get("/orders/seller").then((res) => res.data);
  },

  // my orders -> buyer orders
  getBuyerOrders: async () => {
    return await axios.get("orders/mine").then((res) => res.data);
  },

  // all buyer items -> get all buyer items
  getAllBuyerOrdersItems: async () => {
    return await axios.get("orders/my-orders-items").then((res) => res.data);
  },

  // get buyer order item details
  getBuyerOrderItemDetails: async (orderId) => {
    return await axios
      .get(`orders/my-order-item/${orderId}`)
      .then((res) => res.data);
  },
  // add product review
  submitProductReview: async (review) => {
    return axios.post("reviews/products", review).then((res) => res.data);
  },

  getPurchaseOrderPaymentDetails: async (orderId) =>
    await axios
      .get(`orders/payment/details/${orderId}`)
      .then((res) => res.data),
};

// to replace useOrder with useNewOrders
export const useNewOrders = () =>
  useQuery({
    queryKey: ["allPendingOrders"],
    queryFn: orderService.getNewOrders,
  });

export const useResellerOrderDetails = (id) =>
  useQuery({
    queryKey: ["reseller-order-details", id],
    queryFn: () => orderService.getResellerOrderDetails(id),
  });

export const useNewOrderDetails = (id) =>
  useQuery({
    queryKey: ["new-order-details", id],
    queryFn: () => orderService.getNewOrderDetails(id),
  });

export const useOrder = () =>
  useQuery({
    queryKey: ["allOrder"],
    queryFn: () => orderService.getNewOrders(),
  });

export const useClaimOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: orderService.claimOrder,
    onSuccess: () => {
      queryClient.invalidateQueries(["allPendingOrders"]);
    },
  });
};

export const useMakeOrder = () => {
  return useMutation({
    mutationFn: (data) => orderService.makeOrder(data),
  });
};

export const useMakePayment = () => {
  return useMutation({
    mutationFn: (data) => orderService.makePayment(data),
  });
};

export const useCompleteOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: orderService.completeOrder,
    onSuccess: () => queryClient.invalidateQueries(["reseller-orders"]),
  });
};

export const useNewOrderCompletionCode = () => {
  return useMutation({
    mutationFn: orderService.getNewOrderCompletionCode,
  });
};

export const useMyOrders = () =>
  useQuery({
    queryKey: ["reseller-orders"],
    queryFn: orderService.getResellerOrders,
  });

export const useBuyerOrders = () =>
  useQuery({
    queryKey: ["buyer-orders"],
    queryFn: orderService.getBuyerOrders,
  });

export const useBuyerOrdersItems = () =>
  useQuery({
    queryKey: ["buyer-orders-items"],
    queryFn: orderService.getAllBuyerOrdersItems,
  });

export const useBuyerOrderItemDetailed = (id) =>
  useQuery({
    queryKey: ["detailed-order-item", id],
    queryFn: () => orderService.getBuyerOrderItemDetails(id),
  });

export const useMakeReview = (itemId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["order-item-review", itemId],
    mutationFn: orderService.submitProductReview,
    onSuccess: () => {
      queryClient.invalidateQueries(["detailed-order-item", itemId]);
    },
  });
};

export const usePurchaseOrderPaymentDetails = (orderId) =>
  useQuery({
    queryKey: ["order-payment-details", orderId],
    queryFn: () => orderService.getPurchaseOrderPaymentDetails(orderId),
  });

export default orderService;
