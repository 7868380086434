import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const productDetailsSchema = yup
  .object({
    name: yup.string().required(),
    description: yup.string().required(),
    variant: yup.string().required(),
    price: yup.number().min(1).required(),
    rrp: yup
      .number()
      .min(1)
      .required()
      .moreThan(yup.ref("price", "RRP should be greater than price"))
      .label("RRP"),
    qty: yup.number().min(1).required().label("Quantity"),
  })
  .required();

const ProductDetailsStepper = ({
  productDetails,
  setProductDetails,
  handleNextStep,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productDetailsSchema),
    defaultValues: productDetails,
  });

  const navigateNextStep = (productDetails) => {
    setProductDetails(productDetails);
    handleNextStep();
  };
  return (
    <>
      <Box component="form" onSubmit={handleSubmit(navigateNextStep)}>
        <TextField
          label="Product Name"
          variant="outlined"
          fullWidth
          {...register("name")}
          error={errors.name && true}
          helperText={errors.name?.message}
          autoComplete="off"
          sx={{ mt: 1 }}
        />
        <TextField
          label="Product Description"
          variant="outlined"
          {...register("description")}
          multiline
          fullWidth
          rows={8}
          error={errors.description && true}
          helperText={errors.description?.message}
          sx={{ mt: 1 }}
        />
        <TextField
          label="Variant"
          variant="outlined"
          {...register("variant")}
          multiline
          rows={2}
          fullWidth
          placeholder="size, weight"
          error={errors.variant && true}
          helperText={errors.variant?.message}
          sx={{ mt: 1 }}
        />
        <FormControl sx={{ mt: 1 }} fullWidth>
          <InputLabel error={errors.price && true}>Price</InputLabel>
          <OutlinedInput
            label="Price"
            type="number"
            {...register("price")}
            error={errors.price && true}
            sx={{ maxWidth: 100 }}
            defaultValue={1}
          />
          <FormHelperText error={true}>{errors.price?.message}</FormHelperText>
        </FormControl>

        <FormControl sx={{ mt: 1 }} fullWidth>
          <InputLabel error={errors.rrp && true}>RRP</InputLabel>
          <OutlinedInput
            label="RRP"
            type="number"
            {...register("rrp")}
            error={errors.rrp && true}
            sx={{ maxWidth: 100 }}
            defaultValue={1}
          />
          <FormHelperText error={true}>{errors.rrp?.message}</FormHelperText>
        </FormControl>

        <FormControl sx={{ mt: 1 }} fullWidth>
          <InputLabel error={errors.qty && true}>Quantity</InputLabel>
          <OutlinedInput
            label="Quantity"
            type="number"
            {...register("qty")}
            error={errors.qty && true}
            sx={{ maxWidth: 100 }}
            defaultValue={1}
          />
          <FormHelperText error={true}>{errors.qty?.message}</FormHelperText>
        </FormControl>

        <Box>
          <Button variant="contained" type="submit">
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default ProductDetailsStepper;
