import { Box, Typography, Modal, styled, Paper } from "@mui/material";
import OrderItemsTableList from "./OrderItemsTableList";
import { useResellerOrderDetails } from "services/orderService";
import { Spinner } from "reactstrap";
import { RowBox } from "components/Product/Orders/NewOrderDetails";

const CustomModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const OrderItemsModal = ({ open, toggleModalOpen, selectedOrderId }) => {
  const { data: orderDetails, isLoading: orderDetailsLoading } =
    useResellerOrderDetails(selectedOrderId);

  if (orderDetailsLoading) return <Spinner />;

  return (
    <CustomModal open={open} onClose={() => toggleModalOpen()}>
      <Paper
        sx={{
          width: "96%",
          height: "98%",
          p: 2,
        }}
      >
        <Box flexDirection={"column"} gap={5}>
          <Typography fontWeight={"bold"} fontSize={"2rem"}>
            Order Details
          </Typography>
          <RowBox>
            <Typography fontWeight={"bold"}>Buyer Name</Typography>
            <Typography>{orderDetails.buyerName}</Typography>
          </RowBox>
          <RowBox>
            <Typography fontWeight={"bold"}>Order Amount</Typography>
            <Typography>
              Ksh. {Number(orderDetails.amount).toFixed(2)}
            </Typography>
          </RowBox>
          <RowBox>
            <Typography fontWeight={"bold"}>Order Balance</Typography>
            <Typography>
              Ksh. {Number(orderDetails.balance).toFixed(2)}
            </Typography>
          </RowBox>
          <OrderItemsTableList products={orderDetails.products} />
        </Box>
      </Paper>
    </CustomModal>
  );
};

export default OrderItemsModal;
