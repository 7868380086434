import {
  Box,
  Dialog,
  Paper,
  Stack,
  Typography,
  colors,
  styled,
} from "@mui/material";
import Carousel from "components/Carousel/Carousel";
import { Button } from "reactstrap";
import { useMutateConfirmRestockingRequest } from "services/stockService";
import Swal from "sweetalert2";
import DeliveryFeeModal from "./DeliveryFeeModal";
import { useState } from "react";
import {
  interaction,
  layer,
  custom,
  control, //name spaces
  Interactions,
  Overlays,
  Controls, //group
  Map,
  Layers,
  Overlay,
  Util, //objects
} from "react-openlayers";
import * as ol from "openlayers";
import DisplayMap from "./DisplayMap";
import { transform } from "ol/proj";

var marker = new custom.style.MarkerStyle(
  "https://openlayers.org/en/v4.0.1/examples/data/icon.png"
);
var iconFeature = new ol.Feature(
  new ol.geom.Point(transform([36.8205, -1.2886], "EPSG:4326", "EPSG:3857"))
);

var source = new ol.source.Vector({ features: [iconFeature] });

const OrderDetailsProcessing = ({
  orderDetails,
  showOrderDetailsModal,
  handleCloseModal,
}) => {
  const { mutateAsync, isLoading } = useMutateConfirmRestockingRequest();
  const [showDeliveryFeeModal, setShowDeliveryFeeModal] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const handleClose = () => {
    setShowDeliveryFeeModal(false);
  };

  const handleSubmitRequest = (data) => {
    mutateAsync(data)
      .then((res) => {
        const reject = data.status === "reject";
        Swal.fire({
          title: reject
            ? "Order Status Updated!"
            : "Order Updated Successfully!",
          text: `Order was updated successfully. ${res.message}`,
          icon: reject ? "info" : "success",
        });
        // close the details modal

        handleCloseModal();
      })
      .catch(() => {
        Swal.fire({
          title: "Request Update Failed!",
          text: "Request failed to update",
          icon: "error",
        });
      });
  };

  const handleShowMap = () => {
    setShowMap(false);
  };

  const CustomBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: 1,
  });

  return (
    <Dialog open={showOrderDetailsModal} onClose={() => handleCloseModal()}>
      <Paper
        sx={{
          px: 2,
          py: 3,
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h5" fontWeight="bold">
            Order Details
          </Typography>
          <Box maxHeight="40%" mb={2}>
            <Carousel
              items={orderDetails.images.map((image) => ({
                name: orderDetails.product_name,
                image: image.fileName,
              }))}
            />
          </Box>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Product
            </Typography>
            <Typography>{orderDetails.product_name}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Manufacturer
            </Typography>
            <Typography>{orderDetails.manufacturer}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Variation
            </Typography>
            <Typography>{orderDetails.product_variation}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Quantity
            </Typography>
            <Typography>{orderDetails.quantity}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Price
            </Typography>
            <Typography>{Number(orderDetails.price).toFixed(2)}</Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Order Cost (without delivery fee)
            </Typography>
            <Typography>
              {Number(orderDetails.order_cost).toFixed(2)}
            </Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Delivery Fee
            </Typography>
            <Typography>
              {Number(orderDetails.delivery_fee).toFixed(2)}
            </Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Total Payable
            </Typography>
            <Typography>
              {Number(orderDetails.total_payable).toFixed(2)}
            </Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Total Paid
            </Typography>
            <Typography>
              {Number(orderDetails.total_paid).toFixed(2)}
            </Typography>
          </CustomBox>
          <CustomBox>
            <Typography
              style={{ textDecoration: "underline", color: colors.blue[600] }}
            >
              Amount Due
            </Typography>
            <Typography>
              {Number(orderDetails.amount_due).toFixed(2)}
            </Typography>
          </CustomBox>
          {orderDetails.status === "pending" && (
            <Box>
              <Button
                color="primary"
                disabled={orderDetails.status !== "pending" || isLoading}
                onClick={() => {
                  setShowDeliveryFeeModal(true);
                }}
              >
                Confirm
              </Button>
              <Button
                color="danger"
                disabled={orderDetails.status !== "pending" || isLoading}
                onClick={() => {
                  handleSubmitRequest({
                    restockId: `${orderDetails.id}`,
                    status: "reject",
                  });
                }}
              >
                Reject
              </Button>
            </Box>
          )}
        </Box>
        <DeliveryFeeModal
          orderId={orderDetails.id}
          handleSubmitRequest={handleSubmitRequest}
          isOpen={showDeliveryFeeModal}
          handleClose={handleClose}
        />
        <DisplayMap
          resellerCoordinates={orderDetails.resellerCoordinates}
          manufacturerCoordinates={orderDetails.manufacturerCoodinates}
          handleShowMap={handleShowMap}
          isOpen={showMap}
        />
      </Paper>
    </Dialog>
  );
};
export default OrderDetailsProcessing;
