import {
  Box,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    fee: yup.number().min(0).required().label("Delivery fee"),
  })
  .required();

const DeliveryFeeModal = ({
  isOpen,
  handleClose,
  orderId,
  handleSubmitRequest,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSetDeliveryFee = (input) => {
    handleSubmitRequest({
      restockId: orderId,
      status: "accept",
      deliveryFee: input.fee,
    });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => handleClose()}>
        <Paper
          sx={{
            py: 3,
            px: 2,
            width: 400,
          }}
        >
          <Typography variant="h5" fontWeight="bold" textAlign={"center"}>
            Add Delivery Fee
          </Typography>
          <Divider />
          <Box
            component={"form"}
            onSubmit={handleSubmit(handleSetDeliveryFee)}
            sx={{ display: "flex", flexDirection: "column", mt: 1 }}
            gap={1}
          >
            <Box>
              <FormControl>
                <InputLabel>Fee</InputLabel>
                <OutlinedInput
                  label="Fee"
                  {...register("fee")}
                  error={errors.fee}
                  type="number"
                  sx={{ maxWidth: 250 }}
                />
                <FormHelperText error={errors.fee}>
                  {errors.fee?.message}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <Button type="submit" color="primary">
                Approve
              </Button>
            </Box>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};
export default DeliveryFeeModal;
