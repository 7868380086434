import { Box, Dialog, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useUpdateProductDetails } from "services/productService";
import Swal from "sweetalert2";
import { useUpdateProductImages } from "services/productService";
import ProductImagesUpload from "./ProductImageUpload";

function EditProduct({
  theId,
  name,
  description,
  images,
  isOpen,
  handleClose,
}) {
  const [newName, setNewName] = useState(name);
  const [newDescription, setNewDescription] = useState(description);
  const [error, setError] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const { mutateAsync } = useUpdateProductDetails();
  const { mutateAsync: mutateProductImagesAsync } = useUpdateProductImages();

  // prepare upload images form data
  const prepareFormData = (productId, images) => {
    const formData = new FormData();
    images.forEach((image) => formData.append("images", image));
    formData.append("productId", productId);
    return formData;
  };

  // upload product images
  const uploadProductImages = (productId) => {
    const imagesFormData = prepareFormData(productId, selectedImages);
    mutateProductImagesAsync(imagesFormData)
      .then((res) => {
        Swal.fire({
          title: "Product Details Update Success",
          text: "You have updated the product details successfully.",
          icon: "success",
        });
      })
      .catch((error) => {
        const message = error.response.data.message;
        Swal.fire({
          title: "Error occured",
          text: `Failed to upload product images, responded with: ${message}`,
          icon: "error",
        });
      });
  };

  const mutateASycFunction = (id, name, description) => {
    mutateAsync({ id: id, name: name, description: description })
      .then(() => {
        //Update Images
        uploadProductImages(id);
      })
      .catch(() => {
        Swal.fire({
          title: "Product Details Update Failed",
          text: "Failed to update the product details.",
          icon: "error",
        });
      });
  };

  const editProductDetails = (productId) => {
    if (selectedImages.length < 1) {
      // show error message
      Swal.fire({
        title: "Images Required",
        text: "Please select atleast one image to updload",
        icon: "error",
        showCancelButton: false,
        showCloseButton: false,
      });
    } else if (newName === "") {
      Swal.fire({
        title: "Name Required",
        text: "Please input Name",
        icon: "error",
        showCancelButton: false,
        showCloseButton: false,
      });
    } else if (newDescription === "") {
      Swal.fire({
        title: "Description Required",
        text: "Please input description",
        icon: "error",
        showCancelButton: false,
        showCloseButton: false,
      });
    } else {
      mutateASycFunction(productId, newName, newDescription);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={() => handleClose()}>
        <Box
          sx={{
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Form>
            <Typography variant="h5">Edit Product Details </Typography>
            <Typography sx={{ color: "red" }}>{error}</Typography>
            <TextField
              label="Product Name"
              type="text"
              hint="Enter Name"
              fullWidth
              margin="normal"
              required
              value={newName}
              onChange={(event) => setNewName(event.target.value)}
            />
            <TextField
              label="Product description"
              type="text"
              hint="Enter description"
              fullWidth
              margin="normal"
              required
              value={newDescription}
              onChange={(event) => setNewDescription(event.target.value)}
            />
            <div>
              <ProductImagesUpload
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
              />
            </div>
            <br></br>
            <Button onClick={() => editProductDetails(theId)}>
              Update details
            </Button>
          </Form>
        </Box>
      </Dialog>
    </div>
  );
}

export default EditProduct;
