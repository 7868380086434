import React, { createContext, useContext } from "react";
import Cookies from "js-cookie";
import { useAuthenticated } from "services/getuserService";
import { Spinner } from "reactstrap";

const AuthContext = createContext();

export const useIsAuthenticated = () => useContext(AuthContext);
const token = Cookies.get("access_token");

const AuthProvider = ({ children }) => {
  const {
    data: user,
    isSuccess: isAuthenticated,
    isLoading,
  } = useAuthenticated(token);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
