import Index from "views/finance/Index";
import PurchaseInvoice from "views/finance/PurchaseInvoice";
import Payments from "views/finance/Payments";
import RestockSalesSettlements from "views/finance/RestockSalesSettlements";
import PurchaseSalesSettlements from "views/finance/PurchaseSalesSettlements";
import PartnerManager from "views/finance/PartnerManager";
import ProfitDisbursement from "views/finance/ProfitDisbursement";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/finance",
  },
  {
    path: "/purchases-invoices",
    name: "Purchase Invoices",
    icon: "ni ni-cart text-black",
    component: PurchaseInvoice,
    layout: "/finance",
  },
  {
    path: "/payments",
    name: "Payments In",
    icon: "ni ni-money-coins text-green",
    component: Payments,
    layout: "/finance",
  },
  {
    path: "/restock-settlement",
    name: "Restock Settlements",
    icon: "ni ni-send text-primary",
    component: RestockSalesSettlements,
    layout: "/finance",
  },
  {
    path: "/purchase-settlement",
    name: "Purchase Settlement",
    icon: "ni ni-spaceship text-black",
    component: PurchaseSalesSettlements,
    layout: "/finance",
  },
  {
    path: "/operation-partners",
    name: "Operation Partners",
    icon: "ni ni-collection text-red",
    component: PartnerManager,
    layout: "/finance",
  },
  // {
  //   path: "/deductions-settings",
  //   name: "Deductions Charges",
  //   icon: "ni ni-settings",
  //   component: DeductionsSettings,
  //   layout: "/finance",
  // },
  {
    path: "/profit-distribution",
    name: "Profit Disbursement",
    icon: "ni ni-diamond",
    component: ProfitDisbursement,
    layout: "/finance",
  },
];
export default routes;
