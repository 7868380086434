import { Box, Typography, styled } from "@mui/material";

const CustomFlexColumnTypography = styled(Box)({
  display: "flex",
  gap: 3,
});

const ProductPriceExcerpt = ({ variations }) => {
  return (
    <CustomFlexColumnTypography>
      <Typography>Price:</Typography>
      <Typography>Ksh. {Number(variations[0].rrp).toFixed(2)}</Typography>
    </CustomFlexColumnTypography>
  );
};
export default ProductPriceExcerpt;
