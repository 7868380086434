import { styled } from "@mui/material";
import MUIDataTable from "mui-datatables";

const url = process.env.REACT_APP_API_URL;

const CustomMUIDataTable = styled(MUIDataTable)({
  boxShadow: "none",
});

const OrderItemListTable = ({ items }) => {
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRender: (value) => {
          const imagesLength = Array(value).length;
          if (imagesLength > 0) {
            return (
              <img
                alt="product"
                height={"90px"}
                width={"auto"}
                src={`${url}uploads/products/${value[0].fileName}`}
              />
            );
          }
          return <img alt="product" src="" />;
        },
      },
    },
    {
      name: "productName",
      label: "Product Name",
    },
    {
      name: "variationName",
      label: "Variation",
    },
    {
      name: "quantity",
      label: "Quantity",
    },
    {
      name: "rrp",
      label: "RRP",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "total",
      label: "Total (Ksh)",
      options: {
        customBodyRender: (value, tableMeta) => {
          console.log(tableMeta);
          const rowsData = tableMeta.rowData;
          const quantity = rowsData[4];
          const price = rowsData[5];
          return Number(Number(price) * Number(quantity)).toFixed(2);
        },
      },
    },
  ];
  return (
    <CustomMUIDataTable
      title="Ordered Items"
      columns={columns}
      data={items}
      options={{
        selectableRows: "none",
        pagination: false,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
      }}
    />
  );
};
export default OrderItemListTable;
