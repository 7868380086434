import MUIDataTable from "mui-datatables";
import { Button, Spinner } from "reactstrap";
import { useNewOrders } from "services/orderService";

import { parseISO, formatDistanceToNow } from "date-fns";
import { titleCase } from "title-case";
import { useState } from "react";
import { Divider, Modal, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material";
import NewOrderDetails from "./NewOrderDetails";

const CustomModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  padding: "2rem",
});

const NewOrderTableListing = () => {
  const { data: newOrders = [], isLoading } = useNewOrders();
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const columns = [
    {
      name: "order_id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "Ordered On",
      options: {
        filter: true,
        customBodyRender: (value) => {
          const parsedDate = parseISO(value);
          const formatedDate = formatDistanceToNow(parsedDate);
          return titleCase(`${formatedDate} ago`);
        },
      },
    },
    {
      name: "buyerName",
      label: "Buyer Name",
    },
    {
      name: "amount",
      label: "Total Amount",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "balance",
      label: "Amount Due",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "productsCount",
      label: "No of Items",
      options: {
        filter: false,
      },
    },
    {
      name: "order_id",
      lable: "Action",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <>
              <Button color="primary" onClick={() => setSelectedOrderId(value)}>
                Claim
              </Button>
            </>
          );
        },
      },
    },
  ];

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <MUIDataTable
        columns={columns}
        data={newOrders}
        options={{
          selectableRows: "none",
        }}
      />

      {Boolean(selectedOrderId) && (
        <CustomModal
          open={Boolean(selectedOrderId)}
          onClose={() => setSelectedOrderId(null)}
        >
          <NewOrderDetails
            orderId={selectedOrderId}
            callback={() => setSelectedOrderId(null)}
          />
        </CustomModal>
      )}
    </>
  );
};
export default NewOrderTableListing;
