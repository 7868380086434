import { Box, Paper, Typography } from "@mui/material";
import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { format, parse } from "date-fns";
import { Spinner } from "reactstrap";
import { useSpeficPayment } from "services/financeServices";
import { parseModeOfPayment } from "./InvoiceReport";
import Swal from "sweetalert2";
import InvoiceHeader from "./invoice/InvoiceHeader";
import PageSeparator from "./invoice/PageSeparator";

const styles = {
  page: {
    flexDirection: "column",
    position: "relative",
  },
  top_wave: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
    zIndex: -1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    gap: 10,
  },
  header: {
    display: "flex",
    gap: 10,
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid black",
    marginTop: "3px",
    marginBottom: "3px",
  },
  itemGroup: {
    flexDirection: "row",
    marginLeft: 20,
  },
  itemGroupKey: {
    marginRight: "1px",
    color: "#1a874e",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  body: {
    marginTop: "10px",
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableCel: {
    flex: 1,
    border: "1px solid #000000",
    padding: "3px",
    marginRight: "-1px",
    marginTop: "-1px",
  },
  textRight: {
    textAlign: "right",
  },
  themeText: {
    color: "#046631",
  },
  footer: {},
};

const PaymentReport = ({ paymentId }) => {
  const {
    data: paymentDetails,
    isLoading: paymentLoading,
    isError: paymentHasError,
    error: paymentError,
  } = useSpeficPayment(paymentId);

  const fontStyles = StyleSheet.create({
    documentFontStyle: {
      fontFamily: "Courier",
      fontSize: 12,
    },
    itemGroupKeyFont: {
      fontWeight: "bold",
    },
  });

  if (paymentLoading)
    return (
      <Paper
        sx={{
          height: "95%",
          width: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </Paper>
    );

  if (paymentHasError)
    return (
      <Paper
        sx={{
          height: "95%",
          width: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box flexDirection={"column"} textAlign={"center"}>
          <Typography>Unable to fetch payment details</Typography>
          <Typography>{paymentError.response.data.message}</Typography>
        </Box>
      </Paper>
    );

  return (
    <PDFViewer height="95%" width="95%">
      <Document style={fontStyles.documentFontStyle}>
        <Page size="A4">
          <InvoiceHeader></InvoiceHeader>

          <View style={styles.content}>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Amount Paid:</Text>
              <Text>{Number(paymentDetails.invoiceTotal).toFixed(2)} KSH</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Is it a valid invoice? </Text>
              <Text>{paymentDetails.validInvoice ? "Yes" : "No"}</Text>
            </View>

            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Customer Name:</Text>
              <Text>{paymentDetails.customerName}</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Customer Email:</Text>
              <Text>{paymentDetails.customerEmail}</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Customer Phone Number:</Text>
              <Text>{paymentDetails.customerPhoneNumber}</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Customer's Residence:</Text>
              <Text>{paymentDetails.customerCounty}</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Payment Option:</Text>
              <Text>{paymentDetails.paymentOption}</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Transaction Code:</Text>
              <Text>{paymentDetails.transactionCode}</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>
                Date that the payment was made:
              </Text>
              <Text>{paymentDetails.transactionDate}</Text>
            </View>
            <View style={styles.itemGroup}>
              <Text style={styles.itemGroupKey}>Payment Details: </Text>
              <Text>{paymentDetails.invoiceDescription}</Text>
            </View>
          </View>
          <View style={styles.divider} />
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default PaymentReport;
// "invoiceNumber": null,
// "invoiceTotal": null,
// "amountPaid": 1,
// "datePaid": "2023-08-25T12:12:36.678Z",
// "paidBy": "NICHOLAS ",
// "paymentId": "eb0f64e7-31bb-4fcb-b44b-bd39fc5fe4be",
// "paymentMode": "customerToBusiness",
// "transactionCode": "RHP41U77EA",
// "validInvoice": false
