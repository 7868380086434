import CurrentStockListing from "components/Product/Stock/CurrentStockListing";

const Stock = () => {
  return (
    <>
      <CurrentStockListing />
    </>
  );
};
export default Stock;
