import React, { useContext, useState } from 'react';
import { createContext } from 'react';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

const SearchProvider = ({ children }) => {
	const [search, setSearch] = useState('');

	const handleSearch = (value) => {
		setSearch(value);
	};

	return (
		<SearchContext.Provider value={{ search, handleSearch }}>
			{children}
		</SearchContext.Provider>
	);
};

export default SearchProvider;
