import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { getAllCartItems, clearedItems } from "views/farmer/shoppingCartSlice";
import { useMakeOrder } from "services/orderService";
import { useIsAuthenticated } from "providers/authprovider";

import Swal from "sweetalert2";
import { useMakePayment } from "services/orderService";
const schema = yup
  .object({
    option: yup
      .string()
      .oneOf(["now", "later"])
      .required()
      .label("Payment option"),
    amount: yup.number().min(1).required(),
  })
  .required();

const PaymentOptionSelection = () => {
  const { user, isAuthenticated, isLoading } = useIsAuthenticated();
  const { mutateAsync: orderMutation, isLoading: orderLoading } =
    useMakeOrder();

  const { mutateAsync: paymentMutation, isLoading: paymentLoading } =
    useMakePayment();

  const history = useHistory();

  const items = useSelector(getAllCartItems);
  const dispatch = useDispatch();

  const getCartTotal = () => {
    return items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  if (!isLoading && !isAuthenticated) history.push("/auth/login");

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      option: "later",
      amount: getCartTotal(),
    },
  });

  const handleMakeOrder = async () => {
    const products = items?.map((item) => ({
      id: item.id,
      quantity: item.quantity,
    }));
    const orderData = {
      products,
      delivery_location: "123,124",
    };
    return orderMutation(orderData);
  };

  const clearCartAndRedirect = () => {
    dispatch(clearedItems());
    history.push("/");
  };

  const completePayLaterOrder = async () => {
    return Swal.fire({
      title: "Success",
      text: "Order was received successfully, it will be processed and deliverd",
      icon: "success",
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6",
    });
  };

  const handleStkRequest = async ({ orderId, amount }) => {
    const stkRequestData = {
      phone_number: "254" + String(user.phone_number).slice(1),
      amount: amount,
      account_reference: orderId,
    };
    return paymentMutation(stkRequestData);
  };

  const paymentInitiatedAlert = async () => {
    return Swal.fire({
      title: "Payment Initiated",
      text: "Payment request was initiated, kindly confirm.",
      icon: "info",
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: "Ok",
    });
  };
  const orderFailed = async () => {
    return Swal.fire({
      title: "Order Failed",
      text: "Unable to make the order at the moment.",
      icon: "error",
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
    });
  };

  const paymentRequestFailed = async () => {
    return Swal.fire({
      title: "Payment Error",
      text: "Unable to process payment now, try again letter",
      icon: "error",
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: "Exit",
    });
  };

  const handleProcessOrder = (data) => {
    handleMakeOrder()
      // order saved successfully
      .then((res) => {
        if (data.option === "later") {
          // complete order
          completePayLaterOrder().then(() => clearCartAndRedirect());
        } else if (data.option === "now") {
          // stk push
          handleStkRequest({ orderId: res.invoiceNumber, amount: data.amount })
            .then(() => paymentInitiatedAlert().then(clearCartAndRedirect()))
            .catch(() => paymentRequestFailed());
        }
      })
      // order failed to save;
      .catch(() => orderFailed());
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleProcessOrder)}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <FormLabel id="payment_option">Payment Option</FormLabel>
        <Controller
          name="option"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                disabled={items.length < 1}
                label="Pay on delivery"
                control={<Radio />}
                value="later"
              />
              <FormControlLabel
                disabled={items.length < 1}
                label={
                  <>
                    <Typography sx={{ marginRight: 1 }} component="span">
                      M-Pesa
                    </Typography>
                    <img src="/mpesa.png" alt="mpesa" width="50px" />
                  </>
                }
                control={<Radio />}
                value="now"
              />
              <FormHelperText error={errors.option && true}>
                {errors.option?.message}
              </FormHelperText>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl
        fullWidth
        sx={{
          marginBottom: 2,
        }}
        hidden={watch("option") !== "now"}
      >
        <FormLabel id="amount">Amount</FormLabel>
        <TextField
          placeholder="amount"
          variant="outlined"
          {...register("amount")}
          fullWidth
        />
      </FormControl>

      <FormControl>
        <Button
          disabled={orderLoading || paymentLoading || items.length < 1}
          type="submit"
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </FormControl>
    </Box>
  );
};
export default PaymentOptionSelection;
