const { createTheme } = require("@mui/material");

// 'deep-teal': {
//   DEFAULT: '#004331',
//   50: '#00FBB7',
//   100: '#00E6A8',
//   200: '#00BD8B',
//   300: '#00956D',
//   400: '#006C4F',
//   500: '#004331',
//   600: '#000B08',
//   700: '#000000',
//   800: '#000000',
//   900: '#000000',
//   950: '#000000'
// },
const useCustomTheme = (mode) =>
  createTheme({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // normal mode theme
            primary: {
              main: "#00674B",
            },
            divider: "#00674B",
          }
        : {
            // dark mode theme
          }),
    },
  });

export default useCustomTheme;
