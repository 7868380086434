import SubCountyForm from "components/admin/location/SubCountyForm";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SubCountyList from "./SubCountyList";

const SubCountyModal = ({ county, isOpen, toggle }) => {
  const countyId = county?.id || null;
  const subCountiesList = countyId ? (
    <SubCountyList countyId={countyId} />
  ) : null;

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={true}>
      <ModalHeader toggle={toggle}>{`${
        county ? county.name : ""
      } - Sub-Counties`}</ModalHeader>
      <ModalBody>
        <h2>Sub Counties Data</h2>
        <SubCountyForm countyId={county?.id || ""} />
        {subCountiesList}
      </ModalBody>
      <ModalFooter>
        <p className="text-left">&copy; LiMA {new Date().getFullYear()} </p>
      </ModalFooter>
    </Modal>
  );
};
export default SubCountyModal;
