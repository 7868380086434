import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormText,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  useCountiesQuery,
  useSubCountiesQuery,
} from "../../hooks/useLocationData";
import { useEffect, useState } from "react";
import userService from "services/userService";
import MessageAlert from "components/Shared/MessageAlert";
import ErrorMessages from "components/Shared/ErrorMessages";

const schema = yup
  .object({
    first_name: yup.string().required().label("First Name"),
    last_name: yup.string().required().label("Last Name"),
    phone_number: yup.string().min(10).required().label("Phone Number"),
    email: yup.string().email().required().label("Email"),
    id_number: yup.string().required().min(7).label("ID Number"),
    countyId: yup.string().required().label("County"),
    sub_countyId: yup.string().required().label("Sub County"),
    password: yup.string().required().label("Password"),
  })
  .required();

const Register = () => {
  const {
    trigger,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      countyId: "",
      sub_countyId: "",
    },
  });
  const [countyId, setCountyId] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latLon, setLatLon] = useState("");

  const { data: counties, isLoading: countiesLoading } = useCountiesQuery();
  const { data: subCounties, isLoading: subCountiesLoading } =
    useSubCountiesQuery();

  // filter sub-county based on county
  const filteredSubCounties = subCounties?.filter(
    (subCounty) => subCounty.county_id.valueOf() === countyId.valueOf()
  );

  const subCountyName = filteredSubCounties?.map(
    (subCounty) => subCounty.name
  )[0];

  //Consume the API here to Get Cordinates of the subCounty Name
  useEffect(() => {
    // Define the API URL from OpenStreatMap
    const apiURL =
      "https://nominatim.openstreetmap.org/search?format=json&limit=1&q=" +
      subCountyName;

    // Make the API request
    fetch(apiURL)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          // Extract the latitude and longitude values
          const lat = data[0].lat;
          const lon = data[0].lon;

          // Concatenate the longitude and latitude
          const concatenatedLatLon = `${lon}, ${lat}`;

          // Set the concatenated value in state
          setLatLon(concatenatedLatLon);
        } else {
          console.error("No results found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [subCountyName]);

  const handleCountyChange = (e) => {
    const { value } = e.target;
    setCountyId(value);
    setValue("sub_countyId", "");
    setValue("countyId", value);
    trigger("countyId");
  };

  const handleRegistration = (data) => {
    setIsSubmitting(true);
    setRegistrationError(null);
    setRegistrationSuccess("");

    //Add Cordinates to data from registration Form
    data.fixed_latlong = latLon;

    userService
      .newUserRegistration(data)
      .then((data) => {
        const { email } = data;
        setRegistrationSuccess(`Account for ${email} created successfully`);
        reset();
        setTimeout(() => {
          window.location = "/auth/login";
        }, 2000);
      })
      .catch((err) => {
        setRegistrationError(err);
      });
  };

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-neutral shadow border-1">
          <CardHeader className="bg-transparent pb-5">
            <h2 className="text-muted text-center mt-4 mb-1">
              Register Account
            </h2>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <ErrorMessages error={registrationError} />
            <MessageAlert type="success" message={registrationSuccess} />
            <Form role="form" onSubmit={handleSubmit(handleRegistration)}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    name="first_name"
                    placeholder="First Name"
                    className="form-control"
                    type="text"
                    {...register("first_name")}
                  />
                </InputGroup>
                <FormText color="danger">{errors.first_name?.message}</FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    name="last_name"
                    placeholder="Last Name"
                    {...register("last_name")}
                    type="text"
                    className="form-control"
                  />
                </InputGroup>
                <FormText color="danger">{errors.last_name?.message}</FormText>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-mobile-button" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    name="phone_number"
                    placeholder="Phone Number"
                    {...register("phone_number")}
                    className="form-control"
                    type="tel"
                    autoComplete="off"
                  />
                </InputGroup>
                <FormText color="danger">
                  {errors.phone_number?.message}
                </FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    name="email"
                    placeholder="Email"
                    type="email"
                    {...register("email")}
                    className="form-control"
                    autoComplete="new-email"
                  />
                </InputGroup>
                <FormText color="danger">{errors.email?.message}</FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="ID Number"
                    type="tel"
                    {...register("id_number")}
                    className="form-control"
                    autoComplete="new-email"
                  />
                </InputGroup>
                <FormText color="danger">{errors.id_number?.message}</FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-world" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <select
                    {...register("countyId")}
                    className="form-control text-capitalize"
                    disabled={countiesLoading}
                    onChange={handleCountyChange}
                  >
                    <option value="" disabled>
                      Select County
                    </option>
                    {counties?.map((county) => (
                      <option key={county.id} value={county.id}>
                        {county.name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
                <FormText color="danger">{errors.countyId?.message}</FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-world" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <select
                    {...register("sub_countyId")}
                    className="form-control text-capitalize"
                    disabled={subCountiesLoading}
                  >
                    <option value="" disabled>
                      Select Sub-County
                    </option>
                    {filteredSubCounties?.map((subCounty) => (
                      <option key={subCounty.id} value={subCounty.id}>
                        {subCounty.name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
                <FormText color="danger">
                  {errors.sub_countyId?.message}
                </FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    name="password"
                    placeholder="Password"
                    className="form-control"
                    type="password"
                    autoComplete="new-password"
                    {...register("password")}
                  />
                </InputGroup>
                <FormText color="danger">{errors.password?.message}</FormText>
              </FormGroup>
              {/* <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  <span className="text-success font-weight-700">strong</span>
                </small>
              </div> */}
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      color="default"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  disabled={isSubmitting}
                  className="mt-4"
                  color="default"
                  type="submit"
                >
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
