/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import AuthProvider from "providers/authprovider";
import FarmerLayout from "layouts/Farmer.js";
import ResellerLayout from "layouts/Reseller.js";
import ManufacturerLayout from "layouts/Manufacturer.js";
import FinanceLayout from "layouts/FinanceLayout";
import store from "app/store";
import { persistor } from "app/store";
import SearchProvider from "providers/searchprovider";
import { App } from "@capacitor/app";
import { Dialog } from "@capacitor/dialog";

require("./index.css");

App.addListener("backButton", async () => {
  const { value } = await Dialog.confirm({
    title: "Exit!",
    message: `Are you sure you'd like to exit?`,
  });

  if (value === true) {
    App.exitApp();
  }
});
// overall query client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <SearchProvider>
            <BrowserRouter>
              <Switch>
                <Route
                  from="/manufacturer"
                  render={(props) => <ManufacturerLayout {...props} />}
                />
                <Route
                  path="/reseller"
                  render={(props) => <ResellerLayout {...props} />}
                />
                <Route
                  path="/finance"
                  render={(props) => <FinanceLayout {...props} />}
                />
                <Route
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
                <Route
                  path="/finance"
                  render={(props) => <FinanceLayout {...props} />}
                />
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
                <Route
                  from="/"
                  render={(props) => <FarmerLayout {...props} />}
                />
              </Switch>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </BrowserRouter>
          </SearchProvider>
        </AuthProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
