import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
// import { Container } from "reactstrap";
// // core components
// import { useMediaQuery } from "react-responsive";
// import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "farmer-routes.js";

// import FarmerSideBar from "components/Sidebar/FarmerSideBar";
import FarmerNavbar from "components/Navbars/FarmerNavbar2";
import { CssBaseline, ThemeProvider } from "@mui/material";
import useCustomTheme from "theme/customTheme";

import "./farmer-styling.css";

const Farmer = (props) => {
  const theme = useCustomTheme("light");

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/farmer") {
        if (prop.path === "/") {
          return (
            <Route
              exact
              path={prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        {/* Layout design with farmer navbar and sidebar */}
        <FarmerNavbar />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/" />
        </Switch>
      </ThemeProvider>
    </>
  );
};

export default Farmer;
