import { Box, MenuItem, Select } from "@mui/material";
import ManufacturerRestockRequest from "components/Product/Stock/manufacturer/ManufacturerRestockRequest";
import { useState } from "react";
import { Container, Row, Card, CardBody } from "reactstrap";
// core components

const Restock = () => {
  const [status, setStatus] = useState("pending");
  return (
    <>
      <div className="header bg-gradient-default pb-8 pt-5 pt-md-6"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        {/* status -> | pending | accepted | rejected | transit | delivered | expired */}
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Box>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    size="small"
                  >
                    <MenuItem value="pending">New Orders</MenuItem>
                    <MenuItem value="accepted">Confirmed</MenuItem>
                    <MenuItem value="to_pay">Pending Payment</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                    <MenuItem value="transit">To Deliver</MenuItem>
                    <MenuItem value="delivered">Delivered</MenuItem>
                    <MenuItem value="expired">Expired</MenuItem>
                  </Select>
                </Box>
                <ManufacturerRestockRequest status={status} />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Restock;
