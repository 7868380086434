import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  colors,
} from "@mui/material";
import React, { useState } from "react";
import ProductDetailsStepper from "./stepper/ProductDetailsStepper";
import ProductVariationStepper from "./stepper/ProductVariationStepper";
import ProductImagesUploadStepper from "./stepper/ProductImagesUploadStepper";
import { useUploadProductImages } from "services/productService";
import Swal from "sweetalert2";
import { useCreateProduct } from "services/productService";

const steps = [
  {
    label: "Product Details",
    optional: false,
    component: ProductDetailsStepper,
  },
  { label: "Variations", optional: true, component: ProductDetailsStepper },
  { label: "Images", optional: false, component: ProductDetailsStepper },
];

const AddNewProduct = () => {
  const { mutateAsync: mutateProductImagesAsync } = useUploadProductImages();
  const { mutateAsync: mutateCreateProduct } = useCreateProduct();

  // parent state management for all steps
  const [productDetails, setProductDetails] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [variations, setVariations] = useState([]);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step.optional;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(steps[activeStep])) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(steps[activeStep]);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(steps[activeStep])) {
      throw new Error("You can't skip a step that isn't optional");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(steps[activeStep]);

      return newSkipped;
    });
  };

  const handleReset = () => {
    setProductDetails({});
    setSelectedImages([]);
    setVariations([]);
    setActiveStep(0);
  };

  // prepare upload images form data
  const prepareFormData = (productId, images) => {
    const formData = new FormData();
    images.forEach((image) => formData.append("images", image));
    formData.append("productId", productId);
    return formData;
  };

  // upload product images
  const uploadProductImages = (productId) => {
    const imagesFormData = prepareFormData(productId, selectedImages);
    mutateProductImagesAsync(imagesFormData)
      .then((res) => {
        Swal.fire({
          title: "Product Images Uploaded",
          text: `Product created and ${res.message}`,
          icon: "success",
        });
        handleReset();
      })
      .catch((error) => {
        const message = error.response.data.message;
        Swal.fire({
          title: "Error occured",
          text: `Failed to upload product images, responded with: ${message}`,
          icon: "error",
        });
      });
  };
  const handleSubmitDetails = () => {
    // final stage
    // submit product with variation
    // name, description, variant, price, rrp, qty
    const product = {
      name: productDetails.name,
      description: productDetails.description,
      variations: [
        {
          variation: productDetails.variant,
          price: Number(productDetails.price),
          rrp: Number(productDetails.rrp),
          stockQuantity: Number(productDetails.qty),
        },
      ],
    };

    // from optional extra variations
    product.variations.push(
      ...variations.map((variation) => ({
        variation: variation.variation,
        price: Number(variation.price),
        rrp: Number(variation.rrp),
        stockQuantity: Number(variation.qty),
      }))
    );

    // async mutate create product -> then on success upload the images
    mutateCreateProduct(product)
      .then((productId) => {
        uploadProductImages(productId);
      })
      .catch((error) => {
        const message = error.response.data.message;
        Swal.fire({
          title: "Product creation failed",
          text: `Failed to create product, responsed with: ${message}`,
          icon: "error",
        });
      });
    // reset all the steps data
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepOptional(step)) {
            labelProps.optional = (
              <Typography fontSize="small" color={colors.grey[500]}>
                Optional
              </Typography>
            );
          }

          if (isStepSkipped(steps[index])) {
            stepProps.completed = false;
          }

          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* Render the active stepper component */}
      {activeStep === 0 && (
        <ProductDetailsStepper
          productDetails={productDetails}
          setProductDetails={setProductDetails}
          handleNextStep={handleNext}
        />
      )}

      {activeStep === 1 && (
        <ProductVariationStepper
          variations={variations}
          setVariations={setVariations}
          handleNextStep={handleNext}
          handlePrevStep={handleBack}
          handleSkipStep={handleSkip}
        />
      )}

      {activeStep === 2 && (
        <ProductImagesUploadStepper
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          handlePrevStep={handleBack}
          submitDetails={handleSubmitDetails}
        />
      )}
    </Box>
  );
};
export default AddNewProduct;
