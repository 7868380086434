import { Text, View } from "@react-pdf/renderer";
import { titleCase } from "title-case";
import { styles } from "../InvoiceReport";

const InvoiceCharges = ({ invoice }) => {
  return (
    <View style={{ flexDirection: "column", gap: 10 }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ ...styles.itemGroup, flex: 1 }}>
          <Text style={styles.itemGroupKey}>Invoice Total:</Text>
          <Text>{Number(invoice.invoiceTotal).toFixed(2)}</Text>
        </View>
        <View style={{ ...styles.itemGroup, flex: 1 }}>
          <Text style={styles.itemGroupKey}>Invoice Balance:</Text>
          <Text>{Number(invoice.invoiceBalance).toFixed(2)}</Text>
        </View>
      </View>
      <View style={styles.itemGroup}>
        <Text style={styles.itemGroupKey}>Status:</Text>
        <Text>{titleCase(String(invoice.orderStatus).replace("_", " "))}</Text>
      </View>
    </View>
  );
};
export default InvoiceCharges;
