import { Bars } from "react-loader-spinner";
import { Box, styled } from "@mui/material";

const LoadingOverlay = () => {
  const LoadingBarContainer = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    minHeight: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  return (
    <LoadingBarContainer>
      <Bars width={100} height={100} color="inherit" />
    </LoadingBarContainer>
  );
};
export default LoadingOverlay;
