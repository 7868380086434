import { Box, Divider, Paper, Typography, styled } from "@mui/material";
import { Button, Spinner } from "reactstrap";
import { useNewOrderDetails } from "services/orderService";
import OrderItemListTable from "./OrderItemListTable";
import { useClaimOrder } from "services/orderService";
import Swal from "sweetalert2";

export const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 5,
});

export const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const NewOrderDetails = ({ orderId, callback }) => {
  const { mutateAsync, isLoading: claimLoading } = useClaimOrder();

  const { data: orderDetails = null, isLoading: detailsLoading } =
    useNewOrderDetails(orderId);

  const handleClaimOrder = () => {
    mutateAsync(orderId)
      .then((res) => {
        Swal.fire({
          title: "Successfully claimed!",
          text: "Order successfully claimed",
          icon: "success",
        });
        callback();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error Occurred!",
          text: "Unable to claim order. " + `${error.response.data.message}`,
          icon: "error",
        });
      });
  };

  if (detailsLoading) {
    return (
      <Paper sx={{ width: "100%" }}>
        <Spinner />;
      </Paper>
    );
  }
  return (
    <Paper
      sx={{
        width: "100%",
        padding: "2rem",
        flexDirection: "row",
      }}
    >
      <Typography fontWeight="bold">Purchase Information</Typography>
      <Divider></Divider>

      <ColumnBox sx={{ my: "1rem" }}>
        <RowBox>
          <Typography fontWeight={"bold"}>Buyer Name:</Typography>
          <Typography>{orderDetails.buyerName}</Typography>
        </RowBox>
        <RowBox>
          <Typography fontWeight={"bold"}>Total Items:</Typography>
          <Typography>{orderDetails.productsCount}</Typography>
        </RowBox>
        <RowBox>
          <Typography fontWeight={"bold"}>Estimated Route Distance:</Typography>
          <Typography>{orderDetails.buyerLocation}</Typography>
        </RowBox>
        <RowBox sx={{ gap: "2rem" }}>
          <RowBox>
            <Typography fontWeight={"bold"}>Total:</Typography>
            <Typography>
              Ksh. {Number(orderDetails.amount).toFixed(2)}
            </Typography>
          </RowBox>
          <RowBox>
            <Typography fontWeight={"bold"}>Balance:</Typography>
            <Typography>
              Ksh. {Number(orderDetails.balance).toFixed(2)}
            </Typography>
          </RowBox>
        </RowBox>
        <Box sx={{ mt: "1rem", height: "60vh", overflow: "auto" }}>
          <OrderItemListTable items={orderDetails.items} />
        </Box>
      </ColumnBox>
      <Box>
        <Button
          color="success"
          onClick={handleClaimOrder}
          disabled={claimLoading}
        >
          Claim
        </Button>
      </Box>
    </Paper>
  );
};
export default NewOrderDetails;
