// import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components

import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import productService from "services/productService";

import ViewProduct from "./ViewProduct";
import Swal from "sweetalert2";
import EditProduct from "./EditProduct";
import RestockRequestModal from "components/Product/Stock/RestockRequestModal";
import AddNewProduct from "components/Product/Product/AddNewProduct";
import { useManufacturerProducts } from "services/productService";

const Product = () => {
  const [item, setItem] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showRestockModal, setShowRestockModal] = useState(false);

  const { data = [], isLoading, error, refetch } = useManufacturerProducts();

  let toggle = () => {
    setShowAddModal(!showAddModal);
    refetch();
  };

  let toggleRestockModalView = (item = null) => {
    setItem(item);
    setShowRestockModal((prev) => !prev);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "images",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const manufacturer = tableMeta.rowData[3];
          return `${manufacturer?.first_name} ${manufacturer?.last_name}`;
        },
      },
    },
    {
      name: "variations",
      label: "Variations",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value.length;
        },
      },
    },
    {
      name: "images",
      label: "Product",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <img
              style={{ maxHeight: "64px", maxWidth: "64px" }}
              src={`${process.env.REACT_APP_API_URL}uploads/products/${value[0].fileName}`}
              alt="Product"
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowId = tableMeta.rowData;
          const manufactuer = rowId[3];
          let item = {
            id: rowId[0],
            images: rowId[1],
            name: rowId[2],
            variations: rowId[4],
            manufacturer: `${manufactuer.first_name} ${manufactuer.last_name}`,
          };
          return (
            <div>
              <Button
                variant="contained"
                color="default"
                onClick={() => toggleRestockModalView(item)}
              >
                Restock
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: "none",
    responsive: "simple",
    downloadOptions: { filename: "ProductsList.csv", separator: "," },
  };

  return (
    <>
      <div className="header bg-gradient-default pb-8 pt-5 pt-md-6"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        Table
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Products</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="align-items-center"
                      color="success"
                      onClick={toggle}
                    >
                      Add Product
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <MUIDataTable data={data} columns={columns} options={options} />
            </Card>
          </div>
        </Row>
      </Container>
      {item !== null && (
        <RestockRequestModal
          open={showRestockModal}
          toggleOpen={() => toggleRestockModalView()}
          product={item}
        />
      )}
      <Modal isOpen={showAddModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Product</ModalHeader>
        <ModalBody>
          {/* <AddProduct /> */}
          <AddNewProduct />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      {/* <Modal isOpen={showEditModal} toggle={() => toggleEdit({})}>
        <ModalHeader toggle={() => toggleEdit({})}>Edit Product</ModalHeader>
        <ModalBody>
          <EditProduct item={item} />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Modal isOpen={showViewModal} toggle={() => toggleView({})}>
        <ModalHeader toggle={() => toggleView({})}>View Product</ModalHeader>
        <ModalBody>
          <ViewProduct item={item} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleView({})}>
            Close
          </Button>
        </ModalFooter>
      </Modal> */}
    </>
  );
};

export default Product;
