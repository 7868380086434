import { Box } from "@mui/material";
import Header from "components/Headers/Header";
import SingleVariationRestockModal from "components/Product/Stock/SingleVariationRestockModal";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { Button, Card, CardBody, Container, Row, Spinner } from "reactstrap";
import { useResellerStock } from "services/stockService";

const CurrentStockListing = () => {
  const { data: stockData, isLoading } = useResellerStock();
  const [variationId, setVariationId] = useState(null);
  const [isRestockModalOpen, setIsRestockModalOpen] = useState(false);
  const [maximumQuantity, setMaximumQuantity] = useState(null);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "product_variation_id",
      options: {
        filter: false,
        display: "excluded",
      },
    },
    {
      name: "name",
      label: "Product Name",
    },
    {
      name: "variation",
      label: "Variation",
    },
    {
      name: "manufacturerStock",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
    },
    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value) => {
          return Number(value).toFixed(2);
        },
      },
    },
    {
      name: "rrp",
      label: "RRP",
      options: {
        customBodyRender: (value) => {
          return Number(value).toFixed(2);
        },
      },
    },
    {
      name: "images",
      label: "Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const image = value[0].fileName;
          return (
            <img
              style={{ maxHeight: "64px", maxWidth: "64px" }}
              src={`${process.env.REACT_APP_API_URL}uploads/products/${image}`}
              alt="Product"
            />
          );
        },
      },
    },
    {
      name: "quantity",
      label: "Current Stock",
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value, { rowData }) => {
          const variationId = rowData[1];
          const maxManufacturerStock = rowData[4];

          return (
            <div>
              <Button
                variant="contained"
                color="default"
                onClick={() =>
                  toggleShowModal(variationId, maxManufacturerStock)
                }
              >
                Restock
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const toggleShowModal = (variationId = null, maximumQuantity = null) => {
    setIsRestockModalOpen((prev) => !prev);
    setVariationId(variationId);
    setMaximumQuantity(maximumQuantity);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                  >
                    <Spinner />
                  </Box>
                ) : (
                  <MUIDataTable
                    columns={columns}
                    data={stockData}
                    options={{
                      selectableRows: "none",
                      search: "true",
                    }}
                  />
                )}
              </CardBody>
            </Card>
            {maximumQuantity !== null && variationId !== null && (
              <SingleVariationRestockModal
                variationId={variationId}
                maximumQuantity={maximumQuantity}
                isOpen={isRestockModalOpen}
                toggleClose={() => toggleShowModal()}
              />
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};
export default CurrentStockListing;
