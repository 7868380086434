import { useQueryClient, useQuery } from "@tanstack/react-query";
import locationServices from "services/locationServices";

// fetch all counties
export const useCountiesQuery = () => {
  return useQuery({
    queryKey: ["counties"],
    queryFn: locationServices.fetchAllCountyLocations,
    refetchInterval: 3000,
  });
};

// fetch all subcounties
export const useSubCountiesQuery = () => {
  return useQuery({
    queryKey: ["sub-counties"],
    queryFn: locationServices.fetchAllSubCountyLocations,
  });
};

// fetch all subcounties of one county
export const useCountySubCountiesQuery = (id, enabled = true) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["county-subcounties", id],
    queryFn: ({ queryKey }) => {
      return locationServices.fetchAllCountySubCounties(queryKey[1]);
    },
    enabled,
    initialData: () => {
      const data = queryClient.getQueryData(["sub-counties"]);
      return data
        ? data.filter((subCounty) => String(subCounty.county_id) === String(id))
        : undefined;
    },
    refetchInterval: 3000,
  });
};
