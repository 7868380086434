import { Text, View } from "@react-pdf/renderer";
import { styles } from "../InvoiceReport";

const ProductDetails = ({ invoice }) => {
  let orderDetails;

  if (invoice.invoicedFor === "RESTOCK_PAYMENT") {
    orderDetails = (
      <View style={styles.tableRow}>
        <View style={styles.tableCel}>
          <Text>{invoice.productName}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{invoice.productVariation}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{invoice.productQuantity}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{Number(invoice.productPrice).toFixed(2)}</Text>
        </View>
      </View>
    );
  }
  if (invoice.invoicedFor === "PURCHASE_PAYMENT") {
    orderDetails = invoice.products.map((product) => (
      <View style={styles.tableRow}>
        <View style={styles.tableCel}>
          <Text>{product.productName}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{product.productVariation}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{product.productQuantity}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{Number(product.productPrice).toFixed(2)}</Text>
        </View>
      </View>
    ));
  }
  return (
    <View style={styles.body}>
      <Text style={{ ...styles.themeText, fontWeight: "extrabold" }}>
        Ordered Product(s)
      </Text>
      <View style={styles.divider}></View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableCel, ...styles.themeText }}>
            <Text>Product Name</Text>
          </View>
          <View style={{ ...styles.tableCel, ...styles.themeText }}>
            <Text>Variation</Text>
          </View>
          <View style={{ ...styles.tableCel, ...styles.themeText }}>
            <Text>Quantity</Text>
          </View>
          <View style={{ ...styles.tableCel, ...styles.themeText }}>
            <Text>Price</Text>
          </View>
        </View>
        {orderDetails}
      </View>
    </View>
  );
};
export default ProductDetails;
