import { Box, Container, Grid, Typography } from "@mui/material";
import SingleProductExcerptCard from "components/Product/Product/SingleProductExcerptCard";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useProduct } from "services/productService";

const ProductListing = () => {
  const { data: products = [], error, isError, isLoading } = useProduct();
  const history = useHistory();

  const productsLists = products.map((product) => (
    <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
      <Box
        component="a"
        href={`/product/${product.id}`}
        onClick={(e) => {
          e.preventDefault();
          history.push(`/product/${product.id}`);
        }}
      >
        <SingleProductExcerptCard product={product} />
      </Box>
    </Grid>
  ));

  const DisplayProductsFetchingMessage = (
    <Box
      sx={{
        minHeight: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isError ? (
        <Typography>
          {`Error occured while trying to fetch products. Responded with message: ${error}`}
        </Typography>
      ) : (
        <Typography>
          No Products available at the moment, try again later.
        </Typography>
      )}
    </Box>
  );

  return (
    <Container style={{ marginTop: 10 }}>
      <Grid container columns={12} spacing={3}>
        {!isLoading && products.lengths === 0 ? (
          <Grid item xs={12}>
            <DisplayProductsFetchingMessage />
          </Grid>
        ) : isLoading ? (
          <Grid item xs={12}>
            <LoadingOverlay />
          </Grid>
        ) : (
          productsLists
        )}
      </Grid>
    </Container>
  );
};
export default ProductListing;
