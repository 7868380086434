import { Button, Card, Alert, FormText, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { useDropzone } from "react-dropzone";
import React, { useState, useEffect } from "react";
import productService from "services/productService";

import UserHeader from "components/Headers/UserHeader.js";
import { MultipleImageUpload, SingleImageUpload } from "../../../components/admin/products/FileUpload";

const EditProduct = ({ item }) => {
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedImages, setSelectedImages] = useState([]);
	const [name, setName] = useState(item?.name);
	const [price, setPrice] = useState(item?.price);
	const [rrp, setRrp] = useState(item?.rrp);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleImagesSelect = (images) => {
		setSelectedImages(images);
	};

	const handleImageSelect = (image) => {
		setSelectedImage(image);
	};

	const resetForm = () => {
		setSelectedImage(null);
		setSelectedImages([]);
		setName("");
		setPrice("");
		setRrp("");
	};

	const handleSave = async (event) => {
		setSuccess(false);
		setError(false);
		event.preventDefault();

		const formData = new FormData();
		formData.append("name", name);
		formData.append("price", price);
		formData.append("rrp", rrp);
		if (selectedImage) formData.append("cover", selectedImage);
		for (let file of selectedImages) {
			formData.append("display", file);
		}

		productService
			.updateProduct(item.id, formData) // Call updateProduct instead of addProduct to update the existing product
			.then(() => {
				setSuccess(true);
			})
			.catch((error) => {
				setError(true);
			});
	};

	return (
		<>
			<Row>
				<Col className="order-xl-1" xl="12">
					{error && <Alert color="danger">{"Error while saving, try again later!"}</Alert>}
					{success && <Alert color="success">{"Successfully saved!!"}</Alert>}
					<Form role={"form"} onSubmit={handleSave}>
						<div className="pl-lg-4">
							<Row>
								<Col>
									<FormGroup>
										<label className="form-control-label" htmlFor="name">
											Product Name
										</label>
										<input
											className="form-control"
											id="name"
											placeholder="Name"
											type="text"
											required={true}
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col lg="6">
									<FormGroup>
										<label className="form-control-label" htmlFor="price">
											Price
										</label>
										<input
											className="form-control"
											id="price"
											placeholder="Price"
											type="number"
											min="1"
											required={true}
											value={price}
											onChange={(e) => setPrice(e.target.value)}
										/>
									</FormGroup>
								</Col>
								<Col lg="6">
									<FormGroup>
										<label className="form-control-label" htmlFor="rrp">
											Recommended Retail Price
										</label>
										<input
											className="form-control"
											id="rrp"
											placeholder="Recommended Retail Price"
											type="number"
											min="1"
											required={true}
											value={rrp}
											onChange={(e) => setRrp(e.target.value)}
										/>
									</FormGroup>
								</Col>
							</Row>
						</div>
						<hr className="my-4" />
						<div className="pl-lg-4">
							<Row>
								<Col md="12">
									<FormGroup>
										<label className="form-control-label" htmlFor="input-address">
											Cover Image
										</label>
										<SingleImageUpload name="single-image" onImageSelect={handleImageSelect} />
										{selectedImage && selectedImage instanceof Blob && (
											<div>
												<h2>Selected Image:</h2>
												<img
													src={URL.createObjectURL(selectedImage)}
													alt="Selected"
													style={{ maxHeight: "200px", maxWidth: "200px" }}
												/>
											</div>
										)}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md="12">
									<FormGroup>
										<label className="form-control-label" htmlFor="display">
											Display Images
										</label>
										<MultipleImageUpload name="multi-image" onImageSelect={handleImagesSelect} />
										{selectedImages.map(
											(image) =>
												image &&
												image instanceof Blob && (
													<img
														key={image.name}
														src={URL.createObjectURL(image)}
														alt={image.name}
														style={{ maxHeight: "128px", maxWidth: "128px" }}
													/>
												)
										)}
									</FormGroup>
								</Col>
							</Row>
						</div>

						<Button type="submit" color="success">
							Save
						</Button>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default EditProduct;
