import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRequestStock } from "services/stockService";
import Swal from "sweetalert2";

const SingleVariationRestockModal = ({
  variationId,
  maximumQuantity,
  isOpen,
  toggleClose,
}) => {
  const { mutateAsync, isLoading } = useRequestStock();
  const schema = yup
    .object({
      productId: yup.string().required(),
      quantity: yup.number().max(maximumQuantity).required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      productId: variationId,
      quantity: maximumQuantity,
    },
  });

  const handleRequestStock = (data) => {
    mutateAsync(data)
      .then((res) => {
        Swal.fire({
          title: "Request Submitted!",
          text: "Restock request was successfully submitted.",
          icon: "success",
        });
      })
      .catch((error) => {
        // display error message
        const message = error.response.data.message;
        Swal.fire({
          title: "Request Failed!",
          text:
            `Restock request was not successfully process. ` +
            `Responsed with message: ${message}`,
          icon: "error",
        });
      });
  };
  return (
    <Dialog open={isOpen} onClose={toggleClose}>
      <Box
        py={5}
        px={2}
        component="form"
        onSubmit={handleSubmit(handleRequestStock)}
        display="flex"
        flexDirection="column"
        gap={1}
        width={400}
        maxWidth={450}
      >
        <Typography textTransform="uppercase">
          Enter Quantity You Want
        </Typography>
        <Typography variant="caption" color={colors.grey[600]}>
          Default value is the max quantity available
        </Typography>
        <TextField
          {...register("quantity")}
          type="number"
          error={errors.quantity && true}
          helperText={errors.quantity?.message}
          label="Quantity"
        />
        <Box textAlign="right">
          <Button
            disabled={isLoading}
            variant="contained"
            color="success"
            type="submit"
            size="large"
            sx={{ px: 10 }}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default SingleVariationRestockModal;
