import { Typography } from "@mui/material";
import InnerLayout from "./InnerLayout";

const Index = () => {
  return (
    <>
      <InnerLayout>
        <Typography>To Redesign Finance Index Page</Typography>
      </InnerLayout>
    </>
  );
};
export default Index;
