import {
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import locationServices from "services/locationServices";
import MessageAlert from "components/Shared/MessageAlert";
import ErrorMessages from "components/Shared/ErrorMessages";
import { useCountySubCountiesQuery } from "hooks/useLocationData";

const schema = yup
  .object({
    name: yup.string().required().label("Sub County Name"),
  })
  .required();

const SubCountyForm = ({ countyId }) => {
  const [county, setCounty] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { refetch } = useCountySubCountiesQuery(countyId, false);

  if (county !== countyId) {
    setCounty(countyId);
    setValue("county_id", countyId);
    trigger();
  }

  const handleAddCounty = async (data) => {
    setError(null);
    setSuccessMessage(null);
    locationServices
      .createSubCounty(data)
      .then((data) => {
        setSuccessMessage(`Created ${data.name} county successfully`);
        refetch();
      })
      .catch((error) => setError(error));
    reset();
  };

  return (
    <>
      <ErrorMessages error={error} />
      <MessageAlert type="success" message={successMessage} />
      <Form role="form" onSubmit={handleSubmit(handleAddCounty)}>
        <p>
          <small color="text-danger">{errors.name?.message}</small>
        </p>
        <p>
          <small color="text-danger">
            {errors.county_id ? "Invalid county" : ""}
          </small>
        </p>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-world"></i>
              </InputGroupText>
            </InputGroupAddon>
            <input {...register("name")} className="form-control" />
            <InputGroupAddon addonType="append">
              <Button color="default" type="submit">
                Add
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Form>
    </>
  );
};
export default SubCountyForm;
