// import axios from "./axiosInstance";
import axios from "./axiosInstance";
import { useQuery } from "@tanstack/react-query";

const homePageService = {
  getProductsCount: async () => {
    return await axios.get("/products/count").then((res) => res.data);
  },
  getOrdersCount: async () => {
    return await axios.get("/orders/count").then((res) => res.data);
  },
  getTotalIncome: async () => {
    return await axios.get("/orders/sales").then((res) => res.data);
  },
};

export const useProductCount = () =>
  useQuery({
    queryKey: ["productCount"],
    queryFn: () => homePageService.getProductsCount(),
  });

export const useOrderCount = () =>
  useQuery({
    queryKey: ["orderCount"],
    queryFn: () => homePageService.getOrdersCount(),
  });

export const useTotalIncome = () =>
  useQuery({
    queryKey: ["totalIncome"],
    queryFn: () => homePageService.getTotalIncome(),
  });

export default homePageService;
