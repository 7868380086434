import Index from "views/Index.js";
import Profile from "views/auth/Profile.js";
import Products from "views/reseller/Products";
import Stock from "views/reseller/Stock";
import MyOrders from "views/reseller/MyOrders";
import NewOrders from "views/reseller/NewOrders";
import MySalesReview from "views/reseller/MySalesReview";
import RestockOrders from "views/reseller/RestockOrders";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/reseller",
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-briefcase-24 text-green",
    component: Products,
    layout: "/reseller",
  },
  {
    path: "/my-stock",
    name: "My Stock",
    icon: "ni ni-books text-red",
    component: Stock,
    layout: "/reseller",
  },
  {
    path: "/order",
    name: "New Orders",
    icon: "ni ni-basket text-purple",
    component: NewOrders,
    layout: "/reseller",
  },
  {
    path: "/my-orders",
    name: "Claimed Orders",
    icon: "ni ni-cart text-black",
    component: MyOrders,
    layout: "/reseller",
  },
  {
    path: "/stock-orders",
    name: "Stock Orders",
    icon: "ni ni-cart text-black",
    component: RestockOrders,
    layout: "/reseller",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/reseller",
  },
  {
    path: "/sales-reviews",
    name: "Order Reviews",
    icon: "ni ni-single-02 text-green",
    component: MySalesReview,
    layout: "/reseller",
  },
];
export default routes;
