import { Image, Text, View } from "@react-pdf/renderer";
import { styles } from "../InvoiceReport";
import PageSeparator from "./PageSeparator";

const InvoiceHeader = ({ invoicedFor }) => {
  return (
    <>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 0.8 }}>
          <View style={styles.header}>
            <View>
              <Image
                src="/lima.png"
                style={{ width: "100px", height: "auto" }}
              />
            </View>
            <Text>INVOICE FOR {String(invoicedFor).replace("_", " ")}</Text>
          </View>
        </View>
        <View style={{ flex: 0.2 }}>
          <View style={{ flexDirection: "column", gap: 2 }}>
            <Image
              src="/mpesa.png"
              style={{ width: "100px", height: "auto" }}
            />
            <View style={{ flexDirection: "row", gap: 1 }}>
              <Text style={styles.themeText}>Paybill: </Text>
              <Text>700200</Text>
            </View>
          </View>
        </View>
      </View>
      <PageSeparator />
    </>
  );
};
export default InvoiceHeader;
