import { Spinner } from "reactstrap";
import { useFetchOrderPaymentDetails } from "services/stockService";
import Swal from "sweetalert2";
import InitiatePayment from "components/payment/InitiatePayment";

const RestockRequestPayment = ({
  restockRequestId,
  toggleShowModal,
  refetch,
}) => {
  const {
    data: paymentDetails,
    isError,
    error,
    isLoading: isLoadingPayment,
  } = useFetchOrderPaymentDetails(restockRequestId);

  if (isError) {
    Swal.fire({
      title: "Error occured!",
      text: `Unable to fetch payment details. Error message: ${error}`,
      icon: "error",
    });
    toggleShowModal();
  }

  if (isLoadingPayment) return <Spinner />;
  return (
    <InitiatePayment
      callback={() => {
        refetch();
        toggleShowModal();
      }}
      paymentDetails={paymentDetails}
    />
  );
};
export default RestockRequestPayment;
