import { Typography } from "@mui/material";
import InitiatePayment from "components/payment/InitiatePayment";
import { Spinner } from "reactstrap";
import { usePurchaseOrderPaymentDetails } from "services/orderService";
import CodeCompletion from "./CodeCompletion";

const CompleteOrder = ({ orderId, callback }) => {
  console.log("Complete Order", orderId);
  const {
    data: paymentDetails,
    isLoading: paymentDetailsLoading,
    isError: detailsHasError,
  } = usePurchaseOrderPaymentDetails(orderId);

  if (paymentDetailsLoading) return <Spinner />;

  if (detailsHasError) {
    return <Typography>Order payment details retrival failed</Typography>;
  }

  return paymentDetails?.balance > 0 ? (
    <InitiatePayment
      paymentDetails={paymentDetails}
      callback={() => console.log("callback")}
    />
  ) : (
    <CodeCompletion orderId={orderId} callback={callback} />
  );
};
export default CompleteOrder;
