import ShoppingCheckout from "views/farmer/ShoppingCheckout";
import Checkout from "views/farmer/Checkout";
import Orders from "views/farmer/Orders";
import OrderItem from "views/farmer/OrderItem";
import ProductListing from "views/farmer/ProductListing";
import SingleProductDetails from "views/farmer/SingleProductDetails";

var routes = [
  // farmer url
  // {
  //   path: "/",
  //   name: "Home",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Home,
  //   layout: "/farmer",
  // },
  {
    path: "/",
    name: "Home",
    icon: "ni ni-circle-08 text-pink",
    component: ProductListing,
    layout: "/farmer",
  },
  {
    path: "/product/:productId",
    name: "Product Details",
    icon: "ni ni-circle-08 text-pink",
    component: SingleProductDetails,
    layout: "/farmer",
  },
  {
    path: "/shopping-cart",
    name: "Shopping Cart",
    icon: "ni ni-circle-08 text-info",
    component: ShoppingCheckout,
    layout: "/farmer",
  },
  {
    path: "/checkout",
    name: "Checkout",
    icon: "ni ni-circle-08 text-info",
    component: Checkout,
    layout: "/farmer",
  },
  {
    path: "/orders/item/:orderItemId",
    name: "Order Detail",
    icon: "ni ni-circle-08 text-primary",
    component: OrderItem,
    layout: "/farmer",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-circle-08 text-info",
    component: Orders,
    layout: "/farmer",
  },
];
export default routes;
