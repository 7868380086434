const { default: Header } = require("components/Headers/Header");
const { Container, Row, Card, CardBody } = require("reactstrap");

const InnerLayout = ({ children }) => {
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card>
              <CardBody>{children}</CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default InnerLayout;
