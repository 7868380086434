import { Box, Rating, Typography, colors } from "@mui/material";
import { StarBorderOutlined, StarRate } from "@mui/icons-material";

const ReviewsCard = ({ review }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="row" gap={1}>
        <Typography color="primary">Title</Typography>
        <Typography color={colors.grey[700]}>{review.title}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <Typography color="primary">Description:</Typography>
        <Typography color={colors.grey[700]}>{review.description}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <Rating
          readOnly
          value={review.rating}
          emptyIcon={<StarBorderOutlined color="success" />}
          icon={<StarRate color="success" />}
        />
      </Box>
    </Box>
  );
};
export default ReviewsCard;
