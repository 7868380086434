import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useProductById } from "services/productService";

import Carousel from "components/Carousel/Carousel";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import ProductReviews from "components/Reviews/ProductReviews";
import ProductPriceExcerpt from "components/Farmer/ProductPriceExcerpt";
import AddToCartVariations from "components/Product/Product/AddToCartVariations";

const CustomFlexColumnTypography = styled(Box)({
  display: "flex",
  gap: 3,
});

const SingleProductDetails = () => {
  const { productId } = useParams();
  const { data: product, isLoading } = useProductById(productId);

  if (isLoading) return <LoadingOverlay />;

  return (
    <>
      <CssBaseline />
      <Container style={{ marginTop: 10 }}>
        <Paper
          sx={{
            boxShadow: 4,
            pb: 5,
          }}
        >
          <Carousel
            items={product.images.map((image) => ({
              name: product.name,
              image: image.fileName,
            }))}
          />

          <Box mt={5} px={5} display="flex" flexDirection="column" gap={2}>
            <Typography
              variant="h6"
              textTransform={"uppercase"}
              color="primary"
              fontWeight="bold"
            >
              Product Description
            </Typography>
            <Divider />
            <Typography color="primary" fontWeight="bold">
              {product.name}
            </Typography>
            <Typography>{product.description}</Typography>
            <ProductPriceExcerpt variations={product.variations} />
            <CustomFlexColumnTypography>
              <Typography>Manufacturer:</Typography>
              <Typography>
                {product.manufacturer.first_name}{" "}
                {product.manufacturer.last_name}
              </Typography>
            </CustomFlexColumnTypography>
            {/* Add to cart */}
            <AddToCartVariations
              name={product.name}
              variations={product.variations}
            />
            <Divider />
            <ProductReviews productId={product.id} />
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default SingleProductDetails;
