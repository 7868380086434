import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "reactstrap";

const schema = yup
  .object({
    variation: yup.string().required().label("Variation option"),
    price: yup.number().min(1).required(),
    rrp: yup.number().min(1).moreThan(yup.ref("price")).required().label("RRP"),
    qty: yup.number().min(1),
  })
  .required();

const ProductVariationModal = ({
  updateVariations,
  isOpen,
  handleClose,
  defaultVariation,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultVariation,
  });

  const handleInsertVariation = (variation) => {
    const updatedVariation = {
      ...variation,
      index: defaultVariation.index,
    };

    updateVariations(updatedVariation);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        reset();
        handleClose();
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleInsertVariation)}
        sx={{
          py: 2,
          px: 3,
        }}
      >
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel error={errors.variation && true}>Variation</InputLabel>
          <OutlinedInput
            {...register("variation")}
            error={errors.variation && true}
            placeholder="size, weight...eg 100ml"
            multiline
            rows={2}
            label="Variation"
          />
          <FormHelperText error={errors.variation && true}>
            {errors.variation?.message}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel error={errors.price && true}>Price</InputLabel>
          <OutlinedInput
            {...register("price")}
            error={errors.price && true}
            label="Price"
            type="number"
            sx={{ maxWidth: 200 }}
          />
          <FormHelperText error={errors.price && true}>
            {errors.price?.message}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel error={errors.rrp && true}>RRP</InputLabel>
          <OutlinedInput
            {...register("rrp")}
            error={errors.rrp && true}
            label="RRP"
            type="number"
            sx={{ maxWidth: 200 }}
          />
          <FormHelperText error={errors.rrp && true}>
            {errors.rrp?.message}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 2 }}>
          <InputLabel error={errors.qty && true}>Quantity</InputLabel>
          <OutlinedInput
            {...register("qty")}
            error={errors.qty && true}
            label="Quantity"
            type="number"
            sx={{ maxWidth: 200 }}
          />
          <FormHelperText error={errors.qty && true}>
            {errors.qty?.message}
          </FormHelperText>
        </FormControl>
        <Button type="submit">Confirm</Button>
      </Box>
    </Dialog>
  );
};
export default ProductVariationModal;
