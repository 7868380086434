import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Fade,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Snackbar,
} from "@mui/material";
import { addedCartItem } from "views/farmer/shoppingCartSlice";
import { getPrimaryVariation } from "./SingleProductExcerptCard";

const AddToCartVariations = ({ name, variations }) => {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const primaryVariation = getPrimaryVariation(variations);

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const toggleSnackbar = (productName) => {
    setMessage(`Added ${productName} to Cart`);
    setState({
      ...state,
      open: true,
    });
  };

  const [state, setState] = useState({
    open: false,
    Transition: Fade,
    vertical: "bottom",
    horizontal: "center",
  });

  const handleAddToCart = ({ id, name, rrp }) => {
    // add product to cart
    // id, price, name -> required by cart reducer
    const data = {
      id,
      name,
      price: rrp,
    };

    dispatch(addedCartItem(data));

    toggleSnackbar(name);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClosePopOver = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // if there is just single variation add to cart should be the only option
  return (
    <>
      {variations.length > 1 ? (
        <Box>
          <ButtonGroup variant="contained" ref={anchorRef}>
            <Button
              onClick={() => {
                const variation = variations[selectedIndex];
                const { id, variation: variationName, rrp } = variation;
                handleAddToCart({
                  id,
                  name: `${name} ${variationName}`,
                  rrp,
                });
              }}
            >
              Add to Cart {variations[selectedIndex].variation}
            </Button>
            <Button size="small" onClick={handleToggle}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClosePopOver}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {variations.map((variation, index) => (
                        <MenuItem
                          key={variation.id}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {`${variation.variation}@${variation.rrp}`}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      ) : (
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              handleAddToCart({
                id: variations[selectedIndex].id,
                name: `${name} ${variations[selectedIndex].variation}`,
                rrp: variations[selectedIndex].rrp,
              })
            }
          >
            Add to Cart
          </Button>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: state.vertical,
          horizontal: state.horizontal,
        }}
        autoHideDuration={2000}
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        message={message}
        key={state.Transition.name}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          variant="filled"
          color="primary"
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default AddToCartVariations;
