const { createSlice } = require("@reduxjs/toolkit");

const initialState = [];

const shoppingCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // add new item to cart or increment by 1
    addedCartItem: (state, action) => {
      const { id, price, name } = action.payload;

      const item = state.find((cartItem) => String(cartItem.id) === String(id));
      item
        ? (item.quantity += 1)
        : state.push({ id, name, quantity: 1, price });
    },
    // decrement cart item by 1
    decrementedOneItem: {
      reducer: (state, action) => {
        const id = action.payload;
        const filtedItem = state.find((item) => String(item.id) === id);
        if (filtedItem) {
          filtedItem.quantity > 1 && (filtedItem.quantity -= 1);
        }
      },
    },
    // remove item from cat
    removedItem: {
      reducer: (state, action) => {
        const id = action.payload;
        return state.filter((item) => String(item.id) !== id);
      },
    },
    // clear cart
    clearedItems: (state) => {
      return (state = []);
    },
  },
});

export const getAllCartItems = (state) => state.cart;
export const { addedCartItem, removedItem, decrementedOneItem, clearedItems } =
  shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
