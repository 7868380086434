import {
  Box,
  Button,
  Dialog,
  Rating,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { useMakeReview } from "services/orderService";
import Swal from "sweetalert2";
import { StarBorderOutlined, StarRate } from "@mui/icons-material";

const schema = yup
  .object({
    order_item_id: yup.string().required(),
    title: yup.string().required().label("Title"),
    description: yup.string().required().label("Description"),
    rating: yup.string().required().label("Rating"),
  })
  .required();

const CreateReview = ({ isOpen, toggleDisplay, review, itemId }) => {
  const { mutateAsync } = useMakeReview(itemId);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: review.title || "",
      description: review.description || "",
      rating: review.rating || 0,
    },
  });

  useState(() => {
    setValue("order_item_id", itemId);
  }, [review]);

  const showSuccessAlert = () => {
    Swal.fire({
      title: "Reviewed",
      text: "Your review was submitted successfully...",
      icon: "success",
      confirmButtonColor: colors.green[900],
    });
  };

  const showErrorAlert = (message) => {
    Swal.fire({
      title: "Failed to Review",
      text: `Failed with the following error: ${message}`,
      icon: "error",
      showCancelButton: true,
      showCloseButton: false,
      cancelButtonColor: colors.red[600],
      cancelButtonText: "Ok",
      showDenyButton: false,
    });
  };

  const onSubmit = (data) => {
    data.rating = Number(data.rating) || 0;
    mutateAsync(data)
      .then(() => {
        showSuccessAlert();
        toggleDisplay();
      })
      .catch((error) => {
        showErrorAlert(error.message);
      });
  };
  return (
    <Dialog open={isOpen} onClose={toggleDisplay}>
      <Box bgcolor="background.paper">
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={3}
          padding={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography>Leave your review...</Typography>
          <TextField
            label="Title"
            variant="outlined"
            {...register("title")}
            error={errors.title && true}
            helperText={errors.title?.message}
          />
          <TextField
            label="Description"
            multiline={true}
            type="text"
            minRows={4}
            maxRows={6}
            variant="outlined"
            {...register("description")}
            error={errors.description && true}
            helperText={errors.description?.message}
          />

          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Rating
                emptyIcon={<StarBorderOutlined color="success" />}
                icon={<StarRate color="success" />}
                value={Number(value)}
                onChange={onChange}
              />
            )}
            name="rating"
          />
          {errors.rating?.message}
          <Button color="success" variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default CreateReview;
