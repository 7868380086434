import Index from "views/Index.js";
import Users from "views/admin/Users/Users.js";
import Product from "views/admin/product/Product";
import Order from "views/admin/order/Order";
import County from "views/admin/locations/County";
import Stock from "views/admin/stock/Stock";
import FilteredReviews from "views/admin/reviews/FilteredReviews";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-lue",
    component: Users,
    layout: "/admin",
  },

  {
    path: "/products",
    name: "Products",
    icon: "ni ni-box-2 text-yellow",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/order",
    name: "Orders",
    icon: "ni ni-briefcase-24 text-green",
    component: Order,
    layout: "/admin",
  },
  {
    path: "/stock",
    name: "Stock",
    icon: "ni ni-basket text-purple",
    component: Stock,
    layout: "/admin",
  },
  {
    path: "/county",
    name: "County",
    icon: "ni ni-pin-3 text-orange",
    component: County,
    layout: "/admin",
  },
  {
    path: "/reviews",
    name: "Reviews",
    icon: "ni ni-single-02 text-yellow",
    component: FilteredReviews,
    layout: "/admin",
  },
];
export default routes;
