import Rating from "@mui/material/Rating";
import { Box, Card, CardContent, Typography } from "@mui/material";
import StarOutlinedIcon from "@mui/icons-material/StarRateOutlined";
import StarFilledIcon from "@mui/icons-material/StarRate";
import { UseAverageProductReview } from "services/productService";

export const getPrimaryVariation = (variations) => {
  const variation =
    variations.find((variation) => variation.primary === true) || variations[0];

  return variation;
};

const SingleProductExcerptCard = ({ product }) => {
  const ROOT_URL = process.env.REACT_APP_API_URL;
  const primaryVariation = getPrimaryVariation(product.variations);
  const { data } = UseAverageProductReview(product.id);

  if (data) {
    return (
      <Card
        sx={{
          cursor: "pointer",
          boxShadow: 3,
          "&:hover": {
            boxShadow: 5,
          },
        }}
      >
        <Box height={200} overflow="hidden">
          <img
            src={`${ROOT_URL}uploads/products/${product.images[0].fileName}`}
            alt={product.name}
            width="100%"
            height="auto"
          />
        </Box>
        <CardContent
          sx={{
            pt: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              sx={{ height: 30, overflow: "hidden" }}
              fontFamily="Geologica, cursive"
              color="primary"
              fontWeight="bold"
            >
              {product.name}
            </Typography>
            <Typography
              sx={{ height: 30, overflow: "hidden" }}
              fontFamily="Geologica, cursive"
              component="p"
            >
              {primaryVariation.variation}
            </Typography>
            <Typography
              fontFamily="Geologica, cursive"
              fontWeight="bold"
              fontSize="1.3rem"
              color="primary"
            >
              Ksh. {primaryVariation.rrp}
            </Typography>

            <Rating
              readOnly
              emptyIcon={<StarOutlinedIcon color="primary" />}
              icon={<StarFilledIcon color="primary" />}
              value={data.averageReview}
            />
          </Box>
        </CardContent>
      </Card>
    );
  }
};
export default SingleProductExcerptCard;
