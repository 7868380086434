import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Paper,
  styled,
} from "@mui/material";
import InnerLayout from "./InnerLayout";
import { titleCase } from "title-case";
import { formatDistanceToNow, parseISO } from "date-fns";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import { useInvoices } from "services/financeServices";
import { Button, Spinner } from "reactstrap";
import { Download, MoreVert, ViewAgenda } from "@mui/icons-material";
import InvoiceReport from "components/Finance/InvoiceReport";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateRangeSelectionModal from "components/Finance/DateRangeSelectionModal";

const PurchaseInvoice = () => {
  const [fromFilter, setFromFilter] = useState(null);
  const [toFilter, setToFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [showRangeModal, setShowRangeModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [showInvoiceReport, setShowInvoiceReport] = useState(false);

  const {
    data: invoices,
    isLoading,
    refetch,
  } = useInvoices({
    from: fromFilter,
    to: toFilter,
    page,
  });

  // refetch immediatelly range values changes
  useEffect(() => {
    refetch();
  }, [fromFilter, toFilter, refetch]);

  const CustomModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: 3,
  });

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "invoice_number",
      label: "Invoice No",
      options: {
        filter: true,
      },
    },
    {
      name: "amount",
      label: "Amount Invoiced",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "balance",
      label: "Balance",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "created_at",
      label: "Invoiced On",
      options: {
        customBodyRender: (value) => {
          const parsedDate = formatDistanceToNow(parseISO(value));
          return titleCase(`${parsedDate} ago`);
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        download: false,
        print: false,
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setSelectedInvoiceId(value);
              }}
            >
              <MoreVert />
            </IconButton>
          );
        },
      },
    },
  ];

  const toggleShowInvoiceReport = () => {
    setShowInvoiceReport((prev) => !prev);
  };

  const handleDownloadInvoice = () => {};

  if (isLoading) return <Spinner />;

  return (
    <>
      <InnerLayout>
        <Box sx={{ mb: 3 }}>
          <Button
            size="small"
            color="success"
            onClick={() => setShowRangeModal(true)}
          >
            Filter By Date
          </Button>
        </Box>
        <MUIDataTable
          columns={columns}
          data={invoices.data}
          options={{
            selectableRows: "none",
            viewColumns: "false",
            filter: "false",
            serverSide: true,
            count: invoices.pagination.totalRecords,
            page: Number(invoices.pagination.currentPage) - 1,
            rowsPerPage: 10,
            rowsPerPageOptions: [10],
            onChangePage: (currentPage) => {
              setPage(currentPage + 1);
            },
          }}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <ListItemIcon>
              <ViewAgenda />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                setAnchorEl(null);
                toggleShowInvoiceReport(selectedInvoiceId);
              }}
            >
              View
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText onClick={handleDownloadInvoice}>
              Download PDF
            </ListItemText>
          </MenuItem>
        </Menu>

        {/* modal to show invoice pdf report */}
        {selectedInvoiceId !== null && showInvoiceReport && (
          <CustomModal
            open={showInvoiceReport}
            onClose={() => toggleShowInvoiceReport(null)}
            sx={{}}
          >
            <Box sx={{ width: "90%", height: "95%" }}>
              <Paper
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#525659",
                }}
              >
                <InvoiceReport invoiceId={selectedInvoiceId} />
              </Paper>
            </Box>
          </CustomModal>
        )}
        {/* modal for date range selection */}
        <DateRangeSelectionModal
          isOpen={showRangeModal}
          onClose={() => setShowRangeModal(false)}
          from={fromFilter}
          to={toFilter}
          setFrom={setFromFilter}
          setTo={setToFilter}
        />
      </InnerLayout>
    </>
  );
};
export default PurchaseInvoice;
