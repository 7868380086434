import React, { useMemo, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Spinner,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components

import { useOrder } from "services/orderService";
import { useClaimOrder } from "services/orderService";

import Header from "components/Headers/Header.js";
import Swal from "sweetalert2";
import { useSearch } from "providers/searchprovider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MessageAlert from "components/Shared/MessageAlert";
import NewOrderTableListing from "components/Product/Orders/NewOrderTableListing";

const Order = () => {
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <NewOrderTableListing />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Order;
