import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "reactstrap";
import ProductVariationModal from "./ProductVariationModal";
import { useState } from "react";

const defaultVariation = {
  index: null,
  variation: null,
  price: 1,
  rrp: 2,
  qty: 1,
};
const ProductVariationStepper = ({
  variations,
  setVariations,
  handleNextStep,
  handlePrevStep,
  handleSkipStep,
}) => {
  const [variationModalOpen, setVariationModalOpen] = useState(false);
  const [currentVariation, setCurrentVariation] = useState(defaultVariation);

  const toggleShowVariationModal = (variation = defaultVariation) => {
    setCurrentVariation(variation);
    setVariationModalOpen(!variationModalOpen);
  };

  const updateVariations = (variation) => {
    // check if current has index value -> only available for update
    variation.index !== null && variation.index !== undefined
      ? // update variation
        setVariations([
          ...variations.map((_variation, index) => {
            if (index === variation.index)
              return {
                variation: variation.variation,
                price: variation.price,
                rrp: variation.rrp,
                qty: variation.qty,
              };
            return _variation;
          }),
        ])
      : // insert new variation
        setVariations((prev) => [...prev, variation]);
  };

  const handleDeleteVariation = (deleteIndex) => {
    setVariations(variations.filter((_, index) => index !== deleteIndex));
  };

  return (
    <>
      <p>Product Variations</p>
      <Box>
        <Button onClick={toggleShowVariationModal}>Add Variation</Button>
      </Box>
      <Box>
        <List>
          {variations.map((variation, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <Box display="flex" flexDirection="row" gap={1}>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      toggleShowVariationModal({ ...variation, index });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteVariation(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText>{variation.variation}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <Button onClick={handlePrevStep}>Back</Button>
        <Button onClick={handleNextStep} disabled={variations.length === 0}>
          Next
        </Button>
        <Button onClick={handleSkipStep} disabled={variations.length > 0}>
          Skip
        </Button>
      </Box>
      {variationModalOpen && (
        <ProductVariationModal
          defaultVariation={currentVariation}
          isOpen={variationModalOpen}
          handleClose={toggleShowVariationModal}
          updateVariations={updateVariations}
        />
      )}
    </>
  );
};
export default ProductVariationStepper;
