import axios from "axios";
import Cookies from "js-cookie";

const authToken = Cookies.get("access_token");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: authToken !== null && `Bearer ${authToken}`,
  },
});

export default axiosInstance;
