import { Box, MenuItem, Select } from "@mui/material";
import Header from "components/Headers/Header";
import ResellerRestockRequestListing from "components/Product/Stock/ResellerRestockRequestListing";
import { useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";

const RestockOrders = () => {
  const [status, setStatus] = useState("pending");
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Box>
                  <Box mb={2}>
                    <Select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      size="small"
                      sx={{
                        minWidth: 200,
                      }}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="accepted">To Confirm</MenuItem>
                      <MenuItem value="to_pay">To Pay</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                      <MenuItem value="transit">To Be Delivered</MenuItem>
                      <MenuItem value="delivered">Delivered</MenuItem>
                    </Select>
                  </Box>
                  <Box>
                    <ResellerRestockRequestListing status={status} />
                  </Box>
                </Box>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default RestockOrders;
