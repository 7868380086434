import Index from "views/Index.js";
import AddProduct from "views/manufacturer/products/AddProduct";
import Restock from "views/manufacturer/stock/Restocking";
import Profile from "views/auth/Profile.js";
import ListProducts from "views/manufacturer/products/ListProducts";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/manufacturer",
  },
  {
    path: "/my-products",
    name: "All Products",
    icon: "ni ni-bullet-list-67 text-green",
    layout: "/manufacturer",
    component: ListProducts,
  },
  {
    path: "/products",
    name: "Add Products",
    icon: "ni ni-box-2 text-yellow",
    component: AddProduct,
    layout: "/manufacturer",
  },
  {
    path: "/pendingstock",
    name: "Restock Listing",
    icon: "ni ni-basket text-pink",
    component: Restock,
    layout: "/manufacturer",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/manufacturer",
  },
];
export default routes;
