import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, Dialog, TextField, Typography } from "@mui/material";

const RestockRequestQuantityForm = ({
  variation,
  restockItems = [],
  setRestockItems,
  isOpen,
  handleClose,
}) => {
  // moved here at allow props values to be used in validation
  const schema = yup
    .object({
      quantity: yup
        .number()
        .min(1)
        .max(variation.stockQuantity)
        .required()
        .label("Quantity"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: variation.stockQuantity,
    },
  });

  const checkProductInRestockList = (productId) => {
    return (
      restockItems.find((restockItem) => restockItem.productId === productId) &&
      true
    );
  };

  const handleAddVariationQuantity = (input) => {
    const productId = variation.id;

    // product variation selected in null index -> just for security measure
    if (productId === null) return;

    // check if product already in restock request list array
    if (checkProductInRestockList(productId)) {
      // product already in list update the quantity
      setRestockItems(
        restockItems.map((restockItem) =>
          restockItem.productId === productId
            ? { ...restockItem, quantity: input.quantity }
            : restockItem
        )
      );
    } else {
      // product not in list add for first time
      setRestockItems([
        ...restockItems,
        { productId, quantity: input.quantity },
      ]);
    }
    handleClose();
  };
  return (
    <Dialog open={isOpen} onClose={() => handleClose()}>
      <Box py={2} px={3} display="flex" flexDirection="column" gap={2}>
        <Typography>Variation</Typography>
        <Typography>{variation.name}</Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(handleAddVariationQuantity)}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <TextField
            label="Quantity"
            {...register("quantity")}
            error={errors.quantity && true}
            helperText={errors.quantity?.message}
            type="number"
          />
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default RestockRequestQuantityForm;
