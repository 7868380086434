import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "reactstrap";
import { useInitiatePayment } from "services/paymentService";
import Swal from "sweetalert2";

import * as yup from "yup";

const schema = yup.object({
  phone_number: yup
    .string()
    .matches(/^254\d{9}$/, "provide safaricom number starting with 254")
    .required(),
  amount: yup.number().min(1).required(),
  account_reference: yup.string().required(),
});

const InitiatePayment = ({ paymentDetails, callbacks }) => {
  console.log("Payment details", paymentDetails);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [editableAmount, setEditableAmount] = useState(true);
  const { mutateAsync, isLoading: processingPayment } = useInitiatePayment();

  useEffect(() => {
    if (paymentDetails) {
      setValue("amount", paymentDetails.balance);
      setValue("phone_number", paymentDetails.phoneNumber);
      setValue("account_reference", paymentDetails.invoiceNumber);
    }
  }, [paymentDetails, setValue]);

  const handleInitiateOrderPayment = (data) => {
    if (Number(data.amount) > Number(paymentDetails.balance)) {
      // paying more than order balance
      return Swal.fire({
        title: "Wrong value!",
        text: `You're trying to pay more than the order balance. Order balance is Ksh. ${Number(
          paymentDetails.balance
        ).toFixed(2)}`,
        icon: "error",
      });
    }
    mutateAsync(data)
      .then((res) => {
        // payment initiated
        Swal.fire({
          title: "Processed Successfully!",
          text: `${res.ResponseDescription}`,
          icon: "success",
        }).then((res) => {
          if (res.dismiss || res.isConfirmed) {
            // refetch list
            callbacks();
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Payment Failed!",
          text: `Sorry, we're unable to initate payment at the moment`,
          icon: "error",
        });
      });
  };
  return (
    <>
      <Grid container columns={12}>
        <Grid item sm={7}>
          <Box p={5}>
            {paymentDetails.qrCode && (
              <img
                src={`data:image/png;base64,${paymentDetails.qrCode}`}
                alt="QR Code"
                width="100%"
              />
            )}
          </Box>
          <Box mx={5}>
            {/* to display invoice data and amount due */}
            <Box>
              <Typography fontWeight="bold" variant="h6" color="#36b649">
                Paybill:
              </Typography>
              <Typography>{paymentDetails.paybill}</Typography>
            </Box>

            <Box>
              <Typography fontWeight="bold" variant="h6" color="#36b649">
                Account:
              </Typography>
              <Typography>{paymentDetails.invoiceNumber}</Typography>
            </Box>

            <Box>
              <Typography fontWeight="bold" variant="h6" color="#36b649">
                Balance:
              </Typography>
              <Typography>Ksh. {paymentDetails.balance}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5}>
          <Box
            component={"form"}
            onSubmit={handleSubmit(handleInitiateOrderPayment)}
            mx={2}
          >
            {/* to display input for amount */}
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box>
                <img width="100%" src="/mpesa.png" alt="M-Pesa" />
              </Box>
              <FormControl disabled={editableAmount}>
                <InputLabel error={errors.amount && true}>Amount</InputLabel>
                <OutlinedInput
                  type="number"
                  label="Amount"
                  defaultValue={paymentDetails?.balance}
                  {...register("amount")}
                  error={errors.amount && true}
                />
                <FormHelperText error={errors.amount && true}>
                  {errors.amount?.message}
                </FormHelperText>
              </FormControl>
              <FormControl disabled={editableAmount}>
                <InputLabel error={errors.phone_number && true}>
                  Phone Number
                </InputLabel>
                <OutlinedInput
                  defaultValue={paymentDetails?.phone_number}
                  label="Phone Number"
                  error={errors.phone_number && true}
                  {...register("phone_number")}
                />
                <FormHelperText error={errors.phone_number && true}>
                  {errors.phone_number?.message}
                </FormHelperText>
              </FormControl>
              <FormControlLabel
                label="Edit"
                control={
                  <Checkbox
                    checked={!editableAmount}
                    onChange={() => setEditableAmount(!editableAmount)}
                  />
                }
              />
              <Box display={"flex"} flexDirection={"column"}>
                <Button
                  color="success"
                  type="submit"
                  disabled={processingPayment}
                >
                  Pay
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default InitiatePayment;
