import { Edit, MoreVert } from "@mui/icons-material";
import {
  Box,
  Dialog,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import InnerLayout from "./InnerLayout";
import { useState } from "react";
import CreateEditPartner from "./CreateEditPartner";
import { Button } from "reactstrap";
import { formatDistanceToNow, parseISO } from "date-fns";
import { titleCase } from "title-case";
import { useGetAllPartners } from "services/financeServices";

const PartnerManager = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPartner, setCurrentPartner] = useState(null);
  const [showEditPartner, setShowEditPartner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: partners = [] } = useGetAllPartners();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setCurrentPartner(null);
    setShowEditPartner(false);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "rate",
      label: "Profit %",
    },
    {
      name: "created_at",
      label: "Added On",
      options: {
        customBodyRender: (value) => {
          const parsedDate = parseISO(new Date(value).toISOString());
          return titleCase(`${formatDistanceToNow(parsedDate)} ago`);
          // return parsedDate;
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setCurrentPartner(value);
              }}
            >
              <MoreVert />
            </IconButton>
          );
        },
      },
    },
  ];
  return (
    <>
      <InnerLayout>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Button color="success" onClick={() => setShowEditPartner(true)}>
              Add New
            </Button>
          </Box>
          <MUIDataTable
            columns={columns}
            data={partners}
            options={{
              selectableRows: "none",
              // serverSide: true,
              // page: currentPage - 1,
              // onChangePage: (currentPage) => {
              //   setCurrentPage(currentPage + 1);
              // },
            }}
          />
        </Box>
      </InnerLayout>

      <Menu
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            setShowEditPartner(true);
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog open={showEditPartner} onClose={handleCloseModal}>
        <Paper
          sx={{
            width: "500px",
          }}
        >
          <CreateEditPartner partnerId={currentPartner} />
        </Paper>
      </Dialog>
    </>
  );
};
export default PartnerManager;
