import { Table } from "reactstrap";
import { useCountiesQuery } from "hooks/useLocationData";
import CountyRow from "./CountyRow";
import { useMemo } from "react";
import { useSearch } from "providers/searchprovider";

const CountyTable = ({
  setSuccessMessage,
  setErrorMessage,
  toggle,
  setSelectedCounty,
}) => {
  const { data: counties, isLoading } = useCountiesQuery();

  const {search} = useSearch()

  // const searchCounty = useMemo(() => data.filter(item => item.name.toLowerCase().includes(search)), [data, search])

  const countiesDisplay = isLoading ? (
    <tr>
      <th className="text-center" colSpan={4}>
        Loading...
      </th>
    </tr>
  ) : (
    counties && counties.map((county) => (
      <CountyRow
        key={county.id}
        county={county}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
        setSelectedCounty={setSelectedCounty}
        toggle={toggle}
      />
    ))
  );

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">County</th>
          <th scope="col">Code</th>
          <th scope="col">No. Sub Counties</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>{countiesDisplay}</tbody>
    </Table>
  );
};
export default CountyTable;
