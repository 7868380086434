import {
  Button,
  Col,
  FormText,
  InputGroup,
  InputGroupAddon,
  ListGroupItem,
  Row,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { removedItem } from "views/farmer/shoppingCartSlice";
import { addedCartItem } from "views/farmer/shoppingCartSlice";
import { decrementedOneItem } from "views/farmer/shoppingCartSlice";

const ShoppingCartItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleRemoveProduct = () => {
    dispatch(removedItem(item.id));
  };

  const handleIncrementProduct = () => {
    dispatch(addedCartItem(item));
  };

  const handleDecrementProduct = () => {
    dispatch(decrementedOneItem(item.id));
  };

  return (
    <ListGroupItem key={item.id}>
      <Row>
        <Col xs="12" className="mb-3">
          <div>
            <p className="mb-1">{item.name}</p>
            <small className="text-muted">
              Ksh.{item.price} x {item.quantity}
            </small>
          </div>
        </Col>
        <Col xs="12">
          <Row>
            <Col sm="6" className="mb-3">
              <Button size="xs" color="default" onClick={handleRemoveProduct}>
                <i className="ni ni-fat-remove"></i>
                <span>Remove</span>
              </Button>
            </Col>
            <Col sm="6" className="mb-3">
              <InputGroup style={{ maxWidth: "100px" }}>
                <InputGroupAddon addonType="prepend">
                  <Button
                    size="sm"
                    color="default"
                    onClick={handleDecrementProduct}
                    disabled={item.quantity <= 1}
                  >
                    -
                  </Button>
                </InputGroupAddon>
                <FormText className="px-3">{item.quantity}</FormText>
                <InputGroupAddon addonType="append">
                  <Button
                    size="sm"
                    color="default"
                    onClick={handleIncrementProduct}
                  >
                    +
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <span className="font-weight-bold">Ksh.{item.price * item.quantity}</span>
    </ListGroupItem>
  );
};
export default ShoppingCartItem;
