import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

const displayProcessingMessage = (status) => {
  if (status === "Completed") {
    return { text: "Delivered", background: "success" };
  } else if (status === "Claimed") {
    return { text: "Confirmed", background: "warning" };
  } else {
    return { text: "Awaiting Confirmation", background: "default" };
  }
};
const OrderSummary = ({ data }) => {
  const history = useHistory();
  const { text: statusText, background } = displayProcessingMessage(
    data.order.status
  );

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Grid container columns={12}>
          <Grid item sm={12} md={5} lg={3}>
            {/* Image or product */}
            <Box maxWidth={250} margin="0 auto">
              <img
                src={`${process.env.REACT_APP_API_URL}uploads/products/${data.product_variation.product.images[0].fileName}`}
                alt={data.product_variation.product.name}
                height="auto"
                width="100%"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={7} lg={6}>
            {/* Order Details */}
            <Box display="flex" flexDirection="column" rowGap={1}>
              <Typography>{data.product_variation.product.name}</Typography>
              <Typography>Order: {data.order.id}</Typography>
              <Typography>Qty: {data.quantity}</Typography>
              <Box>
                <Chip color={background} label={statusText} />
              </Box>
              <Typography>
                Ordered On: {parseISO(data.order.created_at).toDateString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={7} lg={3}>
            {/* Show more details button */}
            <Button
              color="success"
              variant="outlined"
              sx={{
                border: "none",
                ":hover": {
                  border: "none",
                },
              }}
              onClick={() => history.push(`/orders/item/${data.id}`)}
            >
              Show More Details
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default OrderSummary;
