import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import axios from "./axiosInstance";

const userService = {
  newUserRegistration: async (data) => {
    return await axios.post("/users/new", data).then((res) => res.data);
  },

  authenticateUser: async (data) => {
    return await axios.post("/users/login", data).then(async (res) => {
      Cookies.set("access_token", res?.data?.access_token);
      return res.data;
    });
  },

  // edit user
  editUserDetails: async (data) => {
    let endpoint;

    if (data.patchAPI === "api1") {
      endpoint = `/users/add/superadmin?id=${data.id}`;
    } else if (data.patchAPI === "api2") {
      endpoint = `/users/add/admin?id=${data.id}`;
    } else if (data.patchAPI === "api3") {
      endpoint = `/users/add/manufacturer?id=${data.id}`;
    } else if (data.patchAPI === "api4") {
      endpoint = `/users/add/corp?id=${data.id}`;
    }

    try {
      return await axios.patch(endpoint, data.data).then((res) => res.data);
    } catch (error) {
      console.error(`Error updating user data using ${data.patchApi}:`, error);
    }
  },
};

export const EditUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => userService.editUserDetails(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["allUsers"]);
    },
  });
};

export const useAddUserlevel = () => {
  return useMutation({
    mutationFn: (data) => userService.addUserLevel(data),
  });
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: userService.authenticateUser,
    onSuccess: (data) => {
      queryClient.setQueriesData(["authenticated"], data.user);
    },
  });
};

export default userService;
