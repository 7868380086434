import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import ShoppingCheckout from "views/farmer/ShoppingCheckout";
import Checkout from "views/farmer/Checkout";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/shopping-cart",
    name: "Shopping Cart",
    icon: "ni ni-circle-08 text-info",
    component: ShoppingCheckout,
    layout: "/farmer",
  },
  {
    path: "/checkout",
    name: "Checkout",
    icon: "ni ni-circle-08 text-info",
    component: Checkout,
    layout: "/farmer",
  },
];
export default routes;
