import {
  Button,
  Form,
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import locationServices from "services/locationServices";
import MessageAlert from "components/Shared/MessageAlert";
import { useState } from "react";
import ErrorMessages from "components/Shared/ErrorMessages";

const schema = yup
  .object({
    name: yup.string().required().label("County Name"),
  })
  .required();

const CountyForm = () => {
  const [message, setMessage] = useState("");
  const [apiErrors, setApiErrors] = useState(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddCounty = (data) => {
    setApiErrors(null);
    setMessage("");
    locationServices
      .createCounty(data)
      .then((data) => {
        setMessage(`${data.name} created successfully`);
        reset();
      })
      .catch((error) => {
        setApiErrors(error);
      });
  };
  return (
    <>
      <ErrorMessages error={apiErrors} />
      <MessageAlert type="success" message={message} />
      <Form role="form" onSubmit={handleSubmit(handleAddCounty)}>
        <small className="text-danger">{errors.name?.message}</small>

        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-world"></i>
              </InputGroupText>
            </InputGroupAddon>
            <input
              {...register("name")}
              className="form-control"
              autoComplete="off"
            />
            <InputGroupAddon addonType="append">
              <Button type="submit" className="btn-default shadow-none">
                Create
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Form>
    </>
  );
};
export default CountyForm;
