import { Text, View } from "@react-pdf/renderer";
import { format, parseISO } from "date-fns";
import { styles } from "../InvoiceReport";

const InvoiceHeaderDetails = ({ invoice }) => {
  return (
    <>
      <View style={{ flexDirection: "row" }}>
        <View style={{ ...styles.itemGroup, flex: 1 }}>
          <Text style={styles.itemGroupKey}>Invoice No#:</Text>
          <Text>{invoice.invoiceNumber}</Text>
        </View>
        <View style={{ ...styles.itemGroup, flex: 1 }}>
          <Text style={styles.itemGroupKey}>Invoiced On:</Text>
          <Text>
            {format(parseISO(invoice.invoiceGeneratedDate), "dd/MMM/yyyy")}
          </Text>
        </View>
      </View>
    </>
  );
};
export default InvoiceHeaderDetails;
