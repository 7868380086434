import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography,
  colors,
} from "@mui/material";
import FarmerHeader from "components/Headers/FarmerHeader";
import CreateReview from "components/Reviews/CreateReview";
import ReviewsCard from "components/Reviews/ReviewsCard";
import { parseISO } from "date-fns";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { useBuyerOrderItemDetailed } from "services/orderService";

const processingBadgeColor = (status) => {
  if (status === "Completed") return "success";
  else if (status === "Claimed") return "warning";
  else return "default";
};
const BASE_URL = process.env.REACT_APP_API_URL;
const OrderItem = () => {
  const history = useHistory();
  const { orderItemId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [review, setReview] = useState({});

  const { data: orderItem, isLoading } = useBuyerOrderItemDetailed(orderItemId);

  if (isLoading) return <Typography>Loading...</Typography>;

  const toggleDisplay = (review = {}) => {
    setReview(review);
    setIsOpen(!isOpen);
  };

  const reviewSection = orderItem?.review ? (
    // display review if present
    <ReviewsCard review={orderItem.review} />
  ) : (
    // show button to toggle modal to create review if it doesn't exist
    <Button
      variant="outlined"
      color="success"
      sx={{ border: "none", ":hover": { border: "none" } }}
      onClick={() => toggleDisplay()}
    >
      ADD REVIEW
    </Button>
  );

  return (
    <>
      <FarmerHeader />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Paper
              sx={{
                px: 3,
                py: 2,
                display: "flex",
                flexDirection: "column",
                columnGap: 1,
                overflow: "auto",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                lineHeight={3}
              >
                <IconButton
                  edge="start"
                  onClick={() => history.push("/orders")}
                >
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight="bold" variant="h5">
                  Order Details
                </Typography>
              </Box>
              <hr style={{ width: "100%" }} color={colors.grey[600]} />
              <Box>
                <Typography>ITEMS IN YOUR ORDER</Typography>
                <Grid
                  container
                  mt={2}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    padding: 2,
                  }}
                >
                  <Grid container mb={2}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Chip
                          label={orderItem.order.status}
                          color={processingBadgeColor(orderItem.order.status)}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        sx={{ textAlign: { lg: "right", sm: "left" } }}
                      >
                        {parseISO(orderItem.order.created_at).toDateString()}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* product details */}
                  <Grid item sm={12} md={4} lg={3}>
                    <Box
                      sx={{
                        margin: "0 auto",
                        overflow: "auto",
                      }}
                    >
                      <img
                        src={`${BASE_URL}uploads/products/${orderItem.product_variation.product.images[0].fileName}`}
                        style={{ width: "auto", height: 150 }}
                        alt={orderItem.product_variation.product.name}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={4} sx={{ mt: { xs: 2 } }}>
                    <Box display="flex" flexDirection="column" rowGap={1}>
                      <Box display="flex" flexDirection="row" gap={1}>
                        <Typography color={colors.grey[600]}>
                          Product Name:
                        </Typography>
                        <Typography>
                          {orderItem.product_variation.product.name}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="row" gap={1}>
                        <Typography color={colors.grey[600]}>
                          (Size, Weight...)
                        </Typography>
                        <Typography>
                          {orderItem.product_variation.variation}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="row" gap={1}>
                        <Typography color={colors.grey[600]}>
                          Manufacturer:
                        </Typography>
                        <Typography>
                          {
                            orderItem.product_variation.product.manufacturer
                              .first_name
                          }{" "}
                          {
                            orderItem.product_variation.product.manufacturer
                              .last_name
                          }
                        </Typography>
                      </Box>
                      <Typography color={colors.grey[700]}>
                        Qty: {orderItem.quantity}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={4}
                    lg={5}
                    sx={{
                      textAlign: {
                        sm: "center",
                        lg: "right",
                      },
                      margin: {
                        xs: "0 auto",
                      },
                    }}
                  >
                    <Button
                      color="success"
                      variant="contained"
                      onClick={() =>
                        history.push(
                          `/product/${orderItem.product_variation.product.id}`
                        )
                      }
                      sx={{ px: 4 }}
                    >
                      BUY AGAIN
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {/* Review Container */}
              <Box mt={3}>
                <Box>
                  {orderItem.order.status === "Completed" && reviewSection}
                </Box>
              </Box>
            </Paper>
            {/* This is a dialog for creating review */}
            <CreateReview
              itemId={orderItem.id}
              isOpen={isOpen}
              toggleDisplay={toggleDisplay}
              review={review}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};
export default OrderItem;
