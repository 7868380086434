import { Cancel, MoreVert, Payment, ViewAgenda } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { formatDistanceToNow, parseISO } from "date-fns";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useResellerRestockRequests } from "services/stockService";
import RestockOrderDetails from "./RestockOrderDetails";
import { titleCase } from "title-case";
import RestockRequestPayment from "./RestockRequestPayment";

export const formatedRestockStatus = (status) => {
  if (status === "accepted") return "Fee Confirmation";
  else if (status === "rejected") return "Rejected";
  else if (status === "to_pay") return "Invoiced";
  else if (status === "transit") return "In Delivery";
  else if (status === "delivered") return "Delivered";
  else if (status === "expired") return "Expired";
  else return titleCase(status);
};

const ResellerRestockRequestListing = ({ status = "pending" }) => {
  const {
    data: restockRequests,
    isLoading,
    refetch,
  } = useResellerRestockRequests(status);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showRestockModal, setShowRestockModal] = useState(false);

  const [showPaymentFormModal, setShowPaymentFormModal] = useState(false);

  // const [show]

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedOrder(null);
  };

  const handleClosePaymentModal = () => {
    setSelectedOrder(null);
    setShowPaymentFormModal(false);
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "images",
      label: "Image",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "product_name",
      label: "Product Name",
    },
    {
      name: "product_variation",
      label: "Variation",
      options: {
        display: "excluded",
      },
    },
    {
      name: "quantity",
      label: "Quantity",
    },
    {
      name: "order_cost",
      label: "Order Total",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "delivery_fee",
      label: "Delivery Fee",
      options: {
        customBodyRender: (value) => (value ? Number(value).toFixed(2) : "--"),
      },
    },
    {
      name: "amount_due",
      label: "Amount Due",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "created_at",
      label: "Ordered On",
      options: {
        customBodyRender: (value) => {
          const parsedDate = parseISO(value);
          const formatedDate = `${formatDistanceToNow(parsedDate)} ago`;
          return titleCase(formatedDate);
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => formatedRestockStatus(value),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          const orderId = tableMeta.rowData[0];
          const order = restockRequests.find(
            (request) => request.id === orderId
          );
          return (
            <IconButton
              onClick={(e) => {
                setSelectedOrder(order);
                setAnchorEl(e.target);
              }}
            >
              <MoreVert />
            </IconButton>
          );
        },
      },
    },
  ];

  if (isLoading) return <LoadingOverlay />;
  return (
    <>
      <MUIDataTable
        columns={columns}
        data={restockRequests}
        options={{
          selectableRows: "none",
        }}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setShowRestockModal(true);
          }}
        >
          <ListItemIcon>
            <ViewAgenda />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>
        <MenuItem disabled={selectedOrder?.status !== "pending"}>
          <ListItemIcon>
            <Cancel />
          </ListItemIcon>
          <ListItemText>Cancel</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={selectedOrder?.status !== "to_pay"}
          onClick={() => {
            setAnchorEl(null);
            setShowPaymentFormModal(true);
          }}
        >
          <ListItemIcon>
            <Payment />
          </ListItemIcon>
          <ListItemText>Pay</ListItemText>
        </MenuItem>
      </Menu>
      {selectedOrder !== null && (
        <RestockOrderDetails
          orderDetails={selectedOrder}
          open={showRestockModal && selectedOrder !== null}
          onClose={() => {
            setSelectedOrder(null);
            setShowRestockModal(false);
            setAnchorEl(null);
          }}
          showPaymentFormModal={setShowPaymentFormModal}
        />
      )}

      {/* payment details modal */}
      {selectedOrder !== null && selectedOrder.status === "to_pay" && (
        <Dialog
          open={showPaymentFormModal}
          onClose={() => handleClosePaymentModal()}
        >
          <Paper
            sx={{
              py: 3,
              px: 2,
            }}
          >
            <RestockRequestPayment
              restockRequestId={selectedOrder.id}
              toggleShowModal={handleClosePaymentModal}
              refetch={refetch}
            />
          </Paper>
        </Dialog>
      )}
    </>
  );
};
export default ResellerRestockRequestListing;
