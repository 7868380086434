import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  Rating,
  Typography,
  colors,
  styled,
} from "@mui/material";

const ReviewDetailsModal = ({ open, onClose, review }) => {
  const CustomModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });
  return (
    <CustomModal open={open} onClose={onClose}>
      <Card
        sx={{
          minWidth: 400,
        }}
      >
        <CardHeader
          title="Review Details"
          sx={{
            textTransform: "capitalize",
            textAlign: "center",
            boxShadow: 2,
          }}
        />
        <CardContent
          sx={{
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography
              fontWeight="bold"
              variant="h6"
              component="p"
              color={colors.grey[600]}
            >
              Title:
            </Typography>
            <Typography>{review.title}</Typography>
            <Typography
              fontWeight="bold"
              variant="h6"
              component="p"
              color={colors.grey[600]}
            >
              Decription:
            </Typography>
            <Typography>{review.description}</Typography>
            <Typography
              fontWeight="bold"
              variant="h6"
              component="p"
              color={colors.grey[600]}
            >
              Rating:
            </Typography>
            <Typography>
              <Rating readOnly value={review.rating} />
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </CustomModal>
  );
};
export default ReviewDetailsModal;
