import { MultipleImageUpload } from "components/admin/products/FileUpload";

const ProductImagesUpload = ({
  selectedImages,
  setSelectedImages,
  alreadyExistingImages,
}) => {
  const handleImagesSelect = (images) => {
    setSelectedImages(images);
  };

  return (
    <>
      <label className="form-control-label" htmlFor="display">
        Display Images
      </label>
      <MultipleImageUpload
        name="multi-image"
        onImageSelect={handleImagesSelect}
      />

      {selectedImages.map(
        (image) =>
          image &&
          image instanceof Blob && (
            <img
              key={image.name}
              src={URL.createObjectURL(image)}
              alt={image.name}
              style={{ maxHeight: "128px", maxWidth: "128px" }}
            />
          )
      )}
    </>
  );
};
export default ProductImagesUpload;
