import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import locationServices from "services/locationServices";

const CountyRow = ({
  county,
  setSuccessMessage,
  setErrorMessage,
  toggle,
  setSelectedCounty,
}) => {
  const handleDeleteCounty = (id) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    locationServices
      .deleteCountyById(id)
      .then((data) => {
        setSuccessMessage(`Deleted county ${data.name} successfully.`);
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  return (
    <tr>
      <th scope="row">{county.name}</th>
      <td>{county.code}</td>
      <td>23</td>
      <td className="text-right">
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#county"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              href="#delete"
              onClick={(e) => handleDeleteCounty(county.id)}
            >
              Delete
            </DropdownItem>
            <DropdownItem
              href="#add"
              onClick={() => {
                setSelectedCounty(county);
                toggle();
              }}
            >
              Sub-Counties
            </DropdownItem>
            <DropdownItem href="#upadte" onClick={(e) => e.preventDefault()}>
              Update County Details
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};
export default CountyRow;
