import { Container } from "@mui/material";
import OrderSummary from "components/Farmer/OrderSummary";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { Card, CardBody } from "reactstrap";
import { useBuyerOrdersItems } from "services/orderService";

const Orders = () => {
  const { data: orders, isLoading } = useBuyerOrdersItems();

  if (isLoading) <LoadingOverlay />;

  return (
    <Container sx={{ mt: 5 }}>
      <Card>
        <CardBody>
          {orders?.map((orderItem) => (
            <OrderSummary data={orderItem} key={orderItem.id} />
          ))}
        </CardBody>
      </Card>
    </Container>
  );
};
export default Orders;
