import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LaunchIcon from "@mui/icons-material/Launch";
import MUIDataTable from "mui-datatables";
import { useMyResellerReviews } from "services/productService";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import Header from "components/Headers/Header";
import { Container, Row } from "reactstrap";
import { useState } from "react";
import ReviewDetailsModal from "./ReviewDetailsModal";

const currentReviewDefaults = {
  title: "",
  description: "",
  rating: "",
};

const MySalesReview = () => {
  const { data: reviews, isLoading } = useMyResellerReviews();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentReview, setCurrentReview] = useState(currentReviewDefaults);
  const [openReviewModal, setOpenReviewModal] = useState(false);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenMenu = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleToggleOpenReviewModal = (review = currentReviewDefaults) => {
    setCurrentReview(review);
    setOpenReviewModal(!openReviewModal);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "title",
      options: {
        display: "excluded",
      },
    },
    {
      name: "description",
      options: {
        display: "excluded",
      },
    },
    {
      name: "orderItem",
      label: "Product",
      options: {
        customBodyRender: (value) => value.product.name,
      },
    },
    {
      name: "rating",
      label: "Rating",
    },
    {
      name: "orderItem",
      label: "Image",
      options: {
        customBodyRender: (value) => {
          const image = value.product.images[0];
          const API_URL = process.env.REACT_APP_API_URL;
          const imageUrl = `${API_URL}uploads/products/${image}`;
          return (
            <img
              src={imageUrl}
              alt={value.product.name}
              height={70}
              width="auto"
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          const title = tableMeta.rowData[1];
          const description = tableMeta.rowData[2];
          const rating = tableMeta.rowData[4];

          return (
            <IconButton
              onClick={(event) => {
                handleOpenMenu(event);
                setCurrentReview({
                  title,
                  description,
                  rating,
                });
              }}
            >
              <MoreVertIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  if (isLoading) return <LoadingOverlay />;

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <MUIDataTable
              columns={columns}
              data={reviews}
              options={{
                selectableRows: "none",
              }}
            />
          </div>
        </Row>
      </Container>
      <Menu
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleToggleOpenReviewModal(currentReview);
          }}
        >
          <ListItemIcon>
            <LaunchIcon />
          </ListItemIcon>
          View
        </MenuItem>
      </Menu>
      {openReviewModal && (
        <ReviewDetailsModal
          open={openReviewModal}
          onClose={handleToggleOpenReviewModal}
          review={currentReview}
        />
      )}
    </>
  );
};
export default MySalesReview;
