import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormText,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MessageAlert from "components/Shared/MessageAlert";
import { Link, Redirect } from "react-router-dom";
import { useLogin } from "services/userService";
import { useIsAuthenticated } from "providers/authprovider";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().min(8).required("Password is required"),
});

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const { mutate: login, isLoading, isError, isSuccess } = useLogin();
  const {
    // isLoading: loadingAuth,
    user,
    isAuthenticated,
  } = useIsAuthenticated();

  const onSubmit = (data) => {
    login(data);
  };

  if (isSuccess) {
    if (isAuthenticated && user.user_level === "SuperAdmin") {
      return <Redirect to="/admin/index" />;
    } else if (
      isAuthenticated &&
      (user.user_level === "Vet" ||
        user.user_level === "CORP" ||
        user.user_level === "Reseller")
    ) {
      return <Redirect to="/reseller/index" />;
    } else {
      return <Redirect to="/" />;
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-neutral shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h4>Sign in with your credentials</h4>
            </div>
            {isError && (
              <MessageAlert type="danger" message="Invalid credentials" />
            )}
            {isSuccess && (
              <MessageAlert
                type="success"
                message="You have successully logged in"
              />
            )}
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    className="form-control"
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    {...register("email")}
                  />
                  {/* <input type="text" {...register('email')} /> */}
                </InputGroup>
                <FormText color="danger">{errors.email?.message}</FormText>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    placeholder="Password"
                    type="password"
                    className="form-control"
                    autoComplete="new-password"
                    {...register("password")}
                  />
                </InputGroup>
                <FormText color="danger">{errors.password?.message}</FormText>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="default" type="submit">
                  {isLoading ? "Loading..." : "Sign in"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <Link to="/auth/register" className="text-light">
              <small>Create New Account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
