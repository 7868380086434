import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getAllCartItems } from "views/farmer/shoppingCartSlice";

const CheckoutProductListing = () => {
  const items = useSelector(getAllCartItems);
  const listItemStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const subTotal = items.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <Box
      sx={{ width: "100%", backgroundColor: "background.paper", padding: 2 }}
    >
      <List disablePadding>
        {items?.map((item) => (
          <ListItem key={item.id} sx={listItemStyles}>
            <ListItemText
              primary={item.name}
              secondary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" component="span">
                    {item.quantity} x Ksh. {item.price}
                  </Typography>
                  <Typography variant="body2" component="span" sx={{ ml: 2 }}>
                    Ksh. {(item.quantity * item.price).toFixed(2)}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ))}
        <ListItem sx={listItemStyles}>
          <ListItemText
            primary="Subtotal"
            secondary={
              <Typography variant="subtitle1" component="span">
                Ksh. {subTotal.toFixed(2)}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default CheckoutProductListing;
