import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useUpdateVariation } from "services/productService";

const schema = yup
  .object({
    price: yup.number().required().label("Price"),
    rrp: yup.number().required().label("RRP"),
    stockQuantity: yup.number().required().label("Stock Quantity"),
  })
  .required();

const UpdateVariation = ({ isOpen, toggleDisplay, variation }) => {
  const { mutateAsync } = useUpdateVariation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      price: variation?.price || 0,
      rrp: variation?.rrp || 0,
      stockQuantity: variation?.stockQuantity || 0,
    },
  });

  const showSuccessAlert = () => {
    Swal.fire({
      title: "Updated",
      text: "Your variation update was submitted successfully...",
      icon: "success",
      confirmButtonColor: colors.green[900],
    });
  };

  const showErrorAlert = (message) => {
    Swal.fire({
      title: "Failed to Update",
      text: `Failed with the following error: ${message}`,
      icon: "error",
      showCancelButton: true,
      showCloseButton: false,
      cancelButtonColor: colors.red[600],
      cancelButtonText: "Ok",
      showDenyButton: false,
    });
  };

  const onSubmit = (input) => {
    const data = {
      stockQuantity: Number(input.stockQuantity),
      rrp: Number(input.rrp),
      price: Number(input.price),
    };

    mutateAsync({ id: variation.id, data })
      .then(() => {
        showSuccessAlert();
        toggleDisplay();
      })
      .catch((error) => {
        showErrorAlert(error.message);
      });
  };
  return (
    <Dialog open={isOpen} onClose={() => toggleDisplay()}>
      <Box bgcolor="background.paper">
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={3}
          padding={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography>Update Product Variation</Typography>
          <TextField
            label="Price"
            variant="outlined"
            {...register("price")}
            error={errors.price && true}
            helperText={errors.price?.message}
          />
          <TextField
            label="RRP"
            multiline={true}
            type="number"
            variant="outlined"
            {...register("rrp")}
            error={errors.rrp && true}
            inputProps={{ type: "number", step: "any" }}
            helperText={errors.rrp?.message}
          />
          <TextField
            label="Stock Quantity"
            multiline={true}
            type="number"
            variant="outlined"
            {...register("stockQuantity")}
            error={errors.stockQuantity && true}
            helperText={errors.stockQuantity?.message}
          />

          <Button color="success" variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default UpdateVariation;
