import { Box } from "@mui/material";
import { MultipleImageUpload } from "components/admin/products/FileUpload";
import { Button } from "reactstrap";
import Swal from "sweetalert2";

const ProductImagesUploadStepper = ({
  handlePrevStep,
  selectedImages,
  setSelectedImages,
  submitDetails,
}) => {
  const handleImagesSelect = (images) => {
    setSelectedImages(images);
  };
  const handleSubmitProductDetails = () => {
    if (selectedImages.length < 1) {
      // show error message
      Swal.fire({
        title: "Images Required",
        text: "Please select atleast one image to updload",
        icon: "error",
        showCancelButton: false,
        showCloseButton: false,
      });
    } else {
      submitDetails();
    }
  };

  return (
    <>
      <p>Product Images</p>
      <label className="form-control-label" htmlFor="display">
        Display Images
      </label>
      <MultipleImageUpload
        name="multi-image"
        onImageSelect={handleImagesSelect}
      />

      {selectedImages.map(
        (image) =>
          image &&
          image instanceof Blob && (
            <img
              key={image.name}
              src={URL.createObjectURL(image)}
              alt={image.name}
              style={{ maxHeight: "128px", maxWidth: "128px" }}
            />
          )
      )}
      <Box>
        <Button onClick={handlePrevStep}>Back</Button>
        <Button onClick={handleSubmitProductDetails}>Finish</Button>
      </Box>
    </>
  );
};
export default ProductImagesUploadStepper;
