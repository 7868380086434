import React from "react";
import { Button } from "reactstrap";
import { layer, custom, Map, Layers } from "react-openlayers";
import * as ol from "openlayers";
import { Box } from "@mui/material";
import { Dialog } from "@mui/material";
import { transform } from "ol/proj";

const DisplayMap = ({
  resellerCoordinates,
  manufacturerCoordinates,
  handleShowMap,
  isOpen,
}) => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const getCordinatesArray = (value) => {
    const coordinatesArray = value.split(",");
    const firstCoordinate = parseFloat(coordinatesArray[0]);
    const secondCoordinate = parseFloat(coordinatesArray[1]);

    return [firstCoordinate, secondCoordinate];
  };

  let theResellerLocation = transform(
    getCordinatesArray(resellerCoordinates),
    "EPSG:4326",
    "EPSG:3857"
  );
  let theManufacturerLocation = transform(
    getCordinatesArray(manufacturerCoordinates),
    "EPSG:4326",
    "EPSG:3857"
  );

  var marker = new custom.style.MarkerStyle(
    "https://openlayers.org/en/v4.0.1/examples/data/icon.png"
  );
  var iconFeature = new ol.Feature(
    new ol.geom.Point([theResellerLocation[0], theResellerLocation[1]])
  );

  var iconFeature2 = new ol.Feature(
    new ol.geom.Point([theManufacturerLocation[0], theManufacturerLocation[1]])
  );

  var source = new ol.source.Vector({ features: [iconFeature, iconFeature2] });

  //Calculate Distance in KMs
  const calculateDistance = (lat1, long1, lat2, long2) => {
    const pi80 = Math.PI / 180;
    lat1 *= pi80;
    lat2 *= pi80;
    long1 *= pi80;
    long2 *= pi80;
    const r = 6372.797; // Average earth radius
    const dlat = lat2 - lat1;
    const dlon = long2 - long1;
    const a =
      Math.sin(dlat / 2) * Math.sin(dlat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) * Math.sin(dlon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const km = r * c;
    const roundedKm = km.toFixed(2);

    return parseFloat(roundedKm);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => handleShowMap()} zIndex={1400}>
        <Button onClick={() => handleShowMap()}>Close Map</Button>
        <Box
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Map
            view={{
              center: [theManufacturerLocation[0], theManufacturerLocation[1]],
              zoom: 6,
            }}
          >
            {" "}
            <Layers>
              <layer.Tile />
              <layer.Vector style={marker.style} source={source} />
            </Layers>
          </Map>
          <div>
            <p>
              Total Distance to Reseller Location is About:{" "}
              {calculateDistance(
                getCordinatesArray(manufacturerCoordinates)[1],
                getCordinatesArray(manufacturerCoordinates)[0],
                getCordinatesArray(resellerCoordinates)[1],
                getCordinatesArray(resellerCoordinates)[0]
              )}{" "}
              KM
            </p>
          </div>
        </Box>
      </Dialog>
    </>
  );
};

export default DisplayMap;
