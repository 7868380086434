import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "reactstrap";
import { useCreatePartner } from "services/financeServices";
import Swal from "sweetalert2";
import { useUpdatePartner } from "services/financeServices";
import { useSpecificPartner } from "services/financeServices";
import { useEffect } from "react";

const schema = yup.object({
  name: yup.string().required().label("Partner Name"),
  phoneNumber: yup.string().min(10).required().label("Phone Number"),
  description: yup.string().required().label("Description"),
  email: yup.string().email().required().label("Email"),
  rate: yup
    .number()
    .min(0)
    .max(100)
    .required()
    .label("Profit Cut Rate")
    .typeError("Enter valid profit rate"),
});

const CreateEditPartner = ({ partnerId }) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutateAsync: createPartner, createLoading } = useCreatePartner();
  const { mutateAsync: updatePartner, updateIsLoading } = useUpdatePartner();
  const { data: partner, isLoading: partnerIsLoading } =
    useSpecificPartner(partnerId);

  useEffect(() => {
    if (partnerId && !partnerIsLoading) {
      setValue("name", partner?.name);
      setValue("email", partner?.email);
      setValue("description", partner?.description);
      setValue("phoneNumber", partner?.phoneNumber);
      setValue("rate", partner?.rate);
    }
  }, [partner, setValue, partnerId, partnerIsLoading]);

  const createNewPartner = (data) => {
    createPartner(data)
      .then(() => {
        Swal.fire({
          title: "Created successfully!",
          text: "Partner details saved succesfully.",
          icon: "success",
        });
      })
      .catch((error) => {
        const message = error.response.data.message;
        Swal.fire({
          title: "Failed to save!",
          text: `Error occurred while trying to create partner details. Responded with '${message}'`,
          icon: "error",
        });
      });
  };

  const updatePartnerDetails = (data) => {
    updatePartner({ id: partnerId, data })
      .then(() => {
        Swal.fire({
          title: "Updated successfully!",
          text: `Partner details updated successfully.`,
          icon: "success",
        });
      })
      .catch((error) => {
        const message = error.response.data.message;

        Swal.fire({
          title: "Failed to udpated details.",
          text: `Failed to update partner details. Responded with '${message}'`,
          icon: "error",
        });
      });
  };
  const onSubmit = (data) => {
    if (partnerId) updatePartnerDetails(data);
    else createNewPartner(data);
  };
  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        py: 3,
        px: 2,
      }}
    >
      <Typography fontWeight={"bold"} fontSize={"2rem"}>
        {`${partnerId ? "Edit" : "Create"} Partner Details`}
      </Typography>
      <Divider />
      <FormControl>
        <InputLabel htmlFor="name" error={!!errors.name}>
          Name
        </InputLabel>
        <OutlinedInput
          id="name"
          placeholder="ICIPE"
          label="Name"
          {...register("name")}
          error={!!errors.name}
        />
        <FormHelperText error={!!errors.name}>
          {errors.name?.message}
        </FormHelperText>
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="phoneNumber" error={!!errors.phoneNumber}>
          Phone Number
        </InputLabel>
        <OutlinedInput
          id="phone"
          placeholder="0722000000"
          label="Phone Number"
          {...register("phoneNumber")}
          error={!!errors.phoneNumber}
        />
        <FormHelperText error={!!errors.phoneNumber}>
          {errors.phoneNumber?.message}
        </FormHelperText>
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="email" error={!!errors.email}>
          Email
        </InputLabel>
        <OutlinedInput
          id="email"
          placeholder="sales@lima.com"
          label="Email"
          {...register("email")}
          error={!!errors.email}
        />
        <FormHelperText error={!!errors.email}>
          {errors.email?.message}
        </FormHelperText>
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="description" error={!!errors.description}>
          Description
        </InputLabel>
        <OutlinedInput
          id="description"
          placeholder="In charge of transaction management"
          label="Description"
          {...register("description")}
          error={!!errors.description}
        />
        <FormHelperText error={!!errors.description}>
          {errors.description?.message}
        </FormHelperText>
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="profit" error={!!errors.rate}>
          Profit Rate
        </InputLabel>
        <OutlinedInput
          id="profit"
          placeholder="10"
          label="Profit Rate"
          {...register("rate")}
          error={!!errors.rate}
          type="number"
        />
        <FormHelperText error={!!errors.rate}>
          {errors.rate?.message}
        </FormHelperText>
      </FormControl>

      <Box>
        <Button
          disabled={createLoading || updateIsLoading || partnerIsLoading}
          color="success"
        >
          {partnerId ? "Edit" : "Add"}
        </Button>
      </Box>
    </Box>
  );
};
export default CreateEditPartner;
