import React from "react";
import { useSelector } from "react-redux";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { getAllCartItems } from "views/farmer/shoppingCartSlice";
import ShoppingCartItem from "./ShoppingCartItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ShoppingCart = () => {
  const items = useSelector(getAllCartItems);
  const history = useHistory();

  const total = items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  return (
    <div className="sidebar-shopping-cart">
      <h5 className="mb-3">Shopping Cart</h5>
      <ListGroup>
        {items?.map((item, index) => (
          <ShoppingCartItem item={item} key={index} />
        ))}
      </ListGroup>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <p className="mb-0 font-weight-bold">Total:</p>
        <span className="font-weight-bold">Ksh.{total}</span>
      </div>
      <div className="d-flex justify-content-end align-items-right">
        <Button
          color="default"
          className="mt-3"
          onClick={() => history.push("/checkout")}
        >
          Checkout
        </Button>
      </div>
    </div>
  );
};

export default ShoppingCart;
