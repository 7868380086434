import { useEffect, useState } from "react";

const ErrorMessages = ({ error }) => {
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    setErrorList([]);
    const errors = error ? error.response.data.message : [];

    if (errors) {
      Array.isArray(errors)
        ? errors.forEach((_error) =>
            setErrorList((prevState) => [...prevState, _error])
          )
        : setErrorList([errors]);
    }
  }, [error]);

  if (!errorList.length > 0) return;

  return (
    <div className="alert alert-danger">
      <ul className="list-group">
        {errorList.map((error, index) => (
          <li style={{ listStyleType: "none" }} key={index}>
            {error}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ErrorMessages;
