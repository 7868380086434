import { Text, View } from "@react-pdf/renderer";
import { styles } from "../InvoiceReport";
import { titleCase } from "title-case";
import React from "react";

const CustomerDetails = ({ invoice }) => {
  return (
    <View style={{ flexDirection: "column", gap: 10 }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ ...styles.itemGroup, flex: 1 }}>
          <Text style={styles.itemGroupKey}>Customer:</Text>
          <Text>{titleCase(invoice.buyerName)}</Text>
        </View>
        <View style={{ ...styles.itemGroup, flex: 1 }}>
          <Text style={styles.itemGroupKey}>Customer Email:</Text>
          <Text>{String(invoice.buyerEmail).toLocaleLowerCase()}</Text>
        </View>
      </View>
      <View style={styles.itemGroup}>
        <Text style={styles.itemGroupKey}>Phone Number:</Text>
        <Text>{invoice.buyerPhone}</Text>
      </View>
    </View>
  );
};
export default CustomerDetails;
