import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components

import React, { useEffect, useMemo, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useUsers } from "services/getuserService";

import AddUserLevel from "components/admin/user/addUserLevel";
import { useSearch } from "providers/searchprovider";
import UserForm from "./UpdateUserForm";
import userService from "services/userService";
import Swal from "sweetalert2";
import { EditUser } from "services/userService";
// import userForm from "components/admin/user/userform";

const Users = () => {
  const [item, setItem] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { data = [], isLoading, error, refetch } = useUsers();
  const { mutateAsync } = EditUser();

  // Search Function
  const { search } = useSearch();

  const searchUsers = useMemo(
    () =>
      data.filter(
        (item) =>
          item.first_name.toLowerCase().includes(search) ||
          item.last_name.toLowerCase().includes(search)
      ),
    [data, search]
  );

  let toggle = () => {
    setModalOpen(!modalOpen);
    refetch();
  };

  let btnFuns = (item = {}) => {
    setShowEditModal(!showEditModal);
    setItem(item);
  };

  let changeUserStatus = (status, id, userLevel) => {
    let patchAPI;
    if (userLevel === "SuperAdmin") {
      patchAPI = "api1";
    } else if (userLevel === "Admin") {
      patchAPI = "api2";
    } else if (userLevel === "Manufacturer") {
      patchAPI = "api3";
    }

    mutateAsync({ id: id, data: { status: status }, patchAPI: patchAPI })
      .then((res) => {
        Swal.fire({
          title: "Status Update Success",
          text: "You have disabled the user successfully.",
          icon: "success",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Status Update Failed",
          text: "Failed to Disable User.",
          icon: "error",
        });
      });
  };
  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "first_name",
      options: {
        display: "excluded",
        options: { filterOptions: { fullWidth: true } },
      },
    },
    {
      name: "last_name",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = tableMeta.rowData[1] + " " + tableMeta.rowData[2];
          return user;
        },
      },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "id_number",
      label: "ID Number",
    },
    {
      name: "phone_number",
      label: "Phone Number",
    },
    {
      name: "registration_number",
      label: "Reg Number",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "countyId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "sub_countyId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "county",
      label: "County",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = tableMeta.rowData[11];
          return user?.fisr;
        },
      },
    },
    {
      name: "subcounty",
      label: "Sub County",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = tableMeta.rowData[12];
          return user?.name;
        },
      },
    },
    {
      name: "user_level",
      label: "User Level",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowId = tableMeta.rowData;
          let item = {
            id: rowId[0],
            first_name: rowId[1],
            last_name: rowId[2],
            email: rowId[4],
            id_number: rowId[5],
            phone_number: rowId[6],
            status: rowId[8],
            countyId: rowId[9],
            sub_countyId: rowId[10],
            userLevel: rowId[13],
          };
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => btnFuns(item)}
              >
                Edit
              </Button>
              {/* <EditUser variant="contained" onClick={() => btnFuns(item)}/> */}
              <br />
              <br />
              {item.status === "Active" && (
                <Button
                  variant="contained"
                  color="danger"
                  onClick={() =>
                    changeUserStatus("InActive", item.id, item.userLevel)
                  }
                >
                  DeActivate
                </Button>
              )}
              {item.status === "InActive" && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    changeUserStatus("Active", item.id, item.userLevel)
                  }
                >
                  Activate
                </Button>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "simple",
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Spinner
          color="success"
          style={{
            height: "10rem",
            width: "10rem",
          }}
        >
          Loading...
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Alert color="warning">
          <strong>Error!</strong> {error.message}
        </Alert>
      </div>
    );
  }

  return (
    <>
      {/* <Header /> */}
      <div className="header bg-gradient-default pb-8 pt-5 pt-md-6"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        Table
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Users</h3>
              </CardHeader>
              <br />

              <MUIDataTable data={data} columns={columns} options={options} />

              {/* {showEditPopup && (
                <div>
                  <h2>Edit Data</h2>
                  <form>
                    <label>
                      Name:
                      <input type="text" value={editData.first_name} />
                    </label>
                    <br />
                    <label>
                      Age:
                      <input type="number" value={editData.email} />
                    </label>
                    <br />
                    <button type="submit">Save</button>
                  </form>
                </div>
              )} */}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={showEditModal} toggle={() => btnFuns({})}>
        <ModalHeader toggle={() => btnFuns({})}>Edit User</ModalHeader>
        <ModalBody>
          <UserForm item={item} />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};

export default Users;
