import { styled } from "@mui/material";
import MUIDataTable from "mui-datatables";

const CustomMUIDataTable = styled(MUIDataTable)({
  boxShadow: "none",
});

const OrderItemsTableList = ({
  products,
  muiTableOptions = {
    selectableRows: "none",
    pagination: false,
    search: false,
    download: false,
    viewColumns: false,
    filter: false,
  },
}) => {
  console.log(products);
  const columns = [
    {
      name: "productName",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "variationName",
      label: "Variation",
    },
    {
      name: "quantity",
      label: "Qty",
    },
    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "rrp",
      label: "RRP",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "image",
      label: "Product",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          console.log(value);
          return (
            <img
              style={{ maxHeight: "64px", maxWidth: "64px" }}
              src={`${process.env.REACT_APP_API_URL}uploads/products/${value[0].fileName}`}
              alt="Product"
            />
          );
        },
      },
    },
  ];
  return (
    <>
      <CustomMUIDataTable
        columns={columns}
        data={products}
        options={muiTableOptions}
      />
    </>
  );
};
export default OrderItemsTableList;
