import MUIDataTable from "mui-datatables";
import InnerLayout from "./InnerLayout";
import { parseModeOfPayment } from "components/Finance/InvoiceReport";
import { usePayments } from "services/financeServices";
import { useEffect, useState } from "react";
import { Button, Spinner } from "reactstrap";
import DateRangeSelectionModal from "components/Finance/DateRangeSelectionModal";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  styled,
} from "@mui/material";
import { Download, MoreVert, ViewAgenda } from "@mui/icons-material";
import PaymentReport from "components/Finance/PaymentReport";

// {
//   "paymentId": "e7dc66d6-0ad9-4de0-a2f0-14bf524d137d",
//   "amountPaid": 3,
//   "datePaid": "2023-08-15T10:51:52.407Z",
//   "invoiceNumber": "RES202300001A",
//   "paymentMode": "mpesaExpress",
//   "transactionCode": "RHF66ESBLQ"
// },
const Payments = () => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [showRangeModal, setShowRangeModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPaymentReport, setShowPaymentReport] = useState(false);

  const {
    data: payments,
    isLoading: paymentsLoading,
    refetch,
  } = usePayments({ from, to });

  useEffect(() => {
    refetch();
  }, [refetch, to, from]);

  const CustomModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: 3,
  });

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "amountPaid",
      label: "Amount (Ksh)",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "transactionCode",
      label: "Transaction Code",
      options: {
        customBodyRender: (value) => String(value).toUpperCase(),
      },
    },
    {
      name: "paymentMode",
      label: "Payment Mode",
      options: {
        customBodyRender: (value) => parseModeOfPayment(value),
      },
    },
    {
      name: "paymentId",
      label: "Actions",
      options: {
        customBodyRender: (value) => (
          <IconButton
            onClick={(e) => {
              setSelectedPayment(value);
              setAnchorEl(e.target);
            }}
          >
            <MoreVert />
          </IconButton>
        ),
      },
    },
  ];

  if (paymentsLoading) return <Spinner />;

  function toggleShowPaymentDetails() {
    setShowPaymentReport((prev) => !prev);
  }

  return (
    <InnerLayout>
      <Box sx={{ mb: 3 }}>
        <Button
          size="small"
          color="success"
          onClick={() => setShowRangeModal(true)}
        >
          Filter By Date
        </Button>
      </Box>
      <MUIDataTable
        columns={columns}
        data={payments}
        options={{
          selectableRows: "none",
          pagination: false,
          viewColumns: "false",
          filter: "false",
        }}
      />

      <DateRangeSelectionModal
        isOpen={showRangeModal}
        onClose={() => setShowRangeModal(false)}
        from={from}
        to={to}
        setFrom={setFrom}
        setTo={setTo}
      />

      {/* modal to show invoice pdf report */}
      {selectedPayment !== null && showPaymentReport && (
        <CustomModal
          open={showPaymentReport}
          onClose={() => toggleShowPaymentDetails()}
        >
          <PaymentReport paymentId={selectedPayment} />
        </CustomModal>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <ListItemIcon>
            <ViewAgenda />
          </ListItemIcon>
          <ListItemText onClick={() => toggleShowPaymentDetails()}>
            View
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText>Download PDF</ListItemText>
        </MenuItem>
      </Menu>
    </InnerLayout>
  );
};
export default Payments;
