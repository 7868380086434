// import axios from "./axiosInstance";
import axios from "./axiosInstance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const stockService = {
  // get stock
  getStock: async () => {
    return await axios.get("/stock/all").then((res) => res.data);
  },
  // request stock
  requestRestock: async (data) => {
    return await axios.post("/restock/request", data).then((res) => res.data);
  },
  // get pending stock requests -> for manufacturer
  getPendingRestock: async () => {
    return await axios
      .get("/restock/manufacturer?status=pending")
      .then((res) => res.data);
  },
  // get reseller restock request by status -> optional status
  // status -> | pending | accepted | rejected | transit | delivered | expired
  getResellerRestockRequest: async (status) => {
    return await axios
      .get(`/restock/reseller${status && "?status=" + status}`)
      .then((res) => res.data);
  },
  getManufacturerRestockingRequests: async (status) => {
    return await axios
      .get(`/manufactuer/reseller${status && "?status=" + status}`)
      .then((res) => res.data);
  },
  /* Claim pending stock */
  claimPendingRestock: async (id) => {
    return await axios
      .patch(`/restock/accept?id=${id}`)
      .then((res) => res.data);
  },

  /* MANUFACTURER - Accept pending stock */
  processRestockingRequest: async ({ id, status }) => {
    return await axios
      .patch(`/restock/process?id=${id}&status=${status}`)
      .then((res) => res.data);
  },

  /* MANUFACTURER - Reject pending stock request */
  rejectPendingRestock: async (id) => {
    return await axios
      .patch(`/restock/process?id=${id}&status=reject`)
      .then((res) => res.data);
  },

  // get specific reseller stock
  getResellerStock: async () => {
    return await axios.get("/stock/reseller").then((res) => res.data);
  },
  // update restocking request
  confirmNewRestockingOrder: async (data) => {
    return await axios.patch("/restock/process", data).then((res) => res.data);
  },

  // RESELLER - confirm order charges - after after of delivery fee
  confirmRestockRequestCharges: async (data) =>
    await axios.patch("/restock/confirm-fee", data).then((res) => res.data),

  // RESELLER - fetch restock order details for payment
  getRestockRequestPaymentDetails: async (restockId) =>
    await axios
      .get(`/restock/payment-details/${restockId}`)
      .then((res) => res.data),
  // MANUFACTURER - Submit completion code to complete order
  completeRestockingOrder: async (data) =>
    await axios.patch("restock/complete-order", data).then((res) => res.data),
  // MANUFACTURER - request new order completion code for reseller
  getNewRestockCompletionCode: async (id) =>
    await axios
      .post(`restock/new-confirmation-code/${id}`)
      .then((res) => res.data),
};

export const useStock = () =>
  useQuery({ queryKey: ["allStock"], queryFn: () => stockService.getStock() });

export const usePendingRestock = () =>
  useQuery({
    queryKey: ["allRestockPending"],
    queryFn: () => stockService.getPendingRestock(),
  });

export const useResellerRestockRequests = (status) =>
  useQuery({
    queryKey: ["reseller-restock", status && status],
    queryFn: () => stockService.getResellerRestockRequest(status),
  });

export const useRequestStock = () =>
  useMutation({
    mutationFn: (data) => {
      return stockService.requestRestock(data);
    },
  });
// claim stock restock by stock restock request id
export const useStockClaim = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => stockService.claimPendingRestock(id),
    onSuccess: () => queryClient.invalidateQueries(["allRestockPending"]),
  });
};

// Accept Pending restock
export const useUpdateRestokingRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id, status) =>
      stockService.processRestockingRequest(id, status),
    onSuccess: () => queryClient.invalidateQueries(["allRestockPending"]),
  });
};

export const useResellerStock = () => {
  return useQuery({
    queryKey: ["my-reseller-stock"],
    queryFn: stockService.getResellerStock,
  });
};

export const useManufacturerRestockingRequests = (status) =>
  useQuery({
    queryKey: ["manufacturer-restock", status && status],
    queryFn: () => stockService.getResellerRestockRequest(status),
  });

export const useMutateConfirmRestockingRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => stockService.confirmNewRestockingOrder(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["reseller-restock", "pending"]);
      queryClient.invalidateQueries(["reseller-restock", "rejected"]);
      queryClient.invalidateQueries(["reseller-restock", "accepted"]);
      queryClient.invalidateQueries(["manufacturer-restock", "pending"]);
      queryClient.invalidateQueries(["manufacturer-restock", "accepted"]);
      queryClient.invalidateQueries(["manufacturer-restock", "rejected"]);
    },
  });
};

export const useConfirmOrderCharges = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => stockService.confirmRestockRequestCharges(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["reseller-restock", "accepted"]);
      queryClient.invalidateQueries(["reseller-restock", "rejected"]);
      queryClient.invalidateQueries(["reseller-restock", "to_pay"]);
      queryClient.invalidateQueries(["manufacturer-restock", "accepted"]);
      queryClient.invalidateQueries(["manufacturer-restock", "rejected"]);
      queryClient.invalidateQueries(["manufacturer-restock", "to_pay"]);
    },
  });
};

export const useFetchOrderPaymentDetails = (restockId) =>
  useQuery({
    queryFn: () => stockService.getRestockRequestPaymentDetails(restockId),
  });

export const useCompleteRestockRequestOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => stockService.completeRestockingOrder(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["manufacturer-restock", "transit"]);
      queryClient.invalidateQueries(["manufacturer-restock", "delivered"]);
    },
  });
};

export const useRequestNewRestockingCompletionCode = () =>
  useMutation({
    mutationFn: (id) => stockService.getNewRestockCompletionCode(id),
  });
