import { Box, Button, Modal, Typography, colors, styled } from "@mui/material";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { parseISO, formatDistanceToNow } from "date-fns";
import { useUpdateReviewStatus } from "services/productService";
import { useFullReviewDetails } from "services/productService";
import Swal from "sweetalert2";

const getManufacturerNames = (manufactuer) => {
  return `${manufactuer?.first_name} ${manufactuer?.last_name}`;
};

// icon -> success | error | info | question | warning

const showCompleteMessage = (title, text, buttonText, icon, color) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    iconColor: color,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: buttonText,
    cancelButtonColor: color,
  });
};

const ViewFullDetails = ({ open, onClose, reviewId }) => {
  const { data: review = null, isLoading } = useFullReviewDetails(reviewId);
  const { mutateAsync } = useUpdateReviewStatus();

  const CustomModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const handleUpdateReviewStatus = (reviewId, status) => {
    mutateAsync({ reviewId, status })
      .then((res) => {
        const productName = res.title;
        if (res.status === "approved")
          showCompleteMessage(
            "Approved Successfully",
            `Review for ${productName} has been approved.`,
            "Okay",
            "success",
            colors.green[900]
          );
        else if (res.status === "rejected")
          showCompleteMessage(
            "Reviewed Rejected",
            `Review for ${productName} has been revoked.`,
            "Close",
            "warning",
            colors.amber[600]
          );
      })
      .catch((error) => {
        showCompleteMessage(
          "Failed to process",
          `Error occurred, with message: ${error.message}`,
          "Close",
          "error",
          colors.red[800]
        );
      })
      .finally(() => {
        onClose();
      });
  };

  if (isLoading || review === null) return <LoadingOverlay />;

  return (
    <CustomModal open={open} onClose={onClose}>
      <Box
        bgcolor="background.paper"
        px={5}
        py={3}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Typography variant="h6" fontWeight="bold" textTransform="uppercase">
          Product Review:
        </Typography>
        <Typography>
          Manufacturer:{" "}
          {review?.orderItem.order.seller.first_name +
            " " +
            review?.orderItem.order.seller.last_name}
        </Typography>
        <Typography fontWeight="bold">Review:</Typography>
        <Typography>Title: {review.title}</Typography>
        <Typography>Description: {review.description}</Typography>
        <Typography textTransform="capitalize">
          Reviewed On: {formatDistanceToNow(parseISO(review.created_at))}
        </Typography>
        <Box display="flex" flexDirection="row" gap={1}>
          <Button
            color="info"
            variant="contained"
            onClick={() => handleUpdateReviewStatus(review.id, "approved")}
          >
            Approve
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => handleUpdateReviewStatus(review.id, "rejected")}
          >
            Reject
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};
export default ViewFullDetails;
