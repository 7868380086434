import ListManufacturersProducts from "components/Product/Product/ListManufacturersProducts";
import React from "react";

function ListProducts() {
  return (
    <>
      {/* Page content */}
      {/* Table */}
      <ListManufacturersProducts />
    </>
  );
}

export default ListProducts;
