import React, { useState } from "react";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { Button, Card, CardHeader, Container, Row } from "reactstrap";
import Modal from "@mui/material/Modal";
import { useSpecificManufacturersProducts } from "services/productService";
import { useUpdateProductStatus } from "services/productService";
import MUIDataTable from "mui-datatables";
import { Box } from "@mui/material";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import UpdateVariation from "components/Product/Product/UpdateVariation";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { MoreVert, DeleteOutline, ToggleOff } from "@mui/icons-material";

// import { useDeleteVariation } from "services/productService";

import { useDeleteVariation } from "services/productService";
import EditProduct from "./EditProduct";

const style = {
  position: "absolute",
  top: "40%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: "78%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ListManufacturersProducts() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { data = [], isLoading } = useSpecificManufacturersProducts();
  const { mutateAsync } = useUpdateProductStatus();
  const [variation, setVariation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [variationId, setVariationId] = useState(null);
  const [variationStatus, setVariationStatus] = useState(null);

  const [open, setOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] =
    useState(false);
  const [theId, setTheId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [images, setImages] = useState([null]);

  const toggleDisplay = (variation = null) => {
    setVariation(variation);
    setIsOpen(!isOpen);
  };
  const deleteVariationMutation = useDeleteVariation();
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (variationId) => {
    try {
      // Call the mutation function to delete the variation
      const deletionResponse = await deleteVariationMutation.mutateAsync({
        id: variationId,
      });

      // Check the backend response to determine success or deactivation
      if (
        deletionResponse.message ===
        "Product and variation deleted successfully"
      ) {
        // Display a success notification for deletion
        Swal.fire({
          title: "Deletion Success",
          text: "Product and variation deleted successfully.",
          icon: "success",
        });
      } else if (
        deletionResponse.message === "Product variation deleted successfully"
      ) {
        // Display a success notification for variation deletion
        Swal.fire({
          title: "Deletion Success",
          text: "Variation deleted successfully.",
          icon: "success",
        });
      } else if (
        deletionResponse.message ===
        "Product variation deactivated successfully"
      ) {
        // Display a success notification for variation deactivation
        Swal.fire({
          title: "Deactivation Success",
          text: "Variation deactivated successfully.",
          icon: "success",
        });
      }

      console.log("Variation deleted/deactivated successfully");
    } catch (error) {
      // Handle error if deletion/deactivation fails
      Swal.fire({
        title: "Deletion/Deactivation Failed",
        text: "Failed to delete or deactivate the variation.",
        icon: "error",
      });

      console.error("Error deleting/deactivating variation:", error);
    }
  };
  const handleClose = () => {
    setSelectedProduct(null);
    setOpen(false);
  };

  const mutateASycFunction = (id, status) => {
    mutateAsync({ id: id, status: status })
      .then(() => {
        Swal.fire({
          title: "Status Update Success",
          text: "You have updated the status successfully.",
          icon: "success",
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Status Update Failed",
          text: "Failed to update the status.",
          icon: "error",
        });
      });
  };

  const ChangeProductStatus = (data) => {
    if (data.status === "NotDisabled") {
      mutateASycFunction(data.id, "Disabled");
    } else if (data.status === "Disabled") {
      mutateASycFunction(data.id, "NotDisabled");
    }
  };

  const handleToggleModal = () => {
    setIsOpen(false);
  };

  const displayVariations = (id) => {
    const product = data.find((product) => product.id === id);
    if (product === null) {
      // display message invalid selection
      Swal.fire({
        title: "Invalid Selection",
        text: "You selected invalid product.",
        icon: "error",
      });
      return;
    }
    setSelectedProduct(product);
    setOpen(true);
  };

  const editProductDetails = (
    productId,
    productName,
    description,
    productImages
  ) => {
    setIsOpen(true);
    setTheId(productId);
    setName(productName);
    setDescription(description);
    setImages(productImages);
  };
  const columns = [
    {
      name: "name",
      label: "Product Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === "NotDisabled") {
            return <p style={{ color: "green" }}>Active</p>;
          } else {
            return <p style={{ color: "red" }}>Disabled</p>;
          }
        },
      },
    },
    {
      name: "variations",
      label: "Number of variations",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value.length,
      },
    },
    {
      name: "images",
      label: "Images",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <img
              style={{ maxHeight: "64px", maxWidth: "64px" }}
              src={`${process.env.REACT_APP_API_URL}uploads/products/${value[0]?.fileName}`}
              alt="Product"
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Variations",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Button onClick={() => displayVariations(value)}>View</Button>;
        },
      },
    },
    {
      name: "id",
      label: "Disable/Enable",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const dataToBePassedOnClick = {
            id: value,
            status: tableMeta.rowData[1],
          };
          if (tableMeta.rowData[1] === "Disabled") {
            return (
              <Button
                onClick={() => ChangeProductStatus(dataToBePassedOnClick)}
              >
                Enable
              </Button>
            );
          } else if (tableMeta.rowData[1] === "NotDisabled") {
            return (
              <Button
                onClick={() => ChangeProductStatus(dataToBePassedOnClick)}
              >
                Disable
              </Button>
            );
          }
        },
      },
    },
    {
      name: "id",
      label: "Edit Product",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button
              onClick={() =>
                editProductDetails(
                  value,
                  tableMeta.rowData[0],
                  tableMeta.rowData[7],
                  tableMeta.rowData[3]
                )
              }
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "description",
      label: "description",
      options: {
        sort: false,
        filter: false,
        display: "excluded",
      },
    },
  ];

  const variationColumns = [
    {
      name: "variation",
      label: "variation",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "price",
      label: "price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "rrp",
      label: "RRP",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "stockQuantity",
      label: "Stock Quantity",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "isActive",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <span style={{ color: value ? "green" : "red" }}>
            {value ? "Active" : "Deactivated"}
          </span>
        ),
      },
    },

    {
      name: "id",
      label: "Edit Stock",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Button
              type="success"
              onClick={() => {
                const selectedVariation = selectedProduct?.variations.find(
                  (variation) => variation.id === value
                );

                toggleDisplay(selectedVariation);
                console.log("Selected VariationID :", selectedVariation.id);
              }}
            >
              {" "}
              Edit{" "}
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              onClick={(e) => {
                const selectedVariation = selectedProduct?.variations.find(
                  (variation) => variation.id === value
                );
                setVariationId(selectedVariation.id);
                setVariationStatus(selectedVariation.isActive);
                setAnchorEl(e.target);
              }}
            >
              <MoreVert />
            </IconButton>
          );
        },
      },
    },

    // {
    //   name: "id",
    //   label: "Action",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <Button
    //           type="success"
    //           onClick={() => {
    //             const selectedVariation = selectedProduct?.variations.find(
    //               (variation) => variation.id === value
    //             );
    //             console.log("Selected Variation:", selectedVariation);

    //             // toggleDisplay(selectedVariation.id);

    //             console.log("Selected VariationID :", selectedVariation.id);
    //             handleDelete(selectedVariation.id);
    //           }}
    //         >
    //           {" "}
    //           Delete/Deactivate{" "}
    //         </Button>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <IconButton
    //           onClick={(e) => {
    //             setAnchorEl(e.target);
    //           }}
    //         >
    //           <MoreVert />
    //         </IconButton>
    //       );
    //     },
    //   },
    // },
  ];

  if (isLoading) {
    return (
      <>
        {/* Loading overlay */}
        <div className="header bg-gradient-default pb-8 pt-5 pt-md-6"></div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <LoadingOverlay />
            </div>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <div className="header bg-gradient-default pb-8 pt-5 pt-md-6"></div>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Your Products</h3>
              </CardHeader>
              <br />
              <div></div>
              {/* MUIDataTable */}
              <MUIDataTable
                title={"Manufacturer's Product"}
                data={data}
                columns={columns}
                options={{
                  selectableRows: "none",
                }}
              />
            </Card>
          </div>
        </Row>
      </Container>

      {/* Variations table dialog */}
      {selectedProduct !== null && (
        <Modal onClose={handleClose} open={open}>
          {/* Render variations table here */}
          <Container className="mt--7" fluid>
            <Row>
              <Box sx={style}>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <h3 className="mb-0">Variations</h3>
                    </CardHeader>
                    <br />
                    <MUIDataTable
                      title={selectedProduct.name + " Variations"}
                      data={selectedProduct.variations}
                      columns={variationColumns}
                      options={{
                        selectableRows: "none",
                      }}
                    />
                    <Menu
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                    >
                      {variationStatus === false && (
                        <MenuItem>
                          <ListItemIcon>
                            <ToggleOff />
                          </ListItemIcon>
                          <ListItemText
                            onClick={() => {
                              handleDelete(variationId);
                            }}
                          >
                            Deactivate
                          </ListItemText>
                        </MenuItem>
                      )}
                      {variationStatus === true && (
                        <MenuItem>
                          <ListItemIcon>
                            <DeleteOutline />
                          </ListItemIcon>
                          <ListItemText
                            onClick={() => {
                              handleDelete(variationId);
                            }}
                          >
                            Delete
                          </ListItemText>
                        </MenuItem>
                      )}
                    </Menu>
                  </Card>
                </div>
              </Box>
            </Row>
          </Container>
        </Modal>
      )}

      {/* Option Edit selection Modat */}
      {isOpen && (
        <EditProduct
          theId={theId}
          name={name}
          description={description}
          images={images}
          isOpen={isOpen}
          handleClose={handleToggleModal}
        />
      )}
      {/* Product Details Modal */}
      {isProductDetailsModalOpen && (
        <Modal
          open={isProductDetailsModalOpen}
          onClose={setIsProductDetailsModalOpen(false)}
        >
          <Box
            component={"form"}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h5">Edit Product Details</Typography>
            <TextField
              label="Stock Quantity"
              type="number"
              value={"editedQuantity"}
              fullWidth
              margin="normal"
            />
          </Box>
        </Modal>
      )}
      {variation !== null && (
        <UpdateVariation
          // itemId={orderItem.id}
          isOpen={isOpen}
          toggleDisplay={toggleDisplay}
          variation={variation}
        />
      )}
    </>
  );
}

export default ListManufacturersProducts;
