import { Cancel, Check, MoreVert, ViewAgenda } from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { formatDistanceToNow, parseISO } from "date-fns";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useManufacturerRestockingRequests } from "services/stockService";
import OrderDetailsProcessing from "./OrderDetailsProcessing";
import { useMutateConfirmRestockingRequest } from "services/stockService";
import Swal from "sweetalert2";
import CompleteOrderModal from "./CompleteOrderModal";
import { formatedRestockStatus } from "../ResellerRestockRequestListing";

const ManufacturerRestockRequest = ({ status = "pending" }) => {
  const { data: restockingRequests, isLoading } =
    useManufacturerRestockingRequests(status);
  const { mutateAsync, isLoading: updateIsLoading } =
    useMutateConfirmRestockingRequest();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [showCompleteOrderModal, setShowCompleteOrderModal] = useState(false);

  const handleClose = () => {
    setShowOrderDetailsModal(false);
    setSelectedOrder(null);
  };

  const handleUpdateRestockStatus = (status = "reject") => {
    const data = {
      status,
      restockId: `${selectedOrder.id}`,
    };

    Swal.fire({
      title: "Please confirm!",
      text: `Are you sure that you want to ${
        status === "accept" ? "accept" : "reject"
      } this order?`,
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        mutateAsync(data)
          .then(() => {
            Swal.fire({
              title: "Updated successfully.",
              text: "Restock request updated successfully with a rejection",
              icon: "info",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error Occured!",
              text: `Restock request processing failed. Server responsed with message `,
              icon: "error",
            });
          });
      }
    });
  };

  const toggleCompleteOrderModal = (show = false) => {
    setShowCompleteOrderModal(show);
    if (show === false) {
      setSelectedOrder(null);
    }
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "images",
      label: "Image",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "product_name",
      label: "Product Name",
    },
    {
      name: "product_variation",
      label: "Variation",
    },
    {
      name: "quantity",
      label: "Quantity",
    },
    {
      name: "amount_due",
      label: "Amount Due",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "created_at",
      label: "Ordered On",
      options: {
        customBodyRender: (value) => {
          const parsedDate = parseISO(value);
          return formatDistanceToNow(parsedDate);
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => formatedRestockStatus(value),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          const orderId = tableMeta.rowData[0];
          const order = restockingRequests.find(
            (order) => order.id === orderId
          );
          return (
            <IconButton
              onClick={(e) => {
                setSelectedOrder(order);
                setAnchorEl(e.target);
              }}
            >
              <MoreVert />
            </IconButton>
          );
        },
      },
    },
  ];

  if (isLoading) return <LoadingOverlay />;

  return (
    <>
      <MUIDataTable
        columns={columns}
        data={restockingRequests}
        options={{
          selectableRows: "none",
        }}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setShowOrderDetailsModal(true);
          }}
        >
          <ListItemIcon>
            <ViewAgenda />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={
            selectedOrder === null ||
            selectedOrder?.status !== "pending" ||
            updateIsLoading
          }
          onClick={() => {
            setAnchorEl(null);
            handleUpdateRestockStatus();
          }}
        >
          <ListItemIcon>
            <Cancel />
          </ListItemIcon>
          <ListItemText>Reject</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={
            selectedOrder === null || selectedOrder?.status !== "transit"
          }
          onClick={() => {
            setAnchorEl(null);
            toggleCompleteOrderModal(true);
          }}
        >
          <ListItemIcon>
            <Check />
          </ListItemIcon>
          <ListItemText>Complete</ListItemText>
        </MenuItem>
      </Menu>
      {selectedOrder !== null && (
        <OrderDetailsProcessing
          orderDetails={selectedOrder}
          handleCloseModal={handleClose}
          showOrderDetailsModal={showOrderDetailsModal}
        />
      )}

      {selectedOrder !== null && (
        <CompleteOrderModal
          isOpen={showCompleteOrderModal}
          orderId={selectedOrder?.id}
          toggleOpen={toggleCompleteOrderModal}
        />
      )}
    </>
  );
};
export default ManufacturerRestockRequest;
