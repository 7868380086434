import { useMutation } from "@tanstack/react-query";
import axios from "./axiosInstance";

const paymentService = {
  initiateMpesaPayment: async (data) => {
    return axios.post("/payments/stk", data).then((res) => res.data);
  },
};

export const useInitiatePayment = () =>
  useMutation({
    mutationFn: (data) => paymentService.initiateMpesaPayment(data),
  });
