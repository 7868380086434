import { useCountySubCountiesQuery } from "hooks/useLocationData";
import { useState } from "react";

const SubCountyList = ({ countyId }) => {
  const [county, setCounty] = useState(null);

  const { data: subCounties, refetch } = useCountySubCountiesQuery(
    countyId,
    false
  );

  if (county !== countyId) {
    refetch();
    setCounty(countyId);
  }

  return (
    <ul>
      {subCounties?.map((subCounty) => (
        <li key={subCounty.id}>{subCounty.name}</li>
      ))}
    </ul>
  );
};
export default SubCountyList;
