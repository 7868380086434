import { Box, Paper, Typography } from "@mui/material";
import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useEffect } from "react";
import { useSpeficInvoice } from "services/financeServices";
import InvoiceHeader from "./invoice/InvoiceHeader";
import CustomerDetails from "./invoice/CustomerDetails";
import InvoiceCharges from "./invoice/InvoiceCharges";
import InvoiceHeaderDetails from "./invoice/InvoiceHeaderDetails";
import PaymentsDetails from "./invoice/PaymentsDetails";
import ProductDetails from "./invoice/ProductDetails";
import PageSeparator from "./invoice/PageSeparator";
import { Spinner } from "reactstrap";

export const styles = {
  page: {
    flexDirection: "column",
    position: "relative",
  },
  top_wave: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
    zIndex: -1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    gap: 10,
  },
  header: {
    display: "flex",
    gap: 10,
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid black",
    marginTop: "3px",
    marginBottom: "3px",
  },
  itemGroup: {
    flexDirection: "row",
  },
  itemGroupKey: {
    marginRight: "1px",
    color: "#1a874e",
  },
  body: {
    marginTop: "10px",
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableCel: {
    flex: 1,
    border: "1px solid #000000",
    padding: "3px",
    marginRight: "-1px",
    marginTop: "-1px",
  },
  textRight: {
    textAlign: "right",
  },
  themeText: {
    color: "#046631",
  },
  footer: {},
};

const fontStyles = StyleSheet.create({
  documentFont: {
    fontFamily: "Courier",
    fontSize: 12,
  },
});
export const parseModeOfPayment = (mode) => {
  if (mode === "mpesaExpress") return "M-Pesa STK";
  else if (mode === "customerToBusiness") return "M-Pesa Paybill";
  else return "Unknown";
};

const InvoiceReport = ({ invoiceId }) => {
  const {
    data: invoice,
    isLoading: invoiceLoading,
    refetch,
  } = useSpeficInvoice(invoiceId);

  useEffect(() => {
    refetch();
  }, [invoiceId, refetch]);

  if (invoiceLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Spinner color="white" />
      </Box>
    );
  }

  return (
    <PDFViewer height="100%" width="100%">
      <Document
        title={invoice.invoiceNumber}
        style={fontStyles.documentFont}
        subject={invoice.invoiceNumber}
      >
        <Page size="A4" style={styles.page}>
          {/* Main */}
          <View style={styles.section}>
            <InvoiceHeader invoicedFor={invoice.invoicedFor} />
            <CustomerDetails invoice={invoice} />
            <InvoiceHeaderDetails invoice={invoice} />
            <InvoiceCharges invoice={invoice} />
            <ProductDetails invoice={invoice} />
            <PaymentsDetails payments={invoice.payments} />
            <View style={styles.divider} />
            <View>
              <View style={{ marginTop: "10px" }}>
                <Text style={styles.themeText}>Summary: </Text>
                <Text style={{ fontStyle: "italic" }}>
                  {invoice.invoiceDescription}
                </Text>
              </View>
            </View>
            <PageSeparator />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default InvoiceReport;
