import { Box, Typography } from "@mui/material";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { useProductReviews } from "services/productService";
import ReviewsCard from "./ReviewsCard";

const ProductReviews = ({ productId }) => {
  const { data: reviews = [], isLoading } = useProductReviews(productId);

  if (isLoading) return <LoadingOverlay />;

  const reviewsCardList = reviews.map((review) => (
    <Box boxShadow={2} px={5} py={3} mb={3} key={review.id}>
      <ReviewsCard review={review} />
    </Box>
  ));

  return (
    <Box>
      {/* Reviews */}
      <Typography
        variant="h6"
        textTransform={"uppercase"}
        fontWeight="bold"
        color="primary"
      >
        Reviews
      </Typography>
      {reviews.length === 0 ? (
        <Typography>No Reviews Yet</Typography>
      ) : (
        reviewsCardList
      )}
    </Box>
  );
};
export default ProductReviews;
