import InnerLayout from "./InnerLayout";

const ProfitDisbursement = () => {
  return (
    <InnerLayout>
      <div>ProfitDisbursement</div>
    </InnerLayout>
  );
};
export default ProfitDisbursement;
