import { useRef } from "react";
import { Box, styled } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigatePrevIcon from "@mui/icons-material/NavigateBefore";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";
import SwiperCore, { Autoplay, Navigation, Pagination, Thumbs } from "swiper";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";
import "swiper/css/effect-coverflow";
import "./customize-slider.css";

SwiperCore.use([[Autoplay, Navigation, Pagination, EffectCoverflow, Thumbs]]);

const ROOT_PATH = process.env.REACT_APP_API_URL;

const Carousel = ({ items }) => {
  const mainSwiperRef = useRef(null);
  const thumbsSwiperRef = useRef(null);

  const thumbs = items.map((item, index) => (
    <SwiperSlide key={index}>
      <img
        src={`${ROOT_PATH}uploads/products/${item.image}`}
        alt={item.name}
        style={{
          margin: "0 auto",
          display: "flex",
          cursor: "pointer",
          width: "100px",
          height: "auto",
        }}
        onClick={() => mainSwiperRef.current?.slideTo(index)}
      />
    </SwiperSlide>
  ));

  const slides = items.map((item, index) => (
    <SwiperSlide key={index}>
      <img
        src={`${ROOT_PATH}uploads/products/${item.image}`}
        alt={item.name}
        style={{ margin: "0 auto", display: "flex" }}
      />
    </SwiperSlide>
  ));

  const CustomNavigationButton = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    color: theme.palette.grey[100],
  }));
  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        navigation={{
          prevEl: ".arrow-prev-button",
          nextEl: ".arrow-next-button",
        }}
        effect="fade"
        onSwiper={(swiper) => (mainSwiperRef.current = swiper)}
        thumbs={{ thumbs: thumbsSwiperRef }}
      >
        <CustomNavigationButton className="arrow-prev-button">
          <NavigatePrevIcon />
        </CustomNavigationButton>
        {slides}
        <CustomNavigationButton className="arrow-next-button">
          <NavigateNextIcon />
        </CustomNavigationButton>
      </Swiper>
      <Swiper
        slidesPerView={6}
        breakpoints={{
          640: { slidesPerView: 5 },
          768: { slidesPerView: 6 },
        }}
        watchSlidesProgress
        onSwiper={(swiper) => (thumbsSwiperRef.current = swiper)}
        style={{
          marginTop: 10,
        }}
      >
        {thumbs}
      </Swiper>
    </>
  );
};
export default Carousel;
