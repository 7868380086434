import axios from "./axiosInstance";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

const token = Cookies.get("access_token");

const getuserService = {
  // get all users
  getUsers: async () => {
    return await axios.get("/users/all").then((res) => res.data);
  },

  getCounties: async () => {
    return await axios.get("/counties/all").then((res) => res.data);
  },

  getAllSubCounties: async (id) =>
    await axios.get(`counties/${id}/subcounties`).then((res) => res.data),

  getUSer: async () => {
    return await axios
      .get("/users/authenticated?token=" + token)
      .then((res) => res.data);
  },
};

export const useUsers = () =>
  useQuery({
    queryKey: ["allUsers"],
    queryFn: () => getuserService.getUsers(), 
  });

export const useCounties = () =>
  useQuery({
    queryKey: ["allCounties"],
    queryFn: () => getuserService.getCounties(),
  });

export const useSubCounties = (id) => {
 return useQuery({
    queryKey: ["allSubCounties", id],
    queryFn: () => getuserService.getAllSubCounties(id),
  });
}
export const useAuthenticated = () =>
  useQuery({
    queryKey: ["authenticated"],
    queryFn: () => getuserService.getUSer(),
    retry: 1,
  });

// export const useUpdateUser = () =>
//   useMutation((data) => getuserService.updateUser(data));

export default getuserService;
