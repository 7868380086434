import axios from "./axiosInstance";
// import axios from "axios";

const locationServices = {
  // County
  fetchAllCountyLocations: async () => {
    return axios.get("/counties/all").then((res) => res.data);
  },
  createCounty: async (data) => {
    return axios.post("/counties/create", data).then((res) => res.data);
  },
  deleteCountyById: async (id) => {
    return axios.delete(`/counties/${id}`).then((res) => res.data);
  },
  // Sub County
  fetchAllSubCountyLocations: async () => {
    return axios.get("/counties/all/subcounties").then((res) => res.data);
  },
  // fetch all sub-counties of a county
  fetchAllCountySubCounties: async (id) => {
    return axios.get(`/counties/${id}/subcounties`).then((res) => res.data);
  },
  createSubCounty: async (data) => {
    return await axios
      .post("/counties/create/subcounty", data)
      .then((res) => res.data);
  },
};

export default locationServices;
