import { Text, View } from "@react-pdf/renderer";
import { parseModeOfPayment } from "../InvoiceReport";
import { format, parseISO } from "date-fns";
import { styles } from "../InvoiceReport";

const PaymentsDetails = ({ payments }) => {
  const paymentData = payments.map((payment) => {
    return (
      <View style={styles.tableRow} key={payment.paymentId}>
        <View style={styles.tableCel}>
          <Text>{payment.transactionCode}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{Number(payment.paidAmount).toFixed(2)}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{parseModeOfPayment(payment.paymentOption)}</Text>
        </View>
        <View style={styles.tableCel}>
          <Text>{format(parseISO(payment.paidOn), "dd/MMM/yyyy")}</Text>
        </View>
      </View>
    );
  });
  return (
    <>
      <View style={styles.body}>
        <Text style={{ ...styles.themeText, fontWeight: "extrabold" }}>
          Payments
        </Text>
        <View style={styles.divider}></View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCel, ...styles.themeText }}>
              <Text>Trans Code</Text>
            </View>
            <View style={{ ...styles.tableCel, ...styles.themeText }}>
              <Text>Amount (KSH)</Text>
            </View>
            <View style={{ ...styles.tableCel, ...styles.themeText }}>
              <Text>Mode</Text>
            </View>
            <View style={{ ...styles.tableCel, ...styles.themeText }}>
              <Text>Date Paid</Text>
            </View>
          </View>
          {paymentData}
        </View>
      </View>
    </>
  );
};
export default PaymentsDetails;
