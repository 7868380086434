import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "reactstrap";
import { useNewOrderCompletionCode } from "services/orderService";
import Swal from "sweetalert2";
import { useCompleteOrder } from "services/orderService";

const schema = yup
  .object({
    code: yup.number().required().typeError("Valid code is required"),
  })
  .required();

const CodeCompletion = ({ orderId, callback }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutateAsync: getNewCode, isLoading: isGettingCode } =
    useNewOrderCompletionCode();
  const { mutateAsync: completeOrder, isLoading: completingOrder } =
    useCompleteOrder();

  const onSubmit = (input) => {
    const data = {
      orderId,
      code: String(input.code),
    };

    completeOrder(data)
      .then(() => {
        Swal.fire({
          title: "Successfully completed!",
          text: "Order has successfully been completed.",
          icon: "success",
        }).then((res) => {
          if (res.dismiss || res.isConfirmed) callback();
        });
      })
      .catch((error) => {
        const message = error?.response?.data?.message;
        Swal.fire({
          title: "Failed to process!",
          text: `Failed to complete order. Responded with '${message}'`,
          icon: "error",
        });
      });
  };

  const handleRequestCode = () => {
    getNewCode(orderId)
      .then(() => {
        Swal.fire({
          title: "New Code Sent!",
          text: "Order completion code has successfully been sent to registered number.",
          icon: "success",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Request failed!",
          text:
            `New code request failed to be processed. ` +
            `Responded with '${error.response.data.message}'`,
          icon: "error",
        });
      });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: 300,
      }}
    >
      <Typography fontWeight={"bold"}>Complete Order</Typography>
      <Divider />
      <FormControl>
        <InputLabel error={!!errors.code}>Code</InputLabel>
        <OutlinedInput
          {...register("code")}
          label="Code"
          id="code"
          placeholder="1234"
          error={!!errors.code}
        />
        <FormHelperText error={!!errors.code}>
          {errors.code?.message}
        </FormHelperText>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          gap: 2,
        }}
      >
        <Box>
          <Button type="submit" color="success">
            Confirm
          </Button>
        </Box>
        <Box>
          <Button
            disabled={isGettingCode}
            size="small"
            onClick={handleRequestCode}
          >
            Get New Code
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default CodeCompletion;
