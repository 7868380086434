import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axiosInstance";

const financeServices = {
  getAllInvoices: async (from, to, page) => {
    return axios
      .get(`/finance/invoices?from=${from}&to=${to}&page=${page}`)
      .then((res) => res.data);
  },
  getInvoiceById: async (id) =>
    axios.get(`finance/invoices/spefic/${id}`).then((res) => res.data),

  getAllPayments: async (from, to) =>
    axios
      .get(`finance/payments/in?from=${from}&to=${to}`)
      .then((res) => res.data),
  getSpecificPayment: async (paymentId) =>
    axios
      .get(`finance/payments/in/specific/${paymentId}`)
      .then((res) => res.data),
  getAllRestockSettlements: async (status, page) =>
    axios
      .get(`finance/restock/settlements?status=${status}&page=${page}`)
      .then((res) => res.data),
  getAllPurchaseSettlements: async (status, page) =>
    axios
      .get(`finance/purchase/settlements?status=${status}&page=${page}`)
      .then((res) => res.data),
  initiateRestockSettlement: async (id) =>
    axios
      .post(`finance/restock/settlements/disburse/${id}`)
      .then((res) => res.data),
  initiatePurchaseSettlement: async (id) =>
    axios
      .post(`finance/purchase/settlements/disburse/${id}`)
      .then((res) => res.data),
  getAllPartners: async () =>
    axios.get("finance/partners/all").then((res) => res.data),
  getSpecificPartner: async (id) =>
    axios.get(`finance/partners/specific/${id}`).then((res) => res.data),
  createPartner: async (data) =>
    axios.post("finance/partners/create", data).then((res) => res.data),
  updatePartner: async (id, data) =>
    axios.patch(`finance/partners/edit/${id}`, data).then((res) => res.data),
};

export const useInvoices = ({ from, to, page }) =>
  useQuery({
    queryKey: ["invoices"],
    queryFn: () => financeServices.getAllInvoices(from, to, page),
  });

export const useSpeficInvoice = (id) =>
  useQuery({
    queryKey: ["invoice", id],
    queryFn: () => financeServices.getInvoiceById(id),
  });

export const usePayments = ({ from, to }) =>
  useQuery({
    queryKey: ["paymentnts", from, to],
    queryFn: () => financeServices.getAllPayments(from, to),
  });

export const useSpeficPayment = (id) =>
  useQuery({
    queryKey: ["payment", id],
    queryFn: () => financeServices.getSpecificPayment(id),
  });

export const useGetAllRestockSettlements = ({ status, page }) =>
  useQuery({
    queryKey: ["restock-settlements"],
    queryFn: () => financeServices.getAllRestockSettlements(status, page),
  });

export const useInitiateRestockSettlement = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => financeServices.initiateRestockSettlement(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["restock-settlements"]);
    },
  });
};

export const useGetAllPurchaseSettlements = ({ status, page }) =>
  useQuery({
    queryKey: ["purchase-settlement"],
    queryFn: () => financeServices.getAllPurchaseSettlements(status, page),
  });

export const useInitiatePurchaseSettlement = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => financeServices.initiatePurchaseSettlement(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["purchase-settlement"]);
    },
  });
};

export const useGetAllPartners = () =>
  useQuery({
    queryKey: ["all-partners"],
    queryFn: financeServices.getAllPartners,
  });

export const useSpecificPartner = (id) =>
  useQuery({
    queryKey: ["specific-partner", id],
    queryFn: () => financeServices.getSpecificPartner(id),
  });

export const useCreatePartner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: financeServices.createPartner,
    onSuccess: () => {
      queryClient.invalidateQueries(["all-partners"]);
    },
  });
};

export const useUpdatePartner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) => financeServices.updatePartner(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["all-partners"]);
    },
  });
};
