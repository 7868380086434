import {
  Button,
  Form,
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Input,
  ModalFooter,
  Alert,
} from "reactstrap";

import MessageAlert from "components/Shared/MessageAlert";
import React, { useState } from "react";
import userService from "services/userService";
import { MenuItem, Select } from "@mui/material";
import { useCounties } from "services/getuserService";
import { useSubCounties } from "services/getuserService";
import Swal from "sweetalert2";
import { EditUser } from "services/userService";

const UserForm = ({ item }) => {
  const [firstName, setFirstName] = useState(item?.first_name);
  const [lastName, setLastName] = useState(item?.last_name);
  const [email, setEmail] = useState(item?.email);
  const [idNumber, setIdNumber] = useState(item?.id_number);
  const [phoneNumber, setPhoneNumber] = useState(item?.phone_number);
  const [status, setStatus] = useState(item?.status);
  const [county, setCounty] = useState(item?.countyId);
  const [subCounty, setSubCounty] = useState(item?.sub_countyId);
  const [userLevel, setUserLevel] = useState(item?.userLevel);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { mutateAsync } = EditUser();

  // function btnFuns(data) {
  //     setModalOpen(data);
  //     setEditData(data);
  //     setUser(data.userData);
  //     setUserId(data.id);
  //     toggle();
  //   }
  const { data = [] } = useCounties();
  const { data: subCounties = [] } = useSubCounties(county);

  const handleSave = async (event) => {
    setSuccess(false);
    setError(false);
    event.preventDefault();

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("id_number", idNumber);
    formData.append("phone_number", phoneNumber);
    formData.append("status", status);
    formData.append("countyId", county);
    formData.append("sub_countyId", subCounty);
    formData.append("user_level", userLevel);

    let patchAPI;
    if (userLevel === "SuperAdmin") {
      patchAPI = "api1";
    } else if (userLevel === "Admin") {
      patchAPI = "api2";
    } else if (userLevel === "Manufacturer") {
      patchAPI = "api3";
    } else if (userLevel === "Reseller") {
      patchAPI = "api4";
    }

    const id = item.id;
    mutateAsync({ id, data: formData, patchAPI })
      .then(() => {
        Swal.fire({
          title: "Status Update Success",
          text: "You have disabled the user successfully.",
          icon: "success",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Status Update Failed",
          text: "Failed to Disable User.",
          icon: "error",
        });
      });
  };

  return (
    <>
      {error && (
        <Alert color="danger">{"Error while saving, try again!"}</Alert>
      )}
      {success && <Alert color="success">{"Successfully updated!!"}</Alert>}
      <Form role={"form"} onSubmit={handleSave}>
        <div>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="first_name || last_name"
            >
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="first_name || last_name"
              required={true}
              value={`${firstName} ${lastName}`}
              // onChange={(e) => setFirstName(e.target.value)}
              onChange={(e) => {
                const [firstName, lastName] = e.target.value.split(" ");
                setFirstName(firstName);
                setLastName(lastName);
              }}
            />
          </FormGroup>
          {/* <FormGroup>
          <label  className="form-control-label"  htmlFor="first_name">Last Name</label>
          <input type="text" className="form-control" id="last_name." required={true} value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
                />
         </FormGroup> */}
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="id_number">ID Number</Label>
            <Input
              type="number"
              name="id_number"
              value={idNumber}
              onChange={(e) => setIdNumber(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="phone_number">Phone Number</Label>
            <Input
              type="number"
              name="phone_number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="status">Status </Label>
            <Select
              style={{ minWidth: 120 }}
              value={status}
              label={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="InActive">InActive</MenuItem>
            </Select>
          </FormGroup>
          <FormGroup>
            <Label for="county">County </Label>
            <Select
              type="text"
              name="county"
              value={county}
              onChange={(e) => setCounty(e.target.value)}
            >
              {data.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Label for="sub_county">Sub County </Label>
            <Select
              type="text"
              name="sub_county"
              value={county}
              onChange={(e) => setSubCounty(e.target.value)}
            >
              {subCounties?.map((data, index) => (
                <MenuItem key={index} value={data.county_id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Label for="user_level">User Level </Label>
            <Select
              type="text"
              name="user_level"
              value={userLevel}
              onChange={(e) => setUserLevel(e.target.value)}
            >
              <MenuItem value={userLevel}>{userLevel}</MenuItem>
              <MenuItem value="Manufacturer">Manufacturer</MenuItem>
              <MenuItem value="Reseller">Reseller</MenuItem>
              <MenuItem value="Farmer">Farmer</MenuItem>
            </Select>
          </FormGroup>
        </div>
        {/* ) )}  */}
        <ModalFooter>
          <Button type="submit" color="success">
            Update
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};

export default UserForm;
