import ErrorMessages from "components/Shared/ErrorMessages";
import MessageAlert from "components/Shared/MessageAlert";
import CountyForm from "components/admin/location/CountyForm";
import CountyTable from "components/admin/location/CountyTable";
import { useState } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import SubCountyModal from "components/admin/location/SubCountyModal";

const County = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [modal, setModal] = useState(false);

  // const toggle = () => setModal(false);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <div className="header bg-gradient-default pb-8 pt-5 pt-md-6"></div>
      {/* countyId, isOpen, backdrop */}
      <SubCountyModal isOpen={modal} toggle={toggle} county={selectedCounty} />
      <Col className="mt--7">
        <Card>
          <CardHeader>Counties</CardHeader>
          <CardBody>
            <ErrorMessages error={errorMessage} />
            <MessageAlert type="success" message={successMessage} />
            <Col lg="6" md="8">
              <CountyForm />
            </Col>
            {/* List Counties */}
            <CountyTable
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              toggle={toggle}
              setSelectedCounty={setSelectedCounty}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default County;
