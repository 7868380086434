import { Box } from "@mui/material";
import Header from "components/Headers/Header";
import RestockRequestModal from "components/Product/Stock/RestockRequestModal";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { Button, Card, CardBody, Container, Row, Spinner } from "reactstrap";
import { useManufacturerNotDisabledProducts } from "services/productService";
import Swal from "sweetalert2";

const Products = () => {
  const { data, isLoading } = useManufacturerNotDisabledProducts();
  const [showRestockModal, setShowRestockModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleToggleRestockModal = (selectedProductId = null) => {
    // just toggle the modal and exit if selected id is null
    if (selectedProductId === null) {
      setSelectedProduct(null);
      setShowRestockModal(false);
      return;
    }

    const product = data.find((product) => product.id === selectedProductId);
    // if product is in `data` list -> set selected product and toggle modal
    if (product !== null) {
      product.manufacturer = `${product.manufacturer.first_name} ${product.manufacturer.last_name}`;
      setSelectedProduct(product);
      setShowRestockModal(true);
    } else {
      // show error message -> for invalid selection
      Swal.fire({
        title: "Invalid Product!",
        text: "The product you seleted is not valid",
        icon: "warning",
      });
    }
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },

    {
      name: "images",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const manufacturer = tableMeta.rowData[3];
          return `${manufacturer?.first_name} ${manufacturer?.last_name}`;
        },
      },
    },
    {
      name: "variations",
      label: "Variations",
      options: {
        customBodyRender: (value) => {
          return value.length;
        },
      },
    },
    {
      name: "images",
      label: "Product",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <img
              style={{ maxHeight: "64px", maxWidth: "64px" }}
              src={`${process.env.REACT_APP_API_URL}uploads/products/${value[0].fileName}`}
              alt="Product"
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowsData = tableMeta.rowData;
          const productId = rowsData[0];
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleToggleRestockModal(productId)}
              >
                Add Stock
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                {isLoading ? (
                  <Box
                    height="50vh"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner />
                  </Box>
                ) : (
                  <MUIDataTable
                    columns={columns}
                    data={data}
                    options={{
                      selectableRows: "none",
                      search: "true",
                    }}
                  />
                )}
              </CardBody>
            </Card>
            {/* Restocking Modal */}
            {selectedProduct !== null && (
              <RestockRequestModal
                open={showRestockModal}
                product={selectedProduct}
                toggleOpen={handleToggleRestockModal}
              />
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Products;
