import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  colors,
} from "@mui/material";
import Carousel from "components/Carousel/Carousel";
import { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

import RestockRequestQuantityForm from "./RestockRequestQuantityForm";
import { useRequestStock } from "services/stockService";
import Swal from "sweetalert2";

const RestockRequestModal = ({ open, product, toggleOpen }) => {
  const { mutateAsync, isLoading } = useRequestStock();
  const [restockItems, setRestockItems] = useState([]);
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [showSubmitRequestModal, setShowSubmitRequestModal] = useState(false);
  const [selectedVariationIndex, setSelectedVariationIndex] = useState(null);

  const images = product.images.map((image) => ({
    image: image.fileName,
    name: product.name,
  }));

  const toggleOpenQuantityModal = (selectedVariationIndex = null) => {
    setShowQuantityModal((prev) => !prev);
    setSelectedVariationIndex(selectedVariationIndex);
  };

  const toggleShowRequestModal = () => {
    setShowSubmitRequestModal((prev) => !prev);
  };

  const handleRemoveRestockItem = (productId) => {
    // hide restock modal
    restockItems.length === 1 && toggleShowRequestModal();

    setRestockItems((prev) =>
      prev.filter((item) => item.productId !== productId)
    );
  };

  const handleSubmitRestockRequest = async () => {
    const successfullRequests = [];
    const failedRequests = [];

    const restockItemsCount = restockItems.length;

    for (let restockItem of restockItems) {
      try {
        await mutateAsync(restockItem);
        successfullRequests.push(restockItem);
      } catch (error) {
        failedRequests.push(restockItem);
      }
    }

    successfullRequests.forEach((item) => {
      handleRemoveRestockItem(item.productId);
    });

    if (restockItemsCount === successfullRequests.length) {
      // only one item was requested and successful
      Swal.fire({
        title: "Successfully submited!",
        text: `Request for submitted successfully.`,
        icon: "success",
      });
    } else if (restockItemsCount === failedRequests.length) {
      Swal.fire({
        title: "Request failed!",
        text: `Restock request failed`,
        icon: "error",
      });
    } else {
      Swal.fire({
        title: "Request processed!",
        text:
          `${restockItemsCount} restock request were processed. ` +
          `${successfullRequests.length} was accepted and ${failedRequests.length} failed`,
        icon: "info",
      });
    }
  };

  return (
    <Dialog open={open} onClose={() => toggleOpen()}>
      <Box px={2} py={3} display="flex" flexDirection="column" gap={1}>
        <Typography variant="h5" textAlign="center">
          REQUEST NEW STOCK OR FOR MORE
        </Typography>
        <Divider />
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h6" color={colors.grey[600]}>
            Product:
          </Typography>
          <Typography variant="h6">{product.name}</Typography>
        </Box>
        <Box>
          <Carousel items={images} />
        </Box>
        <Box>
          <Typography color={colors.grey[600]}>Manufacturer</Typography>
          <Typography>{product.manufacturer}</Typography>
        </Box>
        <Box px={2} py={3} boxShadow={3}>
          {product.variations.map((variation, index) => (
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              mb={3}
              key={variation.id}
            >
              <Typography>
                <Typography component="span" color={colors.grey[600]}>
                  Variation:{" "}
                </Typography>
                {variation.variation}
              </Typography>
              <Typography>
                <Typography component="span" color={colors.grey[600]}>
                  Price:{" "}
                </Typography>
                {Number(variation.price).toFixed(2)}
              </Typography>
              <Typography>
                <Typography component="span" color={colors.grey[600]}>
                  RRP:{" "}
                </Typography>
                {Number(variation.rrp).toFixed(2)}
              </Typography>
              <Box textAlign="right">
                <Button
                  onClick={() => toggleOpenQuantityModal(index)}
                  variant="contained"
                >
                  Add
                </Button>
              </Box>
              {index + 1 !== product.variations.length && <Divider />}
            </Box>
          ))}

          <Box>
            <Button
              variant="contained"
              color="success"
              disabled={restockItems.length <= 0}
              onClick={toggleShowRequestModal}
            >
              Show Restocking List
            </Button>
          </Box>
        </Box>
      </Box>
      {selectedVariationIndex !== null && (
        <RestockRequestQuantityForm
          handleClose={toggleOpenQuantityModal}
          isOpen={showQuantityModal}
          maximumQuantity={product.variations[selectedVariationIndex]}
          setRestockItems={setRestockItems}
          restockItems={restockItems}
          variation={product.variations[selectedVariationIndex]}
        />
      )}
      <Dialog
        open={showSubmitRequestModal && restockItems.length > 0}
        onClose={toggleShowRequestModal}
      >
        <Box
          py={2}
          px={3}
          display="flex"
          flexDirection="column"
          gap={1}
          minWidth={400}
          maxWidth={450}
        >
          <Typography textTransform="uppercase" textAlign="center" variant="h5">
            Restock Request Items
          </Typography>
          <Divider />
          <Box>
            <List>
              {restockItems.map((item) => (
                <ListItem
                  key={item.productId}
                  secondaryAction={
                    <IconButton
                      onClick={() => handleRemoveRestockItem(item.productId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    secondary={
                      <Typography>Quantity: {item.quantity}</Typography>
                    }
                  >
                    {
                      product.variations.find(
                        (variation) => variation.id === item.productId
                      ).variation
                    }
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Button
              variant="contained"
              color="success"
              disabled={restockItems.length === 0 || isLoading}
              onClick={handleSubmitRestockRequest}
            >
              Submit Request
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Dialog>
  );
};
export default RestockRequestModal;
