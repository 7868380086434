// import axios from "./axiosInstance";
import axios from "./axiosInstance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const productService = {
  // get all products manufactures products
  getAllManufacturersProducts: async () => {
    return await axios
      .get("products/manufacturers/all")
      .then((res) => res.data);
  },

  //Get all manufacturer's products that are not disabled
  getAllManufacturersNotDisabledProducts: async () => {
    return await axios
      .get("products/manufacturers/all/notdisabled")
      .then((res) => res.data);
  },

  // update product variation
  updateProductVariation: async (id, data) => {
    return await axios
      .patch(`/products/specific/?id=${data.id}`, data)
      .then((res) => res.data);
  },

  //Update product Details
  updateProductDetails: async (data) => {
    return await axios
      .patch(`/products/specific/?id=${data.id}`, data)
      .then((res) => res.data);
  },

  //get products for specific manufacturer
  getSpecificManufacturersProducts: async () => {
    return await axios
      .get("products/manufacturer/my-products")
      .then((res) => res.data);
  },

  getAverageProductReview: async (productId) => {
    return await axios
      .get(`reviews/specific/average?productId=${productId}`)
      .then((res) => res.data);
  },

  // get all products in stock
  getProducts: async () => {
    return await axios.get("/products/all").then((res) => res.data);
  },
  // get product by ID
  getProductById: async (id) => {
    return await axios
      .get(`/products/specific?id=${id}`)
      .then((res) => res.data);
  },
  // create new product with default variations
  addProductDetails: async (data) => {
    return axios.post("/products/add", data).then((res) => res.data);
  },
  // add product images
  uploadProductImages: async (data) => {
    return axios
      .post(`/products/images/create`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },

  //Update Product Images
  updateProductImages: async (data) => {
    return axios
      .patch(`/products/images/edit`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },

  // delete product
  deleteProduct: async (id) => {
    return await axios
      .delete(`/products/specific?id=${id}`)
      .then((res) => res.data);
  },
  // delete product variation
  deleteProductVariation: async (id) => {
    return await axios
      .delete(`/products/variation/delete/${id}`)
      .then((res) => res.data);
  },
  // update product
  updateProduct: async (id, data) => {
    return await axios
      .patch(`/products/specific?id=${id}`, data)
      .then((res) => res.data);
  },
  getProductReview: async (id) =>
    await axios.get(`reviews/products/product/${id}`).then((res) => res.data),

  getVariationsToSpecificProduct: async (id) =>
    await axios
      .get(`products/manufacturer/specific?id=${id}`)
      .then((res) => res.data),

  getProductReviewsByStatus: async (status) =>
    await axios.get(`reviews/status/${status}`).then((res) => res.data),

  getFullReviewDetails: async (reviewId) =>
    await axios.get(`reviews/detailed/${reviewId}`).then((res) => res.data),

  updateReviewStatus: async (reviewId, status) =>
    await axios
      .patch("reviews/status", { reviewId: reviewId, status: status })
      .then((res) => res.data),

  updatedProductStatus: async (id, status) => {
    await axios
      .patch(`/products/specific/status?id=${id}`, { status: status })
      .then((res) => res.data);
  },
  // reseller reviews
  getResellerReviews: async () =>
    await axios.get("reviews/my-sales").then((res) => res.data),
};

export const useProduct = () =>
  useQuery({
    queryKey: ["allProduct"],
    queryFn: () => productService.getProducts(),
  });

export const useProductById = (id) => {
  return useQuery({
    queryKey: ["product", id],
    queryFn: () => productService.getProductById(id),
  });
};

export const useUpdateVariation = () =>
  useMutation({
    mutationFn: ({ id, data }) =>
      productService.updateProductVariation(id, data),
  });

export const useDeleteVariation = () =>
  useMutation({
    mutationFn: ({ id, data }) =>
      productService.deleteProductVariation(id, data),
  });
export const useUpdateProductDetails = () =>
  useMutation({
    queryKey: ["specific_manufacturers_products"],
    mutationFn: (data) => productService.updateProductDetails(data),
  });

export const useManufacturerProducts = () =>
  useQuery({
    queryKey: ["all_manufacturers_products"],
    queryFn: productService.getAllManufacturersProducts,
  });

export const useManufacturerNotDisabledProducts = () =>
  useQuery({
    queryKey: ["all_manufacturers_products"],
    queryFn: productService.getAllManufacturersNotDisabledProducts,
  });

export const useSpecificManufacturersProducts = () => {
  return useQuery({
    queryKey: ["specific_manufacturers_products"],
    queryFn: () => productService.getSpecificManufacturersProducts(),
  });
};

export const useProductReviews = (id) => {
  return useQuery({
    queryKey: ["product-review", id],
    queryFn: () => productService.getProductReview(id),
  });
};

export const useGetVariationsToSpecificProduct = (id) => {
  return useQuery({
    queryKey: ["specific_manufacturers_products", id],
    queryFn: () => productService.getVariationsToSpecificProduct(id),
  });
};

export const UseAverageProductReview = (productId) => {
  return useQuery({
    queryKey: ["reviews", productId],
    queryFn: () => productService.getAverageProductReview(productId),
  });
};

export const useProductReviewByStatus = (status) =>
  useQuery({
    queryKey: ["reviews", status],
    queryFn: () => productService.getProductReviewsByStatus(status),
  });

export const useFullReviewDetails = (reviewId) =>
  useQuery({
    queryKey: ["detailed-review", reviewId],
    queryFn: () => productService.getFullReviewDetails(reviewId),
  });

export const useUpdateReviewStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ reviewId, status }) =>
      productService.updateReviewStatus(reviewId, status),
    onSuccess: () => {
      queryClient.invalidateQueries(["reviews", "approved"]);
      queryClient.invalidateQueries(["reviews", "pending"]);
      queryClient.invalidateQueries(["reviews", "rejected"]);
    },
  });
};

export const useUpdateProductStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      productService.updatedProductStatus(data.id, data.status),
    onSuccess: () => {
      queryClient.invalidateQueries(["specific_manufacturers_products"]);
    },
  });
};

export const useMyResellerReviews = () =>
  useQuery({
    queryKey: ["sales-review"],
    queryFn: () => productService.getResellerReviews(),
  });

// create product mutate
export const useCreateProduct = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (productDetails) =>
      productService.addProductDetails(productDetails),
    onSuccess: () => {
      // TODO - Invalidate product details
      // queryClient.invalidateQueries(["all_manufacturers_products"]);
    },
  });
};

// upload product images
export const useUploadProductImages = () => {
  // const queryClient = useQuery();
  return useMutation({
    mutationFn: (productImagesData) =>
      productService.uploadProductImages(productImagesData),
    onSuccess: () => {
      // TODO -> Update list of existing products
      // queryClient.invalidateQueries(["all_manufacturers_products"]);
    },
  });
};

// upload product images
export const useUpdateProductImages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (productImagesData) =>
      productService.updateProductImages(productImagesData),
    onSuccess: () => {
      queryClient.invalidateQueries(["specific_manufacturers_products"]);
    },
  });
};
export default productService;
