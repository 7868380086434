import FarmerHeader from "components/Headers/FarmerHeader";
import CheckoutProductListing from "components/Product/CheckoutProductListing";
import PaymentOptionSelection from "components/Product/PaymentOptionSelection";
import { Col, Container, Row } from "reactstrap";

const Checkout = () => {
  return (
    <>
      <FarmerHeader />
      <Container className="mt-3">
        <Row>
          <Col sm="12" md="8">
            <CheckoutProductListing />
          </Col>
          <Col sm="12" md="4">
            <PaymentOptionSelection />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Checkout;
