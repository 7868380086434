import { Box } from "@mui/system";
import Header from "components/Headers/Header";
import { Button, Card, CardBody, Container, Row, Spinner } from "reactstrap";
import MUIDataTable from "mui-datatables";
import { useMyOrders } from "services/orderService";
import { useState } from "react";
import CompleteOrder from "./CompleteOrder";

import { formatDistanceToNow, parseISO } from "date-fns";
import { titleCase } from "title-case";
import OrderItemsModal from "./OrderItemsModal";
import { Paper, Dialog } from "@mui/material";

const MyOrders = () => {
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const { data: orders, isLoading } = useMyOrders();

  const [openCompleteOrderModal, setOpenCompleteOrderModal] = useState(false);
  const [showOrderProdsModal, setShowOrderProdsModal] = useState(false);

  const toggleOpenCompleteOrderModal = (orderId = null) => {
    setSelectedOrderId(orderId);
    setOpenCompleteOrderModal(!openCompleteOrderModal);
  };

  const toggleShowOrderProdsModal = (selectedOrderId = null) => {
    setSelectedOrderId(selectedOrderId);
    setShowOrderProdsModal(!showOrderProdsModal);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "Ordered On",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value) => {
          const parsedDate = parseISO(value);
          return titleCase(`${formatDistanceToNow(parsedDate)} ago`);
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: { filterOptions: { fullWidth: true, filter: true } },
    },
    {
      name: "totalAmount",
      label: "Total Amount",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "balance",
      label: "Amount Due",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[2];

          return (
            <>
              <Button
                color="primary"
                onClick={() => toggleShowOrderProdsModal(value)}
              >
                View Products
              </Button>
              {status !== "Completed" && (
                <Button
                  color="success"
                  onClick={() => {
                    toggleOpenCompleteOrderModal(value);
                    console.log(value, selectedOrderId, openCompleteOrderModal);
                  }}
                >
                  Complete Order
                </Button>
              )}
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="40vh"
                    width="100%"
                  >
                    <Spinner />
                  </Box>
                ) : (
                  <MUIDataTable
                    columns={columns}
                    data={orders}
                    options={{
                      selectableRows: "none",
                    }}
                  />
                )}

                {selectedOrderId !== null && showOrderProdsModal && (
                  <OrderItemsModal
                    open={showOrderProdsModal}
                    toggleModalOpen={toggleShowOrderProdsModal}
                    selectedOrderId={selectedOrderId}
                  />
                )}

                {selectedOrderId !== null && openCompleteOrderModal && (
                  <Dialog
                    open={openCompleteOrderModal}
                    onClose={() => toggleOpenCompleteOrderModal()}
                  >
                    <Paper
                      sx={{
                        py: 3,
                        px: 2,
                      }}
                    >
                      <CompleteOrder
                        orderId={selectedOrderId}
                        callback={() => toggleOpenCompleteOrderModal()}
                      />
                    </Paper>
                  </Dialog>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default MyOrders;
