import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import InnerLayout from "./InnerLayout";
import MUIDataTable from "mui-datatables";
import { titleCase } from "title-case";
import { format } from "date-fns";
import { MoreVert, Send } from "@mui/icons-material";
import { useGetAllPurchaseSettlements } from "services/financeServices";
import { useEffect, useState } from "react";
import PageviewIcon from "@mui/icons-material/Pageview";
import { Spinner } from "reactstrap";
import { useInitiatePurchaseSettlement } from "services/financeServices";
import Swal from "sweetalert2";

const PurchaseSalesSettlements = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("pending");
  const [selectedSettlement, setSelectedSettlement] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const {
    data: settlements,
    isLoading,
    refetch,
  } = useGetAllPurchaseSettlements({
    status: statusFilter,
    page: currentPage,
  });

  const { mutateAsync: disbursePayment, isLoading: disburseLoading } =
    useInitiatePurchaseSettlement();

  const handleDisbursePayment = () => {
    disbursePayment(selectedSettlement?.id)
      .then(() => {
        Swal.fire({
          title: "Initiated successfully!",
          text: `Paymement initiated successfully.`,
          icon: "success",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Failed to initiate payment!",
          text: `Error occurred while trying to initiate payment. Responded with ${error.response.data.message}`,
          icon: "error",
        });
      });
  };

  useEffect(() => {
    refetch();
  }, [currentPage, refetch, statusFilter]);

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "purchase_request_id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "total_amount",
      label: "Order Total",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "deducted_fee",
      label: "Deducted Fee",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "amount_payable",
      label: "Amount Payable",
      options: {
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => titleCase(value),
      },
    },
    {
      name: "approved_by",
      label: "Approved By",
      options: {
        customBodyRender: (value) =>
          value === null ? "" : titleCase(String(value)),
      },
    },
    {
      name: "paid_on",
      label: "Paid On",
      options: {
        customBodyRender: (value) =>
          value === null ? "" : format(new Date(value), "dd/MMM/yyyy"),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton
              onClick={(e) => {
                const selected = settlements.data.find(
                  (settlement) => settlement.id === value
                );
                setAnchorEl(e.currentTarget);
                setSelectedSettlement(selected);
              }}
            >
              <MoreVert />
            </IconButton>
          );
        },
      },
    },
  ];

  if (isLoading) return <Spinner />;

  return (
    <InnerLayout>
      <Box>
        <Select
          onChange={(e) => {
            setStatusFilter(e.target.value);
            setCurrentPage(1);
          }}
          value={statusFilter}
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="initiated">Initiated</MenuItem>
          <MenuItem value="paid">Paid</MenuItem>
        </Select>
        <MUIDataTable
          columns={columns}
          data={settlements.data}
          options={{
            selectableRows: "none",
            serverSide: true,
            rowsPerPageOptions: [10],
            count: settlements.totalRecords,
            page: currentPage - 0,
            onChangePage: (currentPage) => {
              setCurrentPage(currentPage + 1);
            },
          }}
        />
      </Box>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <MenuItem
          disabled={selectedSettlement?.status !== "pending" || disburseLoading}
          onClick={() => {
            handleDisbursePayment();
            setSelectedSettlement(null);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <ListItemText>Disburse</ListItemText>
        </MenuItem>
      </Menu>
    </InnerLayout>
  );
};
export default PurchaseSalesSettlements;
