import {
  Box,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { Button } from "reactstrap";
import { useCompleteRestockRequestOrder } from "services/stockService";
import Swal from "sweetalert2";
import { useRequestNewRestockingCompletionCode } from "services/stockService";

const schema = yup
  .object({
    code: yup.string().required(),
    orderId: yup.string().required(),
  })
  .required();

const CompleteOrderModal = ({ isOpen, toggleOpen, orderId }) => {
  const { mutateAsync, isLoading } = useCompleteRestockRequestOrder();
  const { mutateAsync: requestCompletionCode, isLoading: loadingCodeRequest } =
    useRequestNewRestockingCompletionCode();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // update form if orderId changes
    setValue("orderId", orderId);
  }, [orderId, setValue]);

  const completeRestockOrder = (data) => {
    mutateAsync(data)
      .then(() => {
        // completed successfully
        Swal.fire({
          title: "Completed Successfully!",
          text: `Order was completed successfully.`,
          icon: "success",
        }).then((res) => {
          if (res.isConfirmed || res.isDismissed) {
            toggleOpen();
          }
        });
      })
      .catch((ex) => {
        const message = ex.response.data.message;

        Swal.fire({
          title: "Failed to complete order",
          text: `Failed to complete order order. Responded with message: ${message}.`,
          icon: "error",
        });
      });
  };

  const handleRequestNewCode = () => {
    requestCompletionCode(orderId)
      .then(() => {
        Swal.fire({
          title: "Request Successfull!",
          text: "Code request was successfully sent.",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Request Failed.",
          text: `Code request failed, '${err.response.data.message}'`,
          icon: "error",
        });
      });
  };
  return (
    <Dialog open={isOpen} onClose={() => toggleOpen()}>
      <Paper sx={{ px: 2, py: 3, width: 300 }}>
        <Box my={2}>
          <Typography fontWeight="bold" textAlign={"center"}>
            COMPLETE ORDER
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box
          component={"form"}
          onSubmit={handleSubmit(completeRestockOrder)}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
        >
          <FormControl>
            <InputLabel error={errors.code && true}>Code</InputLabel>
            <OutlinedInput
              label="Code"
              {...register("code")}
              error={errors.code && true}
            />
            <FormHelperText error={errors.code && true}>
              {errors.code?.message}
            </FormHelperText>
          </FormControl>
          <Box>
            <Button disabled={isLoading} color="success" type="submit">
              Complete
            </Button>
            <Button onClick={handleRequestNewCode}>Get New Code</Button>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};
export default CompleteOrderModal;
