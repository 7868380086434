import { Dialog, Paper } from "@mui/material";
import { addDays, format } from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Button } from "reactstrap";

const DateRangeSelectionModal = ({
  isOpen,
  onClose,
  from,
  to,
  setFrom,
  setTo,
}) => {
  const [datePicker, setDatePicker] = useState([
    {
      startDate: from ? from : new Date(),
      endDate: to ? to : addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  function handleSubmitRangeSelection() {
    setFrom(formatDate(datePicker[0].startDate));
    setTo(formatDate(datePicker[0].endDate));

    onClose();
  }

  function formatDate(date) {
    return format(date, "yyyy-MM-dd");
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <Paper sx={{ px: 4, py: 2 }}>
        <DateRangePicker
          onChange={(item) => setDatePicker([item.selection])}
          moveRangeOnFirstSelection={true}
          // months={2}
          ranges={datePicker}
          editableDateInputs={true}
          direction="horizontal"
          preventSnapRefocus={true}
          maxDate={new Date()}
          // calendarFocus="backwards"
        />
        <Button color="success" onClick={handleSubmitRangeSelection}>
          Ok
        </Button>
      </Paper>
    </Dialog>
  );
};
export default DateRangeSelectionModal;
