import { MoreVert } from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import LoadingOverlay from "components/Shared/LoadingOverlay";
import { parseISO, formatDistanceToNow } from "date-fns";
import MUIDataTable from "mui-datatables";
import { useState } from "react";

import LaunchIcon from "@mui/icons-material/Launch";
import ViewFullDetails from "./ViewFullDetails";
import { useProductReviewByStatus } from "services/productService";

const trimLongText = (text, length) => {
  if (text.length <= length) return text;
  else return `${text.slice(0, length)}...`;
};

const CustomMUIDataTable = styled(MUIDataTable)({
  boxShadow: 3,
  marginTop: 8,
});

const ReviewListingTable = ({ status }) => {
  const { data: reviews, isLoading } = useProductReviewByStatus(status);
  const [manageReviewEl, setManageReviewEl] = useState(null);

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewId, setReviewId] = useState(false);

  const handleCloseManageReviewMenu = () => {
    setManageReviewEl(null);
  };

  const handleOpenManageReviewMenu = (e) => {
    setManageReviewEl(e.currentTarget);
  };

  const toggleCloseReviewModal = (reviewId = null) => {
    setReviewId(reviewId);
    setOpenReviewModal(!openReviewModal);
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "Reviewed On",
      options: {
        customBodyRender: (value) => {
          const parsedDate = parseISO(value);
          return `${formatDistanceToNow(parsedDate)}`;
        },
      },
    },
    {
      name: "title",
      label: "Title",
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (value) => trimLongText(value, 25),
      },
    },
    {
      name: "rating",
      label: "Rating",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          setReviewId(id);
          return (
            <IconButton onClick={handleOpenManageReviewMenu}>
              <MoreVert />
            </IconButton>
          );
        },
      },
    },
  ];

  if (isLoading) return <LoadingOverlay />;

  return (
    <>
      <CustomMUIDataTable
        options={{
          selectableRows: "none",
          print: "false",
          filter: "false",
          search: "false",
          download: "false",
          viewColumns: "false",
        }}
        columns={columns}
        data={reviews}
      />
      <Menu
        open={Boolean(manageReviewEl)}
        anchorEl={manageReviewEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleCloseManageReviewMenu}
      >
        <MenuItem
          onClick={() => {
            toggleCloseReviewModal(reviewId);
            handleCloseManageReviewMenu();
          }}
        >
          <ListItemIcon>
            <LaunchIcon />
          </ListItemIcon>
          Full View
        </MenuItem>
      </Menu>
      {openReviewModal && (
        <ViewFullDetails
          open={openReviewModal}
          onClose={toggleCloseReviewModal}
          reviewId={reviewId}
        />
      )}
    </>
  );
};
export default ReviewListingTable;
