import Header from "components/Headers/Header";
import NewOrderTableListing from "components/Product/Orders/NewOrderTableListing";
import { Card, CardBody, Container, Row } from "reactstrap";

const NewOrders = () => {
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <NewOrderTableListing />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default NewOrders;
