import { Button, Card, Alert, FormText, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import { useDropzone } from "react-dropzone";
import React, { useState } from "react";

import UserHeader from "components/Headers/UserHeader.js";

function SingleImageUpload(props) {
	const [image, setImage] = useState(null);
	const [error, setError] = useState(null);

	const onDrop = (acceptedFiles) => {
		// do something with the uploaded image file
		const imageFile = acceptedFiles.find((file) => file.type.startsWith("image/"));
		if (imageFile) {
			setImage(imageFile);
			setError(null); // clear any previous error
			props.onImageSelect(imageFile); 
		} else {
			setError("Please select an image file"); // set error message
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, required: true });

	return (
		<FormGroup>
			<div {...getRootProps()} style={{ border: "1px dashed gray", padding: "1rem", marginTop: "1rem" }}>
				<Input {...getInputProps()} />
				{isDragActive ? (
					<p>Drop the image here</p>
				) : (
					<p>Drag 'n' drop an image file here, or click to select it</p>
				)}
			</div>
			{error && <Alert color="danger">{error}</Alert>}
			{image && <FormText color="muted">Selected image: {image.name}</FormText>}
		</FormGroup>
	);
}

function MultipleImageUpload(props) {
	const [images, setImages] = useState([]);
	const [error, setError] = useState(null);

	const onDrop = (acceptedFiles) => {
		const imageFiles = acceptedFiles.filter((file) => file.type.startsWith("image/"));
		if (imageFiles.length > 0) {
			setImages((prevImages) => [...prevImages, ...imageFiles]);
			setError(null);
			props.onImageSelect([...images, ...imageFiles]); // call the callback function with the selected image files
		} else {
			setError("Please select at least one image file");
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true, required: true });

	return (
		<FormGroup>
			<div {...getRootProps()} style={{ border: "1px dashed gray", padding: "1rem", marginTop: "1rem" }}>
				<Input {...getInputProps()} />
				{isDragActive ? (
					<p>Drop the images here</p>
				) : (
					<p>Drag 'n' drop some image files here, or click to select them</p>
				)}
			</div>
			{error && <Alert color="danger">{error}</Alert>}
			{images.map((image, index) => (
				<FormText key={index} color="muted">
					Selected image #{index + 1}: {image.name}
				</FormText>
			))}
		</FormGroup>
	);
}

export { SingleImageUpload, MultipleImageUpload };
