import { Box, FormGroup, InputLabel, MenuItem, Select } from "@mui/material";
import Header from "components/Headers/Header";
import { Container, Row } from "reactstrap";
import ReviewListingTable from "./ReviewListingTable";
import { useState } from "react";

const FilteredReviews = () => {
  const [reviewStatus, setReviewStatus] = useState("approved");
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Box bgcolor="background.paper" padding={5}>
              <Box maxWidth={350}>
                <FormGroup>
                  <InputLabel id="review_status">Review Status</InputLabel>
                  <Select
                    labelId="review_status"
                    value={reviewStatus}
                    onChange={(e) => setReviewStatus(e.target.value)}
                  >
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                  </Select>
                </FormGroup>
              </Box>
              <Box width="100%">
                <ReviewListingTable status={reviewStatus} />
              </Box>
            </Box>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default FilteredReviews;
